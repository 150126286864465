<template>
  <div>
    <div class="page-title">USER LIST</div>
    <div class="sub-title mb-12">Millionaire Build's list of all users.</div>

    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search User"></v-text-field>
    <v-data-table :headers="headers" :items="admin.list" :items-per-page="10" :search="search" class="elevation-1 mb-5">
      <template v-slot:item.is_busd_set="{ item }" >
        <v-chip label :color="isBusdSet(item.is_busd_set).color" dark small>
          {{ isBusdSet(item.is_busd_set).label }}
        </v-chip>
      </template>
      <template v-slot:item.type="{ item }" >
        <v-chip label :color="userType(item.type).color" dark small>
          {{ userType(item.type).label }}
        </v-chip>
      </template>
      <template v-slot:item.action="{ item }" >
        <v-btn color="primary" class="white--text" dark small :to="`/admin/user/${item.id}`" text>
          VIEW
        </v-btn>
        <v-btn color="primary" class="white--text" dark small text @click="showConfirmLoginModal(item)">
          LOGIN
        </v-btn>
      </template>
    </v-data-table>
    
    <v-dialog v-model="confirmLoginModal.show" persistent max-width="400">
      <v-card>
        <v-card-title class="text-h5">
          Login to users account
        </v-card-title>
        <v-card-text class="lr-font2 py-7">
          Are you sure you want to login to:<br/><br/>
          Name: <b>{{confirmLoginModal.name}}</b><br/>
          Email: <b>{{confirmLoginModal.email}}</b>
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey darken-1" text @click="confirmLoginModal.show = false">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="loginToUser()">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>

export default {
  name: 'AdminUsers',
  data() {
    return {
      search: '',
      headers: [
        { text: 'ID', value: 'id', sortable: false },
        //{ text: 'APL GO ID', value: 'apl_go_user', sortable: false },
        { text: 'MBID', value: 'mbid', sortable: false },
        { text: 'Sponsor MBID', value: 'sponsor_mbid', sortable: false },
        { text: 'BUSD Address', value: 'is_busd_set', sortable: false, align: 'center' },
        { text: 'Is VIP', value: 'is_vip', sortable: false, align: 'center' },
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'User Type', value: 'type', sortable: false, align: 'center' },
        { text: '', value: 'action', sortable: false, align: 'end' },
      ],
      confirmLoginModal: {
        show: false,
        name: '',
        email: '',
        token: '',
        id: '',
        type: '',
      },
    }
  },
  computed: {
    user () { return this.$store.state.user },
    admin () { return this.$store.state.admin }
  },
  methods: {
    getUsers () {
      let self = this
      self.$sdk.post('', {
        requestType: 'listUsers',
        token: self.user.token
      }, res => {
        let users = []
        if (res.data.status == 'failed') {
          self.$store.commit('admin/setAdminListData', {list: []})
          return
        }
        res.data.forEach(user => {
          users.push({
            id: user.id,
            apl_go_user: user.apl_go_user,
            mbid: user.mbid,
            sponsor_mbid: user.sponsor_mbid,
            is_vip: user.is_vip,
            is_busd_set: (user.busd_address) ? 1 : 0,
            name: `${user.first_name} ${user.last_name}`,
            email: user.email,
            type: user.user_type,
            token: user.token,
          })
        })
        self.$store.commit('admin/setAdminListData', {list: users})
      })
    },
    userType (type) {
      switch (type) {
        case '1': return { label: 'Admin', color: '#1d2431' }
        case '2': return { label: 'Agent', color: 'warning' }
        case '3': return { label: 'Member', color: 'green' }
        default: return { label: '', color: '' }
      }
    },
    isBusdSet (is_busd_set) {
      switch (is_busd_set) {
        case 0: return { label: 'Not Set', color: 'error' }
        case 1: return { label: 'Set', color: 'green' }
        default: return { label: '', color: '' }
      }
    },

    showConfirmLoginModal (item) {
      let self = this
      self.confirmLoginModal.name = item.name
      self.confirmLoginModal.email = item.email
      self.confirmLoginModal.token = item.token
      self.confirmLoginModal.show = true
      self.confirmLoginModal.id = item.id
      self.confirmLoginModal.type = item.type
    },

    loginToUser () {
      let self = this
      //localStorage.setItem('af_session_token', self.confirmLoginModal.token)
      localStorage.setItem('apl_session_token', self.confirmLoginModal.token)
      self.$store.commit('user/setSessionData', {token: self.confirmLoginModal.token})
      self.$store.commit('user/setUserData', {
        id: self.confirmLoginModal.id,
        email: self.confirmLoginModal.email,
        type: self.confirmLoginModal.email,
      })

      self.$router.push('/profile')
      location.reload();
    },
  },
  mounted () {
    this.getUsers();
  }
}
</script>

<style lang="scss" scoped>

</style>