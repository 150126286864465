export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    agentInfo: {
      id: '',
      email: '',
      type: '',
      firstName: '',
      lastName: '',
      mobile: '',
      aplGoUser: '',
      aplGoSponsorId: '',
      instagram: '',
      facebook: '',
      profilePic: '',
    }
  },
  mutations: {
    setAgentData (state, data) {
      for (let field in data) {
        state.agentInfo[field] = data[field]
      }
    },
  }
}