  <template>
  <div class="px-4 text-center">
    <div class="page-title">My Core Team</div>
    <div class="sub-title mb-12"></div>

    <v-card width="300" class="stats-box mx-auto pa-5 mb-6" color="#434343" dark>
      <div class="lr-header mb-3">Hi <b>{{user.firstName}}</b></div>
      <p>
        There are <b>{{stats.total}}</b> members below you.<br/>
        <b>{{stats.vip}} Premium VIP</b>  and <b>{{stats.nonVip}} Non-VIP</b>.
      </p>
      <p>
        You have personally sponsored <b>{{stats.sponsored}}</b> member(s) in Millionaire Build.
      </p>
    </v-card>

    <v-tabs v-model="tab" centered background-color="transparent" class="mb-15" dark>
      <v-tab v-for="item in tabs" :key="item">{{item}}</v-tab>
      <v-tab-item class="p-rel">
        <div class="zoom-box d-flex">
          <v-card elevation="2" width="50" class="px-2 pb-1 pt-2 mr-2" title="Zoom In" @click="zoom('classic', true)">
            <i class="fa-solid fa-magnifying-glass-plus"></i>
          </v-card>
          <v-card elevation="2" width="50" class="px-2 pb-1 pt-2 mr-2"  title="Zoom Out" @click="zoom('classic', false)">
            <i class="fa-solid fa-magnifying-glass-minus"></i>
          </v-card>
        </div>
        <vue-tree class="vue-tree" linkStyle="straight" collapse-enabled="false"
          :dataset="classic.data" :config="treeConfig" v-if="classic.rerender">
          <template v-slot:node="{node}">
            <div class="d-flex flex-column" :style="{zoom:classic.zoom}">
              <div :class="['tree-node d-flex justify-center align-center elevation-2', {vip:node.isVip}]"
                @click.stop @click="getUserInfo(node)">
                <v-avatar class="pic mr-2" size="80">
                  <v-img :src="node.img"></v-img>
                </v-avatar>
                <div class="desc text-left">
                  <div class="id">{{ node.mbId }}</div>
                  <div class="name lr-header">{{ node.name}}</div>
                </div>
              </div>

              <div class="mt-2" :ref="`classic${node.userId}`">
                <i class="fa-solid fa-sort-down fa-2x blue--text" v-if="!node.checkChildren" @click="loadClassic(node)"></i>

                <i class="fa-solid fa-caret-down fa-2x" v-if="node.children && node.children.length"></i>

                <i class="fa-solid fa-caret-up fa-2x" v-if="node._children && node._children.length"></i>

                <i class="fa-solid fa-spinner fa-spin slow-spin fa-2x" 
                  v-if="node.loading"
                ></i>
              </div>
            </div>
          </template>
        </vue-tree>
      </v-tab-item>
      <v-tab-item>
        <v-treeview :items="text"  item-text="full_name" class="text-left text-tree"
          open-all open-on-click expand-icon="mdi-chevron-down" v-if="classic.rerender">
          <template v-slot:label="{item}">
            <span class="vip-txt lr-title mr-1 elevation-2" v-if="item.is_vip == '1'">VIP</span>
            <span class="vip-txt non lr-title mr-1 elevation-2" v-else>NON-VIP</span>
            <b>{{item.name}}</b>
            <span class="ml-3 view-p lr-header" v-if="item.checkChildren">
              <i class="fa-solid fa-people-group fa-fw grey--text"></i>
            </span>
            <span @click.stop class="ml-3 view-p lr-header" @click="getTreeData(item)" v-else>
              <v-tooltip top v-if="loadingId != item.userId">
                <template v-slot:activator="{ on, attrs }">
                  <i class="fa-solid fa-people-group fa-fw" v-bind="attrs" v-on="on"></i>
                </template>
                <span>View Children</span>
              </v-tooltip>
              <i class="fa-solid fa-spinner fa-spin slow-spin" v-else></i>
            </span>
            <span @click.stop class="ml-3 view-p lr-header" @click="getUserInfo(item)">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <i class="fa-solid fa-eye" v-bind="attrs" v-on="on"></i>
                </template>
                <span>View Info</span>
              </v-tooltip>
              
            </span>
          </template>
        </v-treeview>
      </v-tab-item>
    </v-tabs>

    <v-bottom-sheet v-model="userInfo.show">
      <v-sheet class="text-center user-info-box">
        <v-btn class="mt-1 mb-5" text color="red" @click="userInfo.show = false">close</v-btn>
        <div class="py-3 d-flex justify-center">
          <v-avatar class="mr-4" size="150">
            <v-img :src="userInfo.data.img"></v-img>
          </v-avatar>
          <div class="text-left">
            <div class="apl-id lr-header">#{{userInfo.data.mbId}}</div>
            <div class="name lr-header">{{userInfo.data.name}}</div>
            <div class="email lr-title">{{userInfo.data.email}}</div>
            <div class="mobile">{{userInfo.data.mobile}}</div>
            <a :href="userInfo.data.fb" target="_blank" class="socials" v-if="userInfo.data.fb">
              <i class="fa-brands fa-square-facebook fa-2x"></i>
            </a>
            <a :href="userInfo.data.ig" target="_blank" class="mr-5 ml-2 socials" v-if="userInfo.data.ig">
              <i class="fa-brands fa-square-instagram fa-2x"></i>
            </a>
            <!-- <v-divider class="mb-3" /> -->
            <div class="sponsor text-center mt-3 elevation-1">
              <span class="lr-title">SPONSOR</span>
              <div class="pa-3 text-left text-center">
                <div class="apl-id lr-header">#{{userInfo.data.sponsorMbId}}</div>
              </div>
            </div>
          </div>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
// import moment from 'moment'
import VueTree from "@ssthouse/vue-tree-chart"

export default {
  name: 'Home',
  components: { VueTree },
  data () {
    return {
      tab: null,
      tabs: ['Tree View', 'Text View'],
      classic: {
        data: {},
        original: {},
        rerender: true,
        loaded: false,
        zoom: 1,
        allLoaded: false,
        firstLoad: false
      },
      tree: {},
      treeConfig: { nodeWidth: 320, nodeHeight: 180, levelHeight: 220 },
      rerender: true,
      defaultPic: 'https://waysideschools.org/wp-content/uploads/2015/07/default-profile-pic.png',
      userInfo: {
        show: false,
        data: {}
      },
      text: [],
      stats: {
        total: 0,
        vip: 0,
        nonVip: 0,
        sponsored: 0
      },
      loadingId: ''
    }
  },
  computed: {
    user () { return this.$store.state.user },
  },
  methods: {
    getTreeData (node = false) {
      let self = this

      if (node) {
        self.loadingId = node.userId
      }
      self.$sdk.post('', {
        // requestType: 'getVIPTree',
        requestType: 'getVIPTreeImmediate',
        token: self.user.token,
        users_id: node ? node.userId : self.user.id
      }, res => {
        self.loadingId = ''
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            loading: false,
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }
        if (!res.data.main) {
          self.$store.commit('global/setGlobalData', {
            loading: false,
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: 'No Tree data at the moment. Please check again later.'
            }
          })
          self.$router.push('/premium')
          return
        }

        // res.data.main.name = res.data.main.full_name
        // self.classic.data = self.largeObjectFormatter2(res.data)
        // let tmp = self.classic.data.main
        // tmp.fb = self.classic.data.main.facebook_url
        // tmp.ig = self.classic.data.main.instagram_url
        // tmp.img = self.classic.data.main.profile_pic
        // tmp.mbId = self.classic.data.main.mbid
        // tmp.sponsorMbId = self.classic.data.main.sponsor_mbid
        // tmp.children = self.classic.data.children
        // self.text.push(tmp)
        // self.stats.sponsored = res.data.children.length || 0
        if (res.data.main.id === self.user.id) {
          self.stats = {
            total: res.data.stats.total_members,
            vip: res.data.stats.total_vip,
            nonVip: res.data.stats.total_non_vip,
            sponsored: res.data.total
          }
        }

        let main = res.data.main
        let temp = self.formatTreeNode(main)
        self.text.push(temp)
        temp.checkChildren = true
        let children = []
        res.data.children.forEach(child => {
          children.push(self.formatTreeNode(child))
        })
        temp.children = children

        if (node) {
          node.children = children
          node.loading = false
          node.checkChildren = true
          self.text = [self.classic.data]
        } else {
          self.classic.data = temp
          self.classic.loaded = true
        }

        if (!self.classic.firstLoad) {
          self.classic.firstLoad = true
          self.classic.original = self.classic.data
        }

        self.classic.rerender = false
        self.$nextTick(() => {
          self.classic.rerender = true
        })
      })
    },
    formatTreeNode (data) {
      return {
        id: data.aplgo_id,
        userId: data.id,
        sponsorId: data.sponsor_id,
        sponsorName: data.sponsor_name,
        name: data.full_name || 'John Doe',
        img: data.profile_pic,
        checkChildren: false,
        email: data.email,
        mobile: data.contact_number,
        fb: data.facebook_url,
        ig: data.instagram_url,
        isVip: data.is_vip == 1,
        is_vip: data.is_vip,
        mbId: data.mbid,
        sponsorMbId: data.sponsor_mbid,
        loading: false,
      }
    },
    loadBinary (node) {
      let self = this
      if (node.loading) return
      node.checkChildren = true
      node.loading = true
      self.getTreeData(true, node, res => {
        let left = res.data.left
        let right = res.data.right
        node.children = [
          self.formatTreeNode(left),
          self.formatTreeNode(right)
        ]
        node.loading = false
        self.binary.rerender = false
        self.$nextTick(() => {
          self.binary.rerender = true
        })
      })
    },
    loadClassic (node) {
      let self = this
      if (node.loading) return
      node.checkChildren = true
      node.loading = true
      self.getTreeData( node)
    },
    getUserInfo (node) {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})

      setTimeout(() => {
        self.$store.commit('global/setGlobalData', {loading: false})
        self.userInfo = {
          show: true,
          data: node
        }
      }, 500)
    },
    checkLoaded (type) {
      if (type != 'Classic Tree' || this.classic.loaded) return
      this.$store.commit('global/setGlobalData', {loading: true})
      this.getTreeData(false)
    },
    zoom (tree, increase) {
      let self = this
      if (increase && self[tree].zoom <= 1.5) {
        self[tree].zoom += .1
        self.treeConfig.nodeWidth += 10
        self.treeConfig.nodeHeight += 10
        self.treeConfig.levelHeight += 15
        self[tree].rerender = false
        self.$nextTick(() => {
          self[tree].rerender = true
        })
        return
      }

      if (self[tree].zoom <= .5) return
      self[tree].zoom -= .1
      self.treeConfig.nodeWidth -= tree == 'binary' ? 10 : 20
      self.treeConfig.nodeHeight -= 10
      self.treeConfig.levelHeight -= 15
      self[tree].rerender = false
      self.$nextTick(() => {
        self[tree].rerender = true
      })
    },
    showAll (tree) {
      let self = this
      if (self[tree].allLoaded) {
        return
      }

      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        token: self.user.token,
        requestType: 'getFullTree',
        type_of_tree: tree,
        aplgo_id: self.user.aplGoUser
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            loading: false,
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }
        let data = res.data.main
        if (tree == 'binary') data.tree = res.data.tree
        else data.children = res.data.children.child
        self[tree].rerender = false
        self[tree].data = self.largeObjectFormatter(data, tree)
        self.$nextTick(() => {
          self[tree].rerender = true
        })
        self[tree].allLoaded = true
        self.$store.commit('global/setGlobalData', {loading: false})
      })
    },
    largeObjectFormatter (x, tree) {
      const treeFormat = data => ({
        ...data,
        id: data.aplgo_id,
        userId: data.users_id,
        sponsorId: data.sponsor_id,
        sponsorName: data.sponsor_name,
        name: data.full_name || 'John Doe',
        img: data.profile_pic,
        checkChildren: true,
        email: data.email,
        mobile: data.contact_number,
        fb: data.facebook_url,
        ig: data.instagram_url,
        loading: false,
        ...Object.entries(data)
          .filter(([k, v]) => typeof v === 'object') // eslint-disable-line
          .reduce((c, [k, v]) => ({ // eslint-disable-line
            children: v ? [v.left ? treeFormat(v.left) : '',  v.right ? treeFormat(v.right) : ''] : v
          }), {})
      })

      const removeTree = x => Object.entries(x)
        .filter(([k, v]) => k !== 'tree') // eslint-disable-line
        .reduce((c, [k, v]) => ({ ...c, [k]: typeof v === 'object' ? (v ? removeTree(v) : v) : v }), {});

      const fixChildren = x => {
        x.children = Object.fromEntries(Object.entries(x.children).filter(([key, val]) => val !== '')) // eslint-disable-line
        if (!Object.entries(x.children).filter(([k, v]) => v != '').length) { // eslint-disable-line
          delete x.children
        }
        return {
          ...x,
          ...Object.entries(x)
            .filter(([k, v]) => k === 'children' && typeof v === 'object')
            .reduce((c, [, v]) => ({
              children: [
                ...Object.values(v).filter(h => typeof h !== 'object'),
                ...Object.values(v).filter(h => typeof h === 'object').map(h => fixChildren(h))
              ]
            }), {}),
        }
      };

      const treeFormat2 = data => {
        if (data.children.child) {
          data.children = data.children.child
        }
        return {
          ...data,
          id: data.aplgo_id,
          userId: data.users_id,
          sponsorId: data.sponsor_id,
          sponsorName: data.sponsor_name,
          name: data.full_name || 'John Doe',
          img: data.profile_pic,
          checkChildren: true,
          email: data.email,
          mobile: data.contact_number,
          fb: data.facebook_url,
          ig: data.instagram_url,
          loading: false,
          ...Object.entries(data)
            .filter(([k, v]) => typeof v === 'object' && v != null) // eslint-disable-line
            .reduce((c, [k, v]) => { // eslint-disable-line
              let children = []
              v.forEach(child => {
                children.push(treeFormat2(child))
              })
              return { children: v ? children : v }
            }, {})
        }
      }

      let temp = ''
      if (tree == 'binary') {
        temp = treeFormat(x)
        temp = removeTree(temp)
        temp = fixChildren(temp)
      } else {
        temp = treeFormat2(x)
      }

      return temp
    },
    hideAll (tree) {
      this[tree].rerender = false
      this[tree].data = this[tree].original
      this.$nextTick(() => {
        this[tree].rerender = true
      })
      this[tree].allLoaded = false
      setTimeout(() => {
        if (this.$refs[`${tree}${this.user.id}`]) {
          this.$refs[`${tree}${this.user.id}`].click()
        }
      }, 200)
    },
    largeObjectFormatter2 (x) {
      let self = this
      const treeFormat2 = data => {
        let tempData = data.main ? data.main : data
        data.children = data.children.total > 0 ? data.children.child : []
        if (!data.main) {
          self.stats.total++
          if (tempData.is_vip == '1') self.stats.vip++
          else self.stats.nonVip++
        }
        return {
          ...data,
          name: tempData.full_name,
          email: tempData.email,
          mobile: tempData.contact_number,
          fb: tempData.facebook_url,
          ig: tempData.instagram_url,
          isVip: tempData.is_vip == 1,
          img: tempData.profile_pic,
          mbId: tempData.mbid,
          sponsorMbId: tempData.sponsor_mbid,
          checkChildren: true,
          loading: false,
          ...Object.entries(data)
            .filter(([k, v]) => typeof v === 'object' && v != null && (v.length || v.total > 0)) // eslint-disable-line
            .reduce((c, [k, v]) => { // eslint-disable-line
              let children = []
              v.forEach(child => {
                children.push(treeFormat2(child))
              })
              return { children: v ? children : v }
            }, {})
        }
      }

      let temp = ''
      temp = treeFormat2(x)

      return temp
    }
  },
  mounted () {
    // if (!this.user.isVip || !this.user.vipExpiry || (this.user.vipExpiry && !moment().isBefore(moment(this.user.vipExpiry, 'YYYY-MM-DD').add('days', 1), 'day'))
    if (!this.user.isVip) {
      this.$store.commit('global/setGlobalData', {
        response: {
          show: true,
          success: false,
          title: 'Premium Required',
          message: 'Be a Premium VIP to unlock this Premium Feature.'
        }
      })
      this.$router.push('/premium')
      return
    }
    this.$store.commit('global/setGlobalData', {loading: true})
    this.getTreeData()
  }
}
</script>

<style lang="scss" scoped>
.page-title{
  color: #fff;
}
.sub-title{
  color: #eee;
}
.p-rel{
  position: relative;
}
.zoom-box{
  position: absolute;
  z-index: 2;
  top: 20px;
  left: 20px;

  div{
    cursor: pointer;
  }
  i{
    font-size: 21px;
    color:#333;
    line-height: 1;
  }
  span{
    font-size: 15px;
    vertical-align: 2px;
    line-height: 1;
  }
}
.vue-tree{
  width: 100%;
  height: calc(100vh - 180px);
  border: 1px solid #ccc;
  background: #fafafa url('/grid.png');
  cursor: grab;
  position: relative;
  z-index: 1;
}

.stats-box{
  .lr-header{
    font-size: 21px;
  }
  b{
    color: #e6c069;
  }
}

.tree-node{
  border: 1px solid #eee;
  padding: 15px;
  text-align: center;
  background: #fff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;

  &.vip{
    border: 4px solid #e6c069!important;
    box-shadow:0 0 5px #e6c069!important;
  }
  .desc{
    line-height: 1;
  }
  .name{
    font-weight: 600;
    color: #1d2431;
    max-width: 190px;
    font-size: 15px;
  }
  .id{
    color: #777;
    margin-bottom: 2px;
    font-size: 14px;
  }
  .add-member-icon{
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    font-size:24px;
    color: #c14343;
    background: #fff;
    border-radius: 100%;
  }

  &:hover{
    background: #353535;
    .name, .id{
      color: #fff;
    }
  }
}
.fa-sort-down{
  position: relative;
  top: -5px;
}

.text-tree{
  .vip-txt{
    line-height: 1;
    background: #e6c069;
    padding: 2px 8px;
    font-weight: 600;
    font-size: 10px;
    letter-spacing: 1px;
    border-radius: 4px;
    vertical-align: 2px;

    &.non{
      background: #ccc;
    }
  }
  .view-p{
    font-size: 18px;
    font-weight: 600;
    color: #2d77dd;
  }
}

.user-info-box{
  *{
    line-height: 1.4;
  }
  .name{
    font-weight: 600;
    color: #1d2431;
    font-size: 21px;
  }
  .apl-id{
    font-size: 15px;
    color: #fff;
    background: #333;
    border-radius: 3px;
    display: inline-block;
    padding: 2px 5px;
    margin-bottom: 2px;
  }
  .email{
    color: #555;
    font-size: 14px;
  }
  .mobile{
    letter-spacing: .5px;
    font-size: 14px;
    color: #666;
  }
}
.socials{
  color: #1d2431!important;
}
.sponsor{
  border: 1px solid #555;
  border-radius: 5px;
  background: #f5f5f5;

  span{
    display: block;
    color: #fff;
    background: #425365;
    padding: 3px 0;
    font-weight: 600;
    border-radius: 5px 5px 0 0;
  }
}
</style>