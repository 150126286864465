import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

import router from './router'
import store from './store'

import VueModules from './modules'
import VueCookies from 'vue-cookies'
Vue.config.productionTip = false
Vue.use(VueModules)
Vue.use(VueCookies)

import SsrCarousel from 'vue-ssr-carousel'
// eslint-disable-next-line  no-unused-vars
import ssrCarouselCss from 'vue-ssr-carousel/index.css'
Vue.component('ssr-carousel', SsrCarousel)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
