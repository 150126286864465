<template>
  <div>
    <div class="page-title text-center mb-8">Top Earner</div>
    
    <v-tabs v-model="tab.topEarner" centered background-color="transparent" class="tree-tabs">
      <v-tab v-for="item in tab.TELabel" :key="item">{{item}}</v-tab>
      <v-tab-item class="p-rel">
        <top-five :userData="topEarner"></top-five>
      </v-tab-item>
      <v-tab-item class="p-rel">
        <top-five :userData="topEarnerMonthly"></top-five>
      </v-tab-item>
    </v-tabs>

    <v-divider class="my-15" />

    <div class="page-title text-center mb-15">Top Inviter</div>

    <v-tabs v-model="tab.topInviter" centered background-color="transparent" class="tree-tabs">
      <v-tab v-for="item in tab.TELabel" :key="item">{{item}}</v-tab>
      <v-tab-item class="p-rel">
        <top-five :userData="topInviter"></top-five>
      </v-tab-item>
      <v-tab-item class="p-rel">
        <top-five :userData="topInviterMonthly"></top-five>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import topFive from './top-five'
import moment from 'moment'

export default {
  name: 'TopFive',
  components: { topFive },
  data () {
    return {
      tab: {
        topEarner: '',
        TELabel: ['All Time', 'Monthly'],
        topInviter: '',
        TILabel: ['All Time', 'Monthly']
      },
      topEarner: [],
      topEarnerMonthly: [],
      topInviter: [],
      topInviterMonthly: [],
    }
  },
  computed: {
    user () { return this.$store.state.user },
  },
  methods: {
    getTopEarner () {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'admin_getTopEarner',
        token: self.user.token
      }, res => {
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {loading: false})
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        res.data.record.forEach(data => {
          self.topEarner.push({
            mbid: data.mbid,
            sponsorid: data.sponsors_id,
            name: `${data.first_name} ${data.last_name}`,
            email: data.email,
            img: data.profile_pic,
            value: data.total_earn % 1 > 0 ? parseFloat(data.total_earn).toFixed(2) : data.total_earn
          })
        })
        res.data.monthly.record.forEach(data => {
          self.topEarnerMonthly.push({
            mbid: data.mbid,
            sponsorid: data.sponsors_id,
            name: `${data.first_name} ${data.last_name}`,
            email: data.email,
            img: data.profile_pic,
            value: data.total_earn % 1 > 0 ? parseFloat(data.total_earn).toFixed(2) : data.total_earn
          })
          self.tab.TELabel[1] = `${moment(data.current_month, 'M').format('MMM')} ${data.current_year} Ranking`
        })

        self.getTopInviter()
      })
    },
    getTopInviter () {
      let self = this
      self.$sdk.post('', {
        requestType: 'admin_getTopInviter',
        token: self.user.token
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        res.data.record.forEach(data => {
          self.topInviter.push({
            mbid: data.mbid,
            name: `${data.first_name} ${data.last_name}`,
            email: data.email,
            img: data.profile_pic,
            value: data.total_invites % 1 > 0 ? parseFloat(data.total_invites).toFixed(2) : data.total_invites
          })
        })
        res.data.monthly.record.forEach(data => {
          self.topInviterMonthly.push({
            mbid: data.mbid,
            name: `${data.first_name} ${data.last_name}`,
            email: data.email,
            img: data.profile_pic,
            value: data.total_invites % 1 > 0 ? parseFloat(data.total_invites).toFixed(2) : data.total_invites
          })
          self.tab.TILabel[1] = `${moment(data.current_month, 'M').format('MMM')} ${data.current_year} Ranking`
        })

      })
    }
  },
  mounted () {
    this.getTopEarner()
  }
}
</script>

<style lang="scss" scoped>
</style>