export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    invitation: {
      list: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      day1: '',
      day7: '',
      day30: ''
    },
    signup: {
      list: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      loading: false
    }
  },
  mutations: {
    setInvationData (state, data) {
      for (let field in data) {
        state.invitation[field] = data[field]
      }
    },
    setSignupData (state, data) {
      for (let field in data) {
        state.signup[field] = data[field]
      }
    },
  }
}