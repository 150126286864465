export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    list: [],
    pageNum: 1,
    pageSize: 10,
    total: 0,
    loading: false
  },
  mutations: {
    setAdminListData (state, data) {
      for (let field in data) {
        state[field] = data[field]
      }
    },
  }
}