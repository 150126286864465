<template>
  <div class="live-box justify-center align-center text-center">
    <div>
      <div class="head mb-5">Live Training</div>
      <v-card elevation="2" class="pa-10 ma-auto" color="#1d2431" dark width="800">
        <div class="title" v-if="training.title">{{training.title}}</div>
        <div class="desc mb-3" v-if="training.desc">{{training.desc}}</div>
        <div class="link">
          <a v-if="training.link" :href="training.link" target="_blank">
            {{training.link}}
          </a>
          <span v-else>No Training Link at the moment. Please come back again later.</span>
        </div>
      </v-card>

      <v-row>
        <v-col class="">
          <v-img src="@/assets/img/live1.jpeg" contain height="600" class="my-5"></v-img>  
        </v-col>
      </v-row>

      <v-card elevation="2" class="pa-10 ma-auto" color="#1d2431" dark width="800">
        <div class="desc" v-if="training.desc">
          LIVE Training with Ace Fletcher 7 Days a week at 10AM Philippine Time.<br/>

          LIVE Training with Ace Fletcher 7 Days a week at 10AM Philippine Time.<br/>

          Plus Tuesdays and Thursdays at 8PM Philippine Time.<br/>

          Open to the Public Ace Fletcher Millionaire Build Business Opportunities Presentations with APL PH Mondays and Wednesdays at 8PM Philippine Time.
        </div>
      </v-card>
      <br/>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Training Live',
  data() {
    return {
      training: {
        title: '',
        desc: '',
        link: ''
      }
    }
  },
  computed: {
    user () { return this.$store.state.user },
  },
  methods: {
    getTrainingLink () {
      let self = this

      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'getLiveTraining',
        token: self.user.token
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            loading: false,
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }
        let data = res.data.data
        self.training = {
          title: data.title,
          desc: data.description,
          link: data.link,
        }
      })
    }
  },
  mounted () {
    if (!this.user.isVip) {
      this.$store.commit('global/setGlobalData', {
        response: {
          show: true,
          success: false,
          title: 'Premium Required',
          message: 'Be a Premium VIP to unlock this Premium Feature.'
        }
      })
      this.$router.push('/premium')
      return
    }
    this.getTrainingLink()
  }
}
</script>

<style lang="scss" scoped>
.live-box{
  width: 100%;
}

.head{
  font-size: 45px!important;
  font-weight: 600;
}
.title{
  font-size: 21px!important;
  font-weight: 600;
}
.desc{
  font-size: 15px;
}

a{
  color: #e6c069!important;
  font-weight: 600;
}
</style>