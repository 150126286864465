<template>
  <div class="px-md-0 px-5">
    <div class="page-title">Announcement Edit</div>
    <div class="sub-title mb-12">Update announcement information.</div>

    <div class="d-flex justify-space-between mb-5 align-center">
      <!-- <a href="#/admin/posts" class="lr-header back">
        <i class="fa-solid fa-arrow-left fa-fw mr-2"></i>
        <b>Back to Announcements</b>
      </a> -->
      <div class="d-flex align-center">
        <v-switch v-model="training.status" inset class="mt-0"
          hide-details color="#2E7D32" @click="updateStatus"></v-switch>
        <b class="lr-header mt-1">{{ training.status ? 'Active' : 'Inactive' }}</b>
      </div>

      <div>
        <v-btn color="error" dark @click="confirmDelete">
          <i class="fa-solid fa-trash fa-fw mr-2"></i> Delete Post
        </v-btn>
      </div>
    </div>

    <v-card elevation="2" class="mb-5 card-box">
      <div class="head py-4 px-5 lr-header d-flex justify-space-between align-center">
        <div>
          Description
        </div>
        <v-btn color="warning" dark @click="setAsFeatured">
          <span v-if="!training.featured">Set as Featured post</span>
          <span v-else>Unfeatured post</span>
        </v-btn>
      </div>
      <div class="pa-5">
        <v-row>
          <v-col class="col-12">
            <div class="lr-header">Title</div>
            <v-text-field outlined hide-details="auto" dense
              v-model="training.title"
            ></v-text-field>
          </v-col>
          <v-col class="col-12">
            <div class="lr-header">
              URL
              <span class="subtitle-2">(<a :href="`#/post/${originalSlug}`" class="px-1">{{ `${hostUrl}/#/post/${originalSlug}` }}</a>)</span>
            </div>
            <v-text-field outlined hide-details="auto" dense
              v-model="training.slug" v-on:keypress="isNumber" @change="removeSpecialChar"
            ></v-text-field>
          </v-col>
          <v-col class="col-12">
            <div class="lr-header">Description</div>
            <v-textarea outlined hide-details="auto" dense
              v-model="training.description"
            ></v-textarea>
          </v-col>
        </v-row>
        <div class="mt-8">
          <v-btn color="#2d8c40" block dark large @click="updatePostInformation">UPDATE POST INFORMATION</v-btn>
        </div>
      </div>
    </v-card>

    <v-card elevation="2" class="mb-5 card-box">
      <div class="head py-4 px-5 lr-header d-flex justify-space-between align-center">
        <div>
          Content
          <div class="subtitle-2 sub">Drag items to arrange their order</div>
        </div>
        <v-btn color="success" @click="addContentModal=true" class="mr-2">
          <v-icon>mdi-receipt-text-plus-outline</v-icon>&nbsp;&nbsp;Add Content
        </v-btn>
      </div>
      <div class="pa-5">
        <draggable v-model="trainingContents" group="training_content" @start="drag=true" @end="drag=false">
          <div :class="['post-content d-flex content-box align-center py-2 px-4 mb-3', boxColor(element.type)]"
            v-for="element in trainingContents" :key="element.id">
            <div class="post-type">
              <!-- <v-chip small class="mr-2 chip" label>Content#{{element.content_id}}</v-chip> -->
              <v-chip small class="mr-2 chip" label>{{element.type_description}}</v-chip>
              <v-icon left v-if="element.type == 1" class="post-icon">mdi-format-text</v-icon>
              <v-icon left v-if="element.type == 2" class="post-icon">mdi-video</v-icon>
              <v-icon left v-if="element.type == 3" class="post-icon">mdi-image</v-icon>
              <v-icon left v-if="element.type == 4" class="post-icon">mdi-file</v-icon>
              <!-- <b>{{element.type_description}}</b> -->
            </div>
            <div class="post-value px-1 text-left lr-text">
              <!-- {{element.content}} -->
              {{element.label}}
            </div>
            <div class="action">
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn x-small color="primary" @click="openUpdateModal(element.id)" class="mr-2" v-bind="attrs" v-on="on">
                    <i class="fa-solid fa-pencil fa-fw"></i>
                  </v-btn>
                </template>
                <span>Update</span>
              </v-tooltip>
              <v-tooltip top color="error">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn x-small color="error" @click="openDeleteContentModal(element)" v-bind="attrs" v-on="on">
                    <i class="fa-solid fa-trash fa-fw"></i>
                  </v-btn>
                </template>
                <span>Remove</span>
              </v-tooltip>
            </div>
          </div>
        </draggable>
        <div class="mt-10">
          <v-btn color="#2d8c40" block dark large @click="updateTrainingContentOrder">UPDATE CONTENT ORDER</v-btn>
        </div>
      </div>
    </v-card>

    <!-- UPDATE CONTENT MODAL -->
    <v-dialog v-model="updateContentModal" persistent max-width="800">
      <v-card>
        <v-card-title class="text-h5">Update Content</v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="col-12 col-md-12">
              <div class="lr-header">Type</div>
              <v-select :items="contentType" outlined hide-details="auto" dense
                v-model="updateContentModalField.type" disabled>
              </v-select>
            </v-col>

            <v-col class="col-12 col-md-12">
              <div class="lr-header">Label</div>
              <v-text-field outlined hide-details="auto" dense v-model="updateContentModalField.label"></v-text-field>
            </v-col>

            <!-- Content Type :: TEXT -->
            <v-col class="col-12 col-md-12" v-if="updateContentModalField.type==1">
              <div class="lr-header">Content</div>
              <vue-editor v-model="updateContentModalField.content" :editor-toolbar="customToolbar" />
            </v-col>

            <!-- Content Type :: IMAGE or VIDEO -->
            <v-col class="col-12 col-md-12" v-if="updateContentModalField.type!=1">
              <div class="lr-header">Content (for external image/video, input the source link below)</div>
              <v-text-field outlined hide-details="auto" dense v-model="updateContentModalField.content"></v-text-field>
            </v-col>

            <!-- Content Type :: IMAGE -->
            <v-col class="col-12 col-md-12" v-if="updateContentModalField.type==3">
              <div class="d-flex justify-space-between">
                <div class="lr-header">Select Image</div>
                <div>
                  <div class="lr-header" @click="uploadProfilePicture()" style="cursor:pointer;font-weight:bold;color:#43A047;">Upload Image</div>
                  <v-file-input v-show="false" type="file" prepend-icon="mdi-camera" @change="fileUpload($event)" ref="uploadFile" accept="image/png, image/jpeg, image/bmp" :hide-input="true"></v-file-input>  
                </div>
              </div>
              <v-item-group style="max-height:150px;overflow-y: auto;border:solid 1px #c0c0c0;border-radius:4px;">
                <v-container>
                  <v-row>
                    <v-col v-for="picture in postPictureList" :key="picture.id" class="d-flex align-self-start" cols="2">
                      <v-item>
                        <v-img :src="picture.path" aspect-ratio="1"
                          :class="`image-item ${(picture.path == updateContentModalField.content) ? 'image-item-active': ''}`"
                          @click="imageSelected(picture.path)"
                        >
                          <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-item>
                    </v-col>
                  </v-row>
                </v-container>
              </v-item-group>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="mt-3" />
        <v-card-actions>
          <v-btn color="grey darken-1" text @click="updateContentModal=false">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="updatePostContent()">
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ADD CONTENT MODAL -->
    <v-dialog v-model="addContentModal" persistent max-width="800">
      <v-card>
        <v-card-title class="text-h5">Add Content</v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="col-12 col-md-12">
              <div class="lr-header">Type</div>
              <v-select :items="contentType" outlined hide-details="auto" dense
                v-model="addContentModalField.type">
              </v-select>
            </v-col>

            <v-col class="col-12 col-md-12" v-if="addContentModalField.type">
              <div class="lr-header">Label</div>
              <v-text-field outlined hide-details="auto" dense v-model="addContentModalField.label"></v-text-field>
            </v-col>

            <!-- Content Type :: TEXT -->
            <v-col class="col-12 col-md-12" v-if="addContentModalField.type==1">
              <div class="lr-header">Content</div>
              <vue-editor v-model="addContentModalField.content" :editor-toolbar="customToolbar" />
            </v-col>

            <!-- Content Type :: IMAGE or VIDEO -->
            <v-col class="col-12 col-md-12" v-if="addContentModalField.type!='' && addContentModalField.type!=1">
              <div class="lr-header">Content (for external image/video, input the source link below)</div>
              <v-text-field outlined hide-details="auto" dense
                v-model="addContentModalField.content"
              ></v-text-field>
            </v-col>

            <!-- Content Type :: IMAGE -->
            <v-col class="col-12 col-md-12" v-if="addContentModalField.type==3">
              <div class="d-flex justify-space-between">
                <div class="lr-header">Select Image</div>
                <div >
                  <div class="lr-header" @click="uploadProfilePicture()" style="cursor:pointer;font-weight:bold;color:#43A047;">Upload Image</div>
                  <v-file-input v-show="false" type="file" prepend-icon="mdi-camera" @change="fileUpload($event)" ref="uploadFile" accept="image/png, image/jpeg, image/bmp" :hide-input="true"></v-file-input>  
                </div>
              </div>
              <v-item-group style="max-height:150px;overflow-y: auto;border:solid 1px #c0c0c0;border-radius:4px;">
                <v-container>
                  <v-row>
                    <v-col v-for="picture in postPictureList" :key="picture.id" class="d-flex align-self-start" cols="2">
                      <v-item>
                        <v-img :src="picture.path" aspect-ratio="1" @click="imageSelected(picture.path)"
                          :class="`image-item ${(picture.path == addContentModalField.content) ? 'image-item-active': ''}`">
                          <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-item>
                    </v-col>
                  </v-row>
                </v-container>
              </v-item-group>
            </v-col>
          </v-row>

        </v-card-text>
        <v-divider class="mt-3" />
        <v-card-actions>
          <v-btn color="grey darken-1" text @click="addContentModal=false">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="addPostContent()">
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DELETE CONTENT MODAL -->
    <v-dialog v-model="deleteContentModal" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h5">Confirm Delete Content</v-card-title>
        <v-card-text>
          Are you sure you want to delete this content 
          <b class="lr-header">{{this.deleteContentModalField.label}}</b>?
        </v-card-text>
        <v-divider class="mt-3" />
        <v-card-actions>
          <v-btn color="grey darken-1" text @click="deleteContentModal = false">
            No, I change my mind
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="deletePostContent()">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <delete-post-modal :data="deleteModal" @hide="deleteModal.show = false"></delete-post-modal>

  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { VueEditor } from "vue2-editor";
import deletePostModal from './delete-post-modal'

export default {
  name: 'AdminTrainingEdit',
  components: {
    draggable,
    VueEditor,
    deletePostModal
  },
  data () {
    return {
      postID: this.$route.params.id,
      trainingContents: [],
      error: {
        firstName: '',
        lastName: '',
      },
      addContentModal: false,
      addContentModalField: {
        type: '',
        label: '',
        content: '',
      },
      updateContentModal: false,
      updateContentModalField: {
        trainingContentId: '',
        type: '',
        label: '',
        content: '',
      },
      deleteContentModal: false,
      deleteContentModalField: {
        trainingContentId: '',
        label: ''
      },
      training: {
        id: '',
        title: '',
        description: '',
        slug: '',
        status: false,
        featured: false
      },
      contentType: [
        { value: '1', text: 'text', },
        { value: '2', text: 'video', },
        { value: '3', text: 'image', },
        /*{ value: '4', text: 'file', },*/
      ],
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link"],
        ["clean"] // remove formatting button
      ],
      postPictureList: [],
      deleteModal: {
        show: false,
        id: this.$route.params.id,
        title: ''
      },
      hostUrl: window.location.host,
      originalSlug: ''
    }
  },
  computed: {
    trainingId () { return this.$route.params.id },
    user () { return this.$store.state.user },
  },
  methods: {
    updateList() {
      console.log(this.trainingContents);
    },

    getTrainingInfo () {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'getTraining',
        token: self.user.token,
        id: self.trainingId,
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          self.$router.push('/admin/users')
          return
        }
        
        self.training.status = res.data.status == '1'
        self.training.featured = res.data.is_featured == '1'

        self.training.id = res.data.id
        self.training.title = res.data.title
        self.training.description = res.data.description
        self.training.slug = res.data.slug
        self.originalSlug = res.data.slug
      })
    },

    getTrainingContent () {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'getListOfTrainingContents',
        token: self.user.token,
        training_id: self.trainingId,
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          self.$router.push('/admin/users')
          return
        }
        this.trainingContents = [];
        for (let i = 0; i < res.data.training_contents.length; i++) {
          let data = res.data.training_contents[i]
          let record = {
            id: i,
            content_id: data.id,
            type: data.type,
            type_description: data.type_description,
            content: data.content,
            label: data.label
          }
          this.trainingContents.push(record);
        }
      })
    },

    openUpdateModal(id) {
      for (let i = 0; i < this.trainingContents.length; i++) {
        if (this.trainingContents[i].id == id) {
          this.updateContentModalField.trainingContentId = this.trainingContents[i].content_id
          this.updateContentModalField.type = this.trainingContents[i].type
          this.updateContentModalField.label = this.trainingContents[i].label
          this.updateContentModalField.content = this.trainingContents[i].content
          this.updateContentModal = true;
          break;
        }
      }
    },

    openDeleteContentModal (data) {
      this.deleteContentModalField.trainingContentId = data.content_id;
      this.deleteContentModalField.label = data.label;
      this.deleteContentModal = true;
    },

    updatePostInformation() {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'updateTraining',
        token: self.user.token,
        training_id: self.trainingId,
        title: this.training.title,
        description: this.training.description,
        slug: this.training.slug
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }
        
        self.$store.commit('global/setGlobalData', {
          response: {
            show: true,
            success: true,
            title: 'Success!',
            message: 'Post updated.'
          }
        })
        self.originalSlug = self.training.slug
      })
    },

    addPostContent() {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'createTrainingContent',
        token: self.user.token,
        trainings_id: self.trainingId,
        type: this.addContentModalField.type,
        content: this.addContentModalField.content,
        label: this.addContentModalField.label
      }, res => {
        self.addContentModalField.label = ''
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }
        
        self.$store.commit('global/setGlobalData', {
          response: {
            show: true,
            success: true,
            title: 'Success!',
            message: 'Post content updated.'
          }
        })
        this.addContentModal = false;
        this.addContentModalField.type = '';
        this.addContentModalField.content = '';
        this.getTrainingContent();
      })
    },

    updatePostContent() {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'updateTrainingContent',
        token: self.user.token,
        id: this.updateContentModalField.trainingContentId,
        type: this.updateContentModalField.type,
        label: this.updateContentModalField.label,
        content: this.updateContentModalField.content,
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }
        
        self.$store.commit('global/setGlobalData', {
          response: {
            show: true,
            success: true,
            title: 'Success!',
            message: 'Post content updated.'
          }
        })
        this.updateContentModal = false;
        this.getTrainingContent();
      })
    },

    deletePostContent() {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'deleteTrainingContent',
        token: self.user.token,
        id: this.deleteContentModalField.trainingContentId,
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }
        
        self.$store.commit('global/setGlobalData', {
          response: {
            show: true,
            success: true,
            title: 'Success!',
            message: 'Post content deleted.'
          }
        })
        this.deleteContentModal = false;
        this.getTrainingContent();
      })
    },
    
    updateTrainingContentOrder() {
      // console.log(this.updateContentModalField);
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'updateTrainingContentOrder',
        token: self.user.token,
        listOfContent: JSON.stringify(this.trainingContents),
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }
        
        self.$store.commit('global/setGlobalData', {
          response: {
            show: true,
            success: true,
            title: 'Success!',
            message: 'Order successfully updated.'
          }
        })
        this.getTrainingContent();
      })
    },

    listPostPictures() {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'listPostPictures',
        token: self.user.token,
        training_id: self.trainingId,
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          self.$router.push('/admin/users')
          return
        }

        this.postPictureList = [];
        for (let i = 0; i < res.data.length; i++) {
          let record = {
            id: i,
            path: res.data[i],
            isSelected: false,
          }
          this.postPictureList.push(record);
        }
      })
    },

    imageSelected(selectedImage) {
      this.addContentModalField.content = selectedImage;
      this.updateContentModalField.content = selectedImage;
    },

    uploadProfilePicture() {
      this.$refs.uploadFile.$refs.input.click();
    },
    
    fileUpload (e) {
      console.log(e);
      let self = this
      let file = e
      if (!file || self.uploading) { return }

      let fd = new FormData()
      fd.append('requestType', 'uploadPostImage')
      fd.append('token', self.user.token)
      fd.append('file', file)

      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'uploadPostImage',
        token: self.user.token,
        post_image: file,
        isFile: true
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        self.$store.commit('global/setGlobalData', {
          response: {
            show: true,
            success: true,
            title: 'Success!',
            message: 'Image uploaded'
          }
        })

        this.updateContentModalField.content = res.image_path
        this.$refs.uploadFile.$refs.input.value = null;
        this.listPostPictures();
      })
    },

    confirmDelete () {
      this.deleteModal.show = true
      this.deleteModal.title = this.training.title
    },
    isNumber (evt) {
      let charCode = (evt.which) ? evt.which : evt.keyCode;
      console.log('aaa', charCode, charCode < 48, (charCode > 57 && charCode < 65), charCode > 90 )
      if (
        charCode == 45 || charCode == 95 ||    // Allow -_
        (charCode >= 48 && charCode <= 57) ||  // Allow 0-9
        (charCode >= 65 && charCode <= 90) ||  // Allow A-Z
        (charCode >= 97 && charCode <= 122) // Allow a-z
      ) {
        return true;
      }
      evt.preventDefault()
      return
    },
    removeSpecialChar () {
      this.training.slug = this.training.slug.replace(/[^a-zA-Z0-9-_]/g, '')
    },
    boxColor (x) {
      switch (x) {
        case '1': return 'type-text'
        case '2': return 'type-video'
        case '3': return 'type-image'
        case '4': return 'type-file'
      }
    },
    updateStatus () {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'setTrainingStatus',
        token: self.user.token,
        training_id: self.training.id,
        is_active: self.training.status ? 1 : 0
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          self.training.status = !self.training.status
          return
        }
      })
    },
    setAsFeatured () {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'setTrainingFeatured',
        token: self.user.token,
        training_id: self.training.id,
        is_featured: self.training.featured ? 0 : 1
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }
        self.training.featured = !self.training.featured
      })
    }
  },
  watch: {
    userId () {
      this.trainingId()
    }
  },
  mounted () {
    this.getTrainingInfo()
    this.getTrainingContent()
    this.listPostPictures()
  }
}
</script>

<style lang="scss" scoped>
.card-box{
  .head{
    font-weight: 600;
    line-height: 1;
    font-size: 21px;
    background: #1d2431;
    color: #fff;

    .subtitle-2{
      line-height: 2;
      font-weight: 400;
      color: #e1e4e8;
    }
  }
}

.post-content {
  cursor: grab;
}

.post-content.sortable-chosen {
  cursor: grabbing;
}

.image-item {
  border: solid 2px #ccc;
  border-radius: 15px;
  cursor: pointer;
}

.image-item-active {
  border: solid 5px #1976d2;
}

.select-image-container {
  //background: url('https://cdn.vuetifyjs.com/images/parallax/material.jpg') no-repeat center center fixed !important;
  background-color: #EAEDED;
  background-size: cover;
  max-height: 200px;
  overflow-y: auto;
}

.content-box{
  background: #fafafa;
  border-radius: 4px;

  &.type-text{
    background: #f2f2f2;
    border: 1px solid #d7d7d7;
  }
  &.type-video{
    background: #c18cdf;
    border: 1px solid #a96acd;
  }
  &.type-image{
    background: #96cfe3;
    border: 1px solid #75aec2;
  }
  &.type-file{
    background: #86eea0;
    border: 1px solid #6ec283;
  }
  .chip{
    background: #333;
    color: #fff;
    text-transform: uppercase;
    width: 60px;
    text-align: center;
    display: inline-block;
  }
  .post-type{
    width: 100px;

    b{
      height: 24px;
      font-size: 14px;
      display: inline-block;
      vertical-align: -1px;
      text-transform: uppercase;
    }
  }
  .post-value{
    width: calc(100% - 180px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .action{
    width: 80px;
  }
}
</style>
