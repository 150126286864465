export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    id: '',
    email: '',
    type: '',
    firstName: '',
    lastName: '',
    mobile: '',
    aplGoUser: '',
    aplGoSponsorId: '',
    instagram: '',
    facebook: '',
    profilePic: '',
    isVip: false,
    vipExpiry: '',
    mbid: '',
    sponsorMbid: '',
    sponsorId: '',
    busdWallet : '',
    referralLink : '',
  },
  mutations: {
    setUserData (state, data) {
      for (let field in data) {
        state[field] = data[field]
      }
    },
  }
}