<template>
  <div class="main d-flex justify-center align-center flex-column px-4 px-md-0">
    <div class="text-left box mb-2" v-if="!user.token && !user.invitationCode">
      <a @click="back" class="lr-text back">
        <i class="fa-solid fa-arrow-left fa-fw"></i> BACK
      </a>
    </div>
    <v-card elevation="4" outlined color="#1d2431" class="px-7 py-5 text-center box mb-5" dark>
      <div class="lr-header _title">
        FORGOT PASSWORD?
      </div>
      <div class="lr-header desc mb-8">
        Enter the email associated with your account and we'll send an email with 
        instruction to reset password.
      </div>
      <div class="lr-title">PASSWORD</div>
      <v-text-field filled outlined hide-details="auto" dense type="password"
        v-model="password" :error-messages="error.password"
        hint="At least 8 characters" @change="fieldChange('password')"
      ></v-text-field>

      <div class="lr-title mt-4">REPEAT PASSWORD</div>
      <v-text-field filled outlined hide-details="auto" dense type="password"
        v-model="rpassword" :error-messages="error.rpassword"
        hint="At least 8 characters" @change="fieldChange('rpassword')"
      ></v-text-field>

      <v-btn color="#2d8c40" block class="mt-6" large @click="resetPass">RESET PASSWORD</v-btn>

    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      password: '',
      rpassword: '',
      error: {
        password: '',
        rpassword: ''
      }
    }
  },
  computed: {
    id () { return this.$route.params.id },
    user () { return this.$store.state.user },
  },
  methods: {
    back () {
      this.$router.push('/')
    },
    fieldChange (field) {
      if (this.error[field] && this[field]) {
        this.error[field] = ''
      }
    },
    resetPass () {
      let self = this
      let fields = ['password', 'rpassword']
      let hasError = false

      fields.forEach(field => {
        self.error[field] = ''
        if (!self[field]) {
          hasError = true
          self.error[field] = 'This field is required.'
        }
      })

      if (self.password.length < 8) {
        hasError = true
        self.error.password = 'Password must be atleast 8 characters.'
      }
      if (self.rpassword.length < 8) {
        hasError = true
        self.error.rpassword = 'Repeat password must be atleast 8 characters.'
      }
      if (self.password && self.rpassword && self.password !== self.rpassword) {
        hasError = true
        self.error.password = 'Password and repeat password doesn\'t match.'
        self.error.rpassword = ' '
      }

      if (hasError) return

      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'resetPassword',
        reset_password_code: self.id,
        password: self.password,
        password_repeat: self.rpassword
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        self.$store.commit('global/setGlobalData', {
          response: {
            show: true,
            success: true,
            title: 'Password Updated!',
            message: 'Your password has been updated.'
          }
        })
        self.$router.push('/auth')
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.main{
  width: 100%;
  height: 100%;
}
.box{
  max-width: 500px;
  width: 100%;
}
.back{
  font-weight: 600;
}
._title{
  font-weight: 600;
  font-size: 36px;
}
.desc{
  color: #d9dde5;
}
.login-box{
  color: #777;

  a{
    font-weight: 600;
    text-decoration: underline;
  }
}
</style>