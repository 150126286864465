<template>
  <div class="main px-4">
    <div class="page-title">Edit Profile</div>
    <div class="mb-12">
      <v-chip label :color="userType(user.type).color" dark small>
        {{ userType(user.type).label }}
      </v-chip>
    </div>

    <v-row>
      <v-col class="col-12 col-md-8">
        <v-card elevation="4" outlined color="#1d2431" class="form-box pa-7 mb-md-5" dark>
          <v-row>
            <v-col class="col-12 col-md-12">
              <div class="lr-title">Email</div>
              <v-text-field filled outlined hide-details="auto" dense type="email"  disabled
                v-model="user.email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="col-12 col-md-6">
              <div class="lr-title">First Name</div>
              <v-text-field filled outlined hide-details="auto" dense
                v-model="firstName" :error-messages="error.firstName"
                @change="fieldChange('firstName')"
              ></v-text-field>
            </v-col>
            <v-col class="col-12 col-md-6">
              <div class="lr-title">Last Name</div>
              <v-text-field filled outlined hide-details="auto" dense
                v-model="lastName" :error-messages="error.lastName"
                @change="fieldChange('lastName')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="col-12 col-md-12">
              <div class="lr-title">Contact Number</div>
              <v-text-field filled outlined hide-details="auto" dense
                v-model="mobile" :error-messages="error.mobile"
                @change="fieldChange('mobile')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="col-12 col-md-12">
              <div class="lr-title">Instagram URL</div>
              <v-text-field filled outlined hide-details="auto" dense
                v-model="instagram" :error-messages="error.instagram"
                @change="fieldChange('instagram')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="col-12 col-md-12">
              <div class="lr-title">Facebook URL</div>
              <v-text-field filled outlined hide-details="auto" dense
                v-model="facebook" :error-messages="error.facebook"
                @change="fieldChange('facebook')"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- <v-row>
            <v-col class="col-12 col-md-12">
              <v-divider/>
            </v-col>
          </v-row> -->

          <!-- <v-row>
            <v-col class="col-12 col-md-12">
              <div class="lr-title">APL Go  - Member’s ID</div>
              <small>
                You must be a registered member in APL Go to have this ID. Check your 
                <a href="https://ph.aplgo.com/" target="_blank">APL Go backoffice</a> to get your Member ID.
              </small>
              <v-text-field filled outlined hide-details="auto" dense
                v-model="aplGoUser" :error-messages="error.aplGoUser"
                @change="fieldChange('aplGoUser')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="col-12 col-md-12">
              <div class="lr-title">APL GO Sponsor ID</div>
              <v-text-field filled outlined hide-details="auto" dense
                v-model="aplGoSponsorId" :error-messages="error.aplGoSponsorId"
                @change="fieldChange('aplGoSponsorId')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="col-12 col-md-6">
              <div class="lr-title">Left ID</div>
              <v-text-field filled outlined hide-details="auto" dense
                v-model="leftId" :error-messages="error.leftId"
                @change="fieldChange('leftId')"
              ></v-text-field>
            </v-col>
            <v-col class="col-12 col-md-6">
              <div class="lr-title">Right ID</div>
              <v-text-field filled outlined hide-details="auto" dense
                v-model="rightId" :error-messages="error.rightId"
                @change="fieldChange('rightId')"
              ></v-text-field>
            </v-col>
          </v-row> -->

          <v-row>
            <v-col class="col-12 col-md-12">
              <v-divider/>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="col-12 col-md-12">
              <div class="lr-title">Millionaire Build ID (MBID)</div>
              <v-text-field filled outlined hide-details="auto" dense disabled
                v-model="mbid" :error-messages="error.mbid"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="col-12 col-md-12">
              <div class="lr-title">Sponsor MBID</div>
              <v-text-field filled outlined hide-details="auto" dense
                v-model="sponsorMbid" :error-messages="error.sponsorMbid"
                @change="fieldChange('sponsorMbid')" :disabled="!!mbSponsorID"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="col-12 col-md-12">
              <div class="lr-title">BUSD Wallet Address</div>
              <small>
                Make sure the address is correct. Incorrect address may result in asset/token lost and is irreversible. Leaving this blank will also result in commission loss.
              </small>
              <v-text-field filled outlined hide-details="auto" dense
                v-model="busdWallet" :error-messages="error.busdWallet"
                @change="fieldChange('busdWallet')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn color="#2d8c40" block class="mt-6" large @click="updateProfile">Update Profile</v-btn>
        </v-card>
      </v-col>
      <v-col class="col-12 col-md-4">
        <v-card elevation="4" outlined color="#1d2431" class="pa-5 text-center mb-5" dark>
          <v-avatar size="200" class="mb-5">
            <i class="fa fa-spinner fa-spin fa-5x" v-if="uploading"></i>
            <img :src="profilePic" v-else/>
          </v-avatar>
          <input type="file" v-show="false" @change="fileUpload($event)" ref="file" />
          <v-btn color="#2d8c40" block class="white--text" large @click="uploadProfilePicture">Change Profile Picture</v-btn>
        </v-card>

        <v-card elevation="4" outlined color="#1d2431" class="pa-5 text-center mb-5" dark v-if="user.type == 2">
          <div class="text-center mb-5 lr-header _title">
            <!-- AVAILABILITY -->
            AGENT INFO
          </div>

          <div class="lr-title text-left mb-3">Availability</div>
          <v-row>
            <v-col class="py-1">
              <v-checkbox label="Monday" color="orange" value="1" hide-details class="mt-0"
                v-model="availability.monday"></v-checkbox>
            </v-col>
            <v-col class="py-1">
              <v-checkbox label="Tuesday" color="orange" value="1" hide-details class="mt-0"
                v-model="availability.tuesday"></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-1">
              <v-checkbox label="Wednesday" color="orange" value="1" hide-details class="mt-0"
                v-model="availability.wednesday"></v-checkbox>
            </v-col>
            <v-col class="py-1">
              <v-checkbox label="Thursday" color="orange" value="1" hide-details class="mt-0"
                v-model="availability.thursday"></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-1">
              <v-checkbox label="Friday" color="orange" value="1" hide-details class="mt-0"
                v-model="availability.friday"></v-checkbox>
            </v-col>
            <v-col class="py-1">
              <v-checkbox label="Saturday" color="orange" value="1" hide-details class="mt-0"
                v-model="availability.saturday"></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-1">
              <v-checkbox label="Sunday" color="orange" value="1" hide-details class="mt-0"
                v-model="availability.sunday"></v-checkbox>
            </v-col>
          </v-row>

          <v-row class="mt-8">
            <v-col class="py-1">
              <v-select label="From" outlined class="black--text" :items="timeSlots"
                v-model="availability.from" :error-messages="avError.from"></v-select>
            </v-col>
            <v-col class="py-1">
              <v-select label="To" outlined class="black--text" :items="timeSlots"
                v-model="availability.to" :error-messages="avError.to"></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="col-12 col-md-12 pb-1" v-if="user.type == 2">
              <div class="lr-title text-left mb-1">Agent Description</div>
              <v-text-field filled outlined hide-details="auto" dense
                v-model="description" :error-messages="avError.description"
                @change="avError.description = !!description ? '' : 'This field is required.'"
                hint="Please tell us about yourself, or an agent"
                :counter="150" :rules="[v => (v || '' ).length <= 150 || 'Description must be 150 characters or less']"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="my-4">
            <v-col class="py-1">
              <div class="text-left lr-title mb-1">Agent Note:</div>
              <v-textarea filled outlined hide-details="auto" dense no-resize persist v-model="availability.note"></v-textarea>
            </v-col>
          </v-row>

          <v-btn color="#2d8c40" block class="white--text mt-5" large @click="updateAvailability">Update Availability</v-btn>
        </v-card>
      </v-col>
      <v-col class="col-12 col-md-8">
        <v-card elevation="4" outlined color="#1d2431" class="form-box pa-7 mb-md-5" dark>
          <v-row>
            <v-col class="col-12 col-md-12">
              <div class="lr-title">GCash Number</div>
              <small>
                Make sure your gcash number is correct and active.
              </small>
              <v-text-field filled outlined hide-details="auto" dense
                v-model="gcash" :error-messages="error.gcash"
                @change="fieldChange('gcash')"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-btn color="#2d8c40" block class="mt-6" large @click="updateGcashInformation">Update GCash Information</v-btn>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import axios from 'axios'
import moment from 'moment'
export default {
  name: 'Profile',
  metaInfo: {
    title: 'User Profile',
  },
  data () {
    return {
      firstName: '',
      lastName: '',
      mobile: '',
      aplGoUser: '',
      aplGoSponsorId: '',
      leftId: '',
      rightId: '',
      instagram: '',
      facebook: '',
      profilePic: '',
      mbid: '',
      sponsorMbid: '',
      busdWallet: '',
      gcash: '',
      error: {
        firstName: '',
        lastName: '',
        mobile: '',
        description: '',
        aplGoUser: '',
        aplGoSponsorId: '',
        leftId: '',
        rightId: '',
        instagram: '',
        facebook: '',
        mbid: '',
        sponsorMbid: '',
        busdWallet: '',
        gcash: '',
      },
      uploading: false,
      timeSlots: [],
      description: '',
      note: '',
      avError: {
        from: '',
        to: '',
        description: ''
      },
      mbSponsorID: ''
    }
  },
  computed: {
    user () { return this.$store.state.user },
    availability () { return this.user.availability }
  },
  methods: {
    userType (type) {
      switch (type) {
        case '1': return { label: 'Admin', color: '#1d2431' }
        case '2': return { label: 'Agent', color: 'warning' }
        case '3': return { label: 'Member', color: 'green' }
        default: return { label: '', color: '' }
      }
    },
    fieldChange (field) {
      if (this.error[field] && this[field]) {
        this.error[field] = ''
      }
    },
    updateProfile () {
      let self = this
      let fields = [
        'firstName', 'lastName', 'mobile',
        'instagram', 'facebook',
        'sponsorMbid', 'busdWallet'
      ]
      let hasError = false

      fields.forEach(field => {
        self.error[field] = ''
        if (!self[field]) {
          hasError = true
          self.error[field] = 'This field is required.'
        }
      })

      if (hasError) return
      
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'updateUserInfo',
        token: self.user.token,
        first_name: self.firstName,
        last_name: self.lastName,
        contact_number: self.mobile,
        apl_go_user: self.aplGoUser,
        apl_go_sponsor_id: self.aplGoSponsorId,
        left_id: self.leftId,
        right_id: self.rightId,
        instagram_url: self.instagram,
        facebook_url: self.facebook,
        sponsor_mbid: self.sponsorMbid,
        busd_address: self.busdWallet
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        self.$store.commit('global/setGlobalData', {
          response: {
            show: true,
            success: true,
            title: 'Success!',
            message: 'Your profile was updated.'
          }
        })
      })
    },
    getUserInfo () {
      let self = this

      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'getUserInfo',
        token: self.user.token
      }, res => {
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            loading: false,
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          self.$router.push('/auth')
          return
        }

        self.firstName = res.data.first_name
        self.lastName = res.data.last_name
        self.mobile = res.data.contact_number
        self.aplGoUser = res.data.apl_go_user
        self.aplGoSponsorId = res.data.apl_go_sponsor_id
        self.leftId = res.data.left_id || ''
        self.rightId = res.data.right_id || ''
        self.instagram = res.data.instagram_url
        self.facebook = res.data.facebook_url
        self.profilePic = res.data.profile_pic
        self.mbid = res.data.mbid
        self.sponsorMbid = res.data.sponsor_mbid
        self.mbSponsorID = res.data.sponsor_mbid
        self.busdWallet = res.data.busd_address
        self.gcash = res.data.gcash

        self.$store.commit('user/setUserData', {
          id: res.data.id,
          email: res.data.email,
          type: res.data.user_type,
          firstName: res.data.first_name,
          lastName: res.data.last_name,
          mobile: res.data.contact_number,
          aplGoUser: res.data.apl_go_user,
          aplGoSponsorId: res.data.apl_go_sponsor_id,
          instagram: res.data.instagram_url,
          facebook: res.data.facebook_url,
          profilePic: res.data.profile_pic,
          isVip: res.data.is_vip == 1,
          vipExpiry: res.data.vip_expiry,
          mbid: res.data.mbid,
          sponsorMbid: res.data.sponsor_mbid,
          sponsorId: res.data.sponsor_id,
          busdWallet: res.data.busd_address,
          referralLink: res.data.referral_link,
          gcash: res.data.gcash,
        })

        if (res.data.user_type == 2) {
          self.getAgentInfo()
        } else {
          self.$store.commit('global/setGlobalData', {loading: false})
        }
      })
    },
    getAgentInfo () {
      let self = this

      self.$sdk.post('', {
        requestType: 'getAgentInfo',
        token: self.user.token
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        self.$store.commit('user/setAvailabilityData', {
          from: res.data.availability_from,
          to: res.data.availability_to,
          timezone: res.data.availability_timezone,
          monday: res.data.is_available_monday,
          tuesday: res.data.is_available_tuesday,
          wednesday: res.data.is_available_wednesday,
          thursday: res.data.is_available_thursday,
          friday: res.data.is_available_friday,
          saturday: res.data.is_available_saturday,
          sunday: res.data.is_available_sunday,
          description: res.data.agent_description,
          note: res.data.availability_note,
        })
        self.description = res.data.agent_description
        self.note =  res.data.availability_note
      })
    },
    uploadProfilePicture() {
      if (this.$refs.file.click()) {
        this.$refs.file.click()
      }
    },
    fileUpload (e) {
      let self = this
      let file = e.target.files[0]
      if (!file || self.uploading) { return }

      let fd = new FormData()
      fd.append('requestType', 'uploadProfilePicture')
      fd.append('token', self.user.token)
      fd.append('file', file)

      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'uploadProfilePicture',
        token: self.user.token,
        profile_picture: file,
        isFile: true
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        self.$store.commit('user/setUserData', {profilePic: res.profile_pic})
        self.profilePic = res.profile_pic
        self.$store.commit('global/setGlobalData', {
          response: {
            show: true,
            success: true,
            title: 'Success!',
            message: 'Your profile picture was updated.'
          }
        })
        self.$refs.file.value = null;
      })
    },
    addZero (x) { return x < 10 ? `0${x}` : x },
    updateAvailability () {
      let self = this
      let hasError = false

      if (!self.availability.from) {
        hasError = true
        self.avError.from = 'This field is required.'
      }
      if (!self.availability.to) {
        hasError = true
        self.avError.to = 'This field is required.'
      }
      if (
        self.availability.to && self.availability.from &&
        moment(self.availability.to, 'hh:mmA').diff(moment(self.availability.from, 'hh:mmA'), 'minutes') <= 0
      ) {
        hasError = true
        self.avError.to = 'Invalid time range'
      }

      if (!self.description) {
        hasError = true
        self.avError.description = 'This field is required.'
      }
      if (self.user.type == 2 && self.description && self.description.length > 150) {
        hasError = true
        self.avError.description = 'Cannot be more than 150 characters.'
      }


      if (hasError) return
      
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'updateAgentInfo',
        token: self.user.token,
        availability_from: self.availability.from,
        availability_to: self.availability.to,
        availability_timezone: self.availability.timezone,
        is_available_monday: self.availability.monday,
        is_available_tuesday: self.availability.tuesday,
        is_available_wednesday: self.availability.wednesday,
        is_available_thursday: self.availability.thursday,
        is_available_friday: self.availability.friday,
        is_available_saturday: self.availability.saturday,
        is_available_sunday: self.availability.sunday,
        agent_description: self.description,
        availability_note: self.availability.note,
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        self.$store.commit('global/setGlobalData', {
          response: {
            show: true,
            success: true,
            title: 'Success!',
            message: 'Your profile was updated.'
          }
        })
      })
    },
    updateGcashInformation () {
      let self = this
      /*let fields = [
        'firstName', 'lastName', 'mobile',
        'instagram', 'facebook',
        'sponsorMbid', 'busdWallet'
      ]
      let hasError = false

      fields.forEach(field => {
        self.error[field] = ''
        if (!self[field]) {
          hasError = true
          self.error[field] = 'This field is required.'
        }
      })

      if (hasError) return*/
      
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'gcash_registerViaProfile',
        token: self.user.token,
        gcash: self.gcash,
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }
        self.$store.commit('global/setGlobalData', {
          response: {
            show: true,
            success: true,
            title: 'Success!',
            message: 'Your GCash information was updated.'
          }
        })
      })
    },
  },
  mounted () {
    this.getUserInfo()

    for (let i = 0; i < 24; i++) {
      if (i < 12) {
        this.timeSlots.push(`${this.addZero(i)}:00AM`)
        this.timeSlots.push(`${this.addZero(i)}:30AM`)
      } else {
        this.timeSlots.push(`${this.addZero(i - 12)}:00PM`)
        this.timeSlots.push(`${this.addZero(i - 12)}:30PM`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.main{
  width: 100%;
}
.form-box{
  width: 100%;
}
.profile-pic{
  width: 100%;
}
._title{
  font-weight: 600;
  font-size: 28px;
}

</style>