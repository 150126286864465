<template>
  <div class="px-4">
    <div class="page-title">Announcements</div>
    <div class="sub-title mb-7">List of website announcement.</div>

    <v-btn large color="success" @click="createPostModal = true" class="mb-4">
      <v-icon class="mr-2">mdi-receipt-text-plus-outline</v-icon>Create Announcement
    </v-btn>

    <v-card elevation="5" outlined class="px-7 py-10 mb-7">
      <div v-for="(post, i) in posts" :key="i">
        <div class="lr-header d-flex post-box">
          <div>
            <a :href="`#/admin/post/${post.id}`">
              <v-img src="https://ionicframework.com/docs/img/demos/thumbnail.svg"
              class="mx-4" width="150"></v-img>
            </a>
          </div>
          <div class="desc">
            <div>
              <div class="featured-box py-1 px-3" v-if="post.featured">FEATURED</div>
            </div>
            <a :href="`#/admin/post/${post.id}`" class="vlog-title">{{post.title}}</a>
            <div class="date">Created: {{post.created}}</div>
            <div class="date mb-3">Last Update: {{post.updated}}</div>
            <div class="vlog-desc">{{post.description}}</div>
            <div class="action-box mt-2 d-flex align-center">
              <div>
                <a :href="`#/post/${post.slug}`">View</a>
                <span class="mx-4">|</span>
                <a :href="`#/admin/post/${post.id}`">Edit</a>
                <span class="mx-4">|</span>
                <a @click="confirmDelete(post)">Delete</a>
              </div>
            </div>
          </div>
          <div>
            <v-switch v-model="post.status" inset class="mt-0 px-2" hide-details
              :readonly="post.loading" :loading="post.loading" color="#2E7D32"
              @click="updateStatus(post)"></v-switch>
            <div class="mt-1 status">
              <b>{{ post.status ? 'Active' : 'Inactive' }}</b>
            </div>
          </div>
        </div>
        <v-divider class="my-6" v-if="i != posts.length - 1" />
      </div>

      <div v-if="loading && !posts.length" class="text-center py-4 loader lr-text">
        <v-progress-circular indeterminate color="primary" class="mb-0 mr-3"></v-progress-circular>
        <b>Getting Announcement . . .</b>
      </div>

      <div v-if="!loading && !posts.length" class="text-center py-4 empty-state lr-text">
        <i class="fa-regular fa-face-grin-wide fa-5x mb-4"></i>
        <div>No Announcement as of yet.</div>
        <div>Please come back later.</div>
      </div>
    </v-card>

    <!-- CREATE POST MODAL -->
    <v-dialog v-model="createPostModal" persistent max-width="800">
      <v-card class="pt-3">
        <v-card-title class="text-h5">Create Post</v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="col-12">
              <div class="lr-header">Title</div>
              <v-text-field outlined hide-details="auto" dense
                v-model="createPostModalField.title"
              ></v-text-field>
            </v-col>
            <v-col class="col-12">
              <div class="lr-header">Description</div>
              <v-textarea outlined hide-details="auto" dense
                v-model="createPostModalField.description"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="mt-3" />
        <v-card-actions>
          <v-btn color="grey darken-1" text @click="createPostModal=false">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="createPost()">
            Create Post
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <delete-post-modal :data="deleteModal" @success="getPosts"
      @hide="deleteModal.show = false"></delete-post-modal>

  </div>
</template>

<script>
import deletePostModal from './delete-post-modal'

export default {
  name: 'Training',
  components: { deletePostModal },
  data () {
    return {
      posts: [],
      loading: false,
      createPostModal: false,
      createPostModalField: {
        title: '',
        description: '',
      },
      deleteModal: {
        show: false,
        id: 0,
        title: ''
      }
    }
  },
  computed: {
    id () { return this.$route.params.id },
    user () { return this.$store.state.user },
  },
  methods: {
    updateStatus (data) {
      let self = this
      if (!data.id) return

      let index = self.posts.findIndex(x => x.id === data.id)
      if (self.posts[index].loading) return
      self.posts[index].loading = true
      self.$sdk.post('', {
        requestType: 'setTrainingStatus',
        token: self.user.token,
        training_id: data.id,
        is_active: data.status ? 1 : 0
      }, res => {
        self.posts[index].loading = false
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          self.posts[index].status = !self.posts[index].status
          return
        }
      })
    },
    getPosts () {
      let self = this
      self.loading = true
      self.$sdk.post('', {
        requestType: 'getListOfTrainings',
        token: self.user.token
      }, res => {
        self.loading = false
        if (res.data.status == 'failed') {
          return
        }
        //console.log(res);
       self.posts = []
        res.data.forEach(post => {
          self.posts.push({
            id: post.id,
            name: `${post.first_name} ${post.last_name}`,
            title: post.title,
            created: post.created_at,
            updated: post.updated_at,
            description: post.description,
            status: post.status == '1',
            featured: post.is_featured == '1',
            slug: post.slug,
            loading: false
          })
        })
      })
    },
    createPost () {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      let slug = this.createPostModalField.title.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-')
      self.$sdk.post('', {
        requestType: 'createTraining',
        token: self.user.token,
        title: this.createPostModalField.title,
        description: this.createPostModalField.description,
        slug: slug
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }
        
        self.$store.commit('global/setGlobalData', {
          response: {
            show: true,
            success: true,
            title: 'Success!',
            message: 'Post created.'
          }
        })
        self.$router.push(`/admin/post/${res.data.id}`)
      })
    },
    confirmDelete (data) {
      this.deleteModal = {
        show: true,
        id: data.id,
        title: data.title
      }
    }
  },
  mounted () {
    this.getPosts();
  }
}
</script>

<style lang="scss" scoped>
.vlog-title{
  font-size: 21px;
  word-spacing: 2px;
  color: #1d2431!important;
  text-decoration: none;

  &:hover{
    text-decoration: underline;
  }
}
.desc{
  width: 100%;
}
.status{
  color: #555;
}
.featured-box{
  font-size: 13px;
  letter-spacing: 1px;
  background: #E65100;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  line-height: 1;
}
.date{
  letter-spacing: 2px;
  color: #868f9d;
  font-size: 13px;
  line-height: 1.3;
}
.vlog-desc{
  color: #666;
  word-spacing: 2px;
}
.action-box{
  height: 30px;
  * {
    display: inline-block;
    line-height: 1;
  }
  div{
    z-index: -1;
    opacity: 0;
    transform: translateY(15px);
    transition: .3s all ease;
  }
  a{
    font-weight: 600;
    text-decoration: none;
  }
}
.post-box:hover{
  .action-box div{
    z-index: 1;
    opacity: 1;
    transform: translateY(0);
  }
}

.loader{
  font-size: 21px;
  color: #868f9d;
}
.empty-state{
  i{
    color: #1d2431;
  }
  div{
    color: #868f9d;
    font-size: 21px;
    font-weight: 600;
  }
}
</style>



























