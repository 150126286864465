import axios from 'axios'
const queryString = require('query-string')
// import Vue from 'vue'

class SDK {
  constructor () {
    this.url = 'https://team-builders.online/api/'
  }

  get (route, params, callback) {
    let self = this
    let qs = ''
    if (Object.values(params).length > 0) {
      qs = '?' + queryString.stringify(params)
    }
    let requestUrl = self.url + route + qs
    let settings = {
      method: 'GET',
      url: requestUrl,
      responseType: 'json'
    }
    // let authToken = Vue.$cookies.get('carrrAdminToken')
    // if (authToken) {
    //   settings.headers = {'Authorization': 'Bearer ' + authToken}
    // }
    axios(settings).then(r => {
      callback(r.data)
    }).catch (err => {
      callback({ error: err.response.data })
    })
  }

  post (route, params, callback) {
    let self = this
    let requestUrl = self.url + route
    let settings = {
      method: 'POST',
      url: requestUrl,
      // responseType: 'json',
      data: params,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/x-www-form-urlencoded',
      }
    }
    if (params.isFile) {
      settings.headers['Content-type'] = 'multipart/form-data'
    }
    // let authToken = Vue.$cookies.get('carrrAdminToken')
    // if (authToken) {
    //   settings.headers = {'Authorization': 'Bearer ' + authToken}
    // }
    axios(settings).then(r => {
      callback(r.data)
    }).catch (err => {
      console.log(err)
      callback({ error: err })
    })
  }
}

export default SDK
