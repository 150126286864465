import session from './session'
import info from './info'
import availability from './availability'

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: Object.assign(
    session.state,
    info.state,
    availability.state,
  ),
  mutations: Object.assign(
    session.mutations,
    info.mutations,
    availability.mutations,
  )
}
