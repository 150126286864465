import list from './list'
import info from './info'

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: Object.assign(
    list.state,
    info.state,
  ),
  mutations: Object.assign(
    list.mutations,
    info.mutations,
  )
}
