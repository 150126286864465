<template>
  <nav class="nav">
    <v-toolbar class="web-mode" dark>
      <v-toolbar-title>
        <a href="#/">
          <img src="@/assets/img/nav-logo.png" class="logo" />
        </a>
      </v-toolbar-title>

      <v-spacer />

      <div class="d-none d-md-block">
        <!--<a href="#/agents" :class="['mx-2', {active:isActive('AgentList')}]"
          v-if="(!isLogin && hasInvitationCode) || isLogin">
          Agents
        </a>-->

        <a href="#/products" :class="['mx-2', {active:isActive('Products')}]"
          v-if="(!isLogin && hasInvitationCode) || isLogin">
          Products
        </a>

        <a href="#/vip" :class="['mx-2', {active:isActive('VipPresentation')}]"
          v-if="(!isLogin && hasInvitationCode) || isLogin">
          VIP Presentation
        </a>
        <a class="mx-2" v-if="isLogin">
          <v-menu transition="slide-y-transition" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <a v-bind="attrs" v-on="on" class="py-5">
                Premium - VIP
                <i class="fa-solid fa-caret-down fa-fw"></i>
              </a>
            </template>
            <v-list>
              <v-list-item>
                <a href="#/premium" class="list-item" style="width: 100%">
                  <v-list-item-title>View</v-list-item-title>
                </a>
              </v-list-item>
              <v-list-item>
                <a href="#/premium/tree" class="list-item d-block" style="width: 100%">
                  <v-list-item-title>Tree</v-list-item-title>
                </a>
              </v-list-item>
            </v-list>
          </v-menu>
        </a>

        <a class="mx-2" v-if="(!isLogin && hasInvitationCode) || isLogin">
          <v-menu transition="slide-y-transition" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <a v-bind="attrs" v-on="on" class="py-5">
                Training
                <i class="fa-solid fa-caret-down fa-fw"></i>
              </a>
            </template>
            <v-list>
              <v-list-item>
                <a href="#/training/fast_start" class="list-item">
                  <v-list-item-title>Fast Start</v-list-item-title>
                </a>
              </v-list-item>
              <v-list-item>
                <a href="#/training/english" class="list-item">
                  <v-list-item-title>English</v-list-item-title>
                </a>
              </v-list-item>
              <v-list-item>
                <a href="#/training/taglish" class="list-item">
                  <v-list-item-title>Taglish</v-list-item-title>
                </a>
              </v-list-item>
              <v-list-item>
                <a href="#/training/live" class="list-item">
                  <v-list-item-title>Live</v-list-item-title>
                </a>
              </v-list-item>
            </v-list>
          </v-menu>
        </a>

        <a href="#/binary-tree" :class="['mx-2', {active:isActive('CoreTeam')}]"
          v-if="isLogin && isAdmin">
          Binary Tree
        </a>

        <a href="#/posts" :class="['mx-2', {active:isActive('PostList')}]"
          v-if="(!isLogin && hasInvitationCode) || isLogin">
          Announcement
        </a>

        <a href="#/profile" :class="['mx-2', {active:isActive('Profile')}]"
          v-if="isLogin && !isAdmin">
          Profile
        </a>

        <a href="#/auth/signup-millionaire-build" :class="['mx-2', {active:isActive('Signup')}]"
          v-if="!isLogin && hasInvitationCode">
          Signup
        </a>
        <a href="#/auth" :class="['mx-2', {active:isActive('Auth')}]"
          v-if="!isLogin && hasInvitationCode">
          Login
        </a>

        <a @click="logoutUser" class="mx-2" v-if="isLogin">Logout</a>
      </div>

      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="d-md-none"></v-app-bar-nav-icon>
    </v-toolbar>

    <v-toolbar class="sub-menu d-none d-md-block" height="40" v-if="isAdmin">
      <v-spacer />
      <div>
        <a href="#/admin/posts" :class="['mx-3', {active:isActive('AdminPosts')}]"
          v-if="isLogin && isAdmin">
          Manage Announcement
        </a>
      
        <a href="#/admin/analytics" :class="['mx-3', {active:isActive('AdminAnalytics')}]"
          v-if="isLogin && isAdmin">
          Analytics
        </a>

        <a href="#/admin/products" :class="['mx-3', {active:isActive('AdminProducts')}]"
          v-if="isLogin && isAdmin">
          Products
        </a>

        <a href="#/admin/gcash" :class="['mx-3', {active:isActive('AdminGCashGiveAways')}]"
          v-if="isLogin && isAdmin">
          GCash
        </a>

        <a href="#/admin/leaderboard" :class="['mx-3', {active:isActive('AdminLeaderboard')}]"
          v-if="isLogin && isAdmin">
          Leaderboard
        </a>
      
        <a href="#/admin/transactions" :class="['mx-3', {active:isActive('AdminTransactions')}]"
          v-if="isLogin && isAdmin">
          Transactions
        </a>

        <a href="#/admin/users" :class="['mx-3', {active:isActive('AdminUsers')}]"
          v-if="isLogin && isAdmin">
          Users
        </a>
      </div>
    </v-toolbar>

    <v-toolbar class="sub-menu d-none d-md-block px-5" height="40" v-else-if="user.mbid">
      <div class="lr-header">
        <label class="mr-1 lr-header">MBID</label>
        <b class="mr-7">#{{user.mbid}}</b>
        <label class="mr-1 lr-header" v-if="user.sponsorMbid">SPONSOR MBID</label>
        <b v-if="user.sponsorMbid">#{{user.sponsorMbid}}</b>
      </div>
      <v-spacer />
      <div>
        <a href="#/admin/gcash" :class="['mx-3', {active:isActive('AdminGCashGiveAways')}]"
          v-if="isLogin">
          GCash
        </a>
      </div>
    </v-toolbar>

    <v-navigation-drawer v-model="drawer" absolute left temporary>
      <v-list nav dense class="mobile-nav">
        <v-list-item-group v-model="group">
          <!--<v-list-item v-if="(!isLogin && hasInvitationCode) || isLogin" to="/agents">
            Agents
          </v-list-item>-->

          <v-list-item v-if="(!isLogin && hasInvitationCode) || isLogin" to="/products">
            Products
          </v-list-item>

          <v-list-item v-if="(!isLogin && hasInvitationCode) || isLogin" to="/vip">
            VIP Presentation
          </v-list-item>
        </v-list-item-group>

        <v-list-group :value="false" v-if="isLogin">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="mobile-link">Premium - VIP</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item to="/premium" selectable>
            <v-list-item-title class="mobile-link pl-4">View</v-list-item-title>
          </v-list-item>
          <v-list-item to="/premium/tree" selectable>
            <v-list-item-title class="mobile-link pl-4">Tree</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="false" v-if="(!isLogin && hasInvitationCode) || isLogin">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="mobile-link">Training</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item to="/training/fast_start" selectable>
            <v-list-item-title class="mobile-link pl-4">Fast Start</v-list-item-title>
          </v-list-item>
          <v-list-item to="/training/english" selectable>
            <v-list-item-title class="mobile-link pl-4">English</v-list-item-title>
          </v-list-item>
          <v-list-item to="/training/taglish" selectable>
            <v-list-item-title class="mobile-link pl-4">Taglish</v-list-item-title>
          </v-list-item>
          <v-list-item to="/training/live" selectable>
            <v-list-item-title class="mobile-link pl-4">Live</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-item-group v-model="group">
          <v-list-item v-if="isLogin && isAdmin" to="/binary-tree">
            Binary Tree
          </v-list-item>
          <v-list-item v-if="(!isLogin && hasInvitationCode) || isLogin" to="/posts">
            Annoucement
          </v-list-item>
        </v-list-item-group>

        <v-list-item-group v-model="group"  v-if="isLogin && isAdmin" class="admin-links">
          <v-subheader class="sub-header">Admin Links</v-subheader>
          <v-list-item to="/admin/posts">
            Manage Annoucement
          </v-list-item>
          <v-list-item v-if="isLogin && isAdmin" to="/admin/analytics">
            Analytics
          </v-list-item>
          <v-list-item v-if="isLogin && isAdmin" to="/admin/products">
            Products
          </v-list-item>
          <v-list-item v-if="isLogin && isAdmin" to="/admin/gcash">
            GCash
          </v-list-item>
          <v-list-item v-if="isLogin && isAdmin" to="/admin/leaderboard">
            Leaderboard
          </v-list-item>
          <v-list-item v-if="isLogin && isAdmin" to="/admin/transactions">
            Transactions
          </v-list-item>

          <v-list-item v-if="isLogin && isAdmin" to="/admin/users">
            Users
          </v-list-item>
        </v-list-item-group>
        
        <v-list-item-group v-model="group">
          <v-list-item v-if="isLogin && !isAdmin" to="/profile">
            Profile
          </v-list-item>

          <v-list-item v-if="!isLogin && hasInvitationCode" to="/auth/signup-millionaire-build">
            Signup
          </v-list-item>

          <v-list-item v-if="!isLogin && hasInvitationCode" to="/auth">
            Login
          </v-list-item>

          <v-list-item v-if="isLogin" @click="logoutUser">
            <a>Logout</a>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import moment from 'moment'

export default {
  name: 'Navigation',
  data() {
    return {
      drawer: false,
      group: null,
    }
  },
  computed: {
    user () { return this.$store.state.user },
    hasInvitationCode () { return this.user.invitationCode },
    isLogin () { return this.user.token && this.user.id },
    isAdmin () { return this.user.type == 1 },
    isVipExpired () {
      if (!this.user.vipExpiry) return true

      return moment().isBefore(moment(this.user.vipExpiry, 'YYYY-MM-DD').add('days', 1), 'day')
    }
  },
  methods: {
    goLogin () {
      this.$router.push('/auth')
    },
    isActive (x) { return this.$route.name === x },
    logoutUser () {
      localStorage.removeItem('apl_session_token')
      this.$store.commit('user/setSessionData', {
        token: '',
        invitationCode: ''
      })
      this.$store.commit('user/setUserData', {
        id: '',
        email: '',
        type: '',
        firstName: '',
        lastName: '',
        mobile: '',
        aplGoUser: '',
        aplGoSponsorId: '',
        instagram: '',
        facebook: '',
        profilePic: '',
        isVip: false,
        vipExpiry: '',
        mbid: '',
        sponsorMbid: '',
        busd_wallet : '',
      })
      this.$router.push('/')
    }
  },
  watch: {
    group () {
      this.drawer = false
    }
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
.web-mode{
  background: #333!important;
  .text-h4{
    color: #fff;
    font-weight: 600;
    font-family: 'Kodchasan', sans-serif!important;
  }
}

.logo{
  height: 54px;
  line-height: 1;
  margin-top: 6px;
}

.nav a{
  text-decoration: none;
  color: #fff !important;
  font-weight: 600;
  letter-spacing: 1px;
  &.active{
    color: #61b84f !important;
  }
}

.list-item{
  color: #1d2431!important;
  font-weight: 600;
  text-decoration: none;
}

.mobile-link{
  color: #1d2431 !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}

.sub-menu{
  a {
    font-weight: 400;
    font-size: 14px;
    color: #333!important;
    &.active{
      color: #267f14 !important;
      font-weight: 600!important;
    }
  }
}

.admin-links{
  background: #efefef;
  border: 1px solid #ddd;
  .sub-header{
    height: 30px;
  }
}
</style>