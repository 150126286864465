<template>
  <div class="px-4 text-center">
    <div class="page-title">My Core Team</div>
    <div class="sub-title mb-12">Tree chart representation</div>

    <!-- <pre style="text-align: initial; font-size: 13px;">{{binary.data}}</pre> -->

    <v-tabs v-model="tab" centered background-color="transparent" class="tree-tabs">
      <v-tab v-for="item in tabs" :key="item" @click="checkLoaded(item)">{{item}}</v-tab>
      <v-tab-item class="p-rel">
        <div class="zoom-box d-flex">
          <v-card elevation="2" width="50" class="px-2 pb-1 pt-2 mr-2" title="Zoom In" @click="zoom('binary', true)">
            <i class="fa-solid fa-magnifying-glass-plus"></i>
          </v-card>
          <v-card elevation="2" width="50" class="px-2 pb-1 pt-2 mr-2" title="Zoom Out" @click="zoom('binary', false)">
            <i class="fa-solid fa-magnifying-glass-minus"></i>
          </v-card>
          <v-card elevation="2" class="px-2 pb-1 pt-2 mr-2"  @click="showAll('binary')" :disabled="binary.allLoaded">
            <i class="fa-solid fa-eye fa-fw mr-2"></i>
            <span>Show All</span>
          </v-card>
          <v-card elevation="2" >
            <div class="px-2 pb-1 pt-2" @click="hideAll('binary')">
              <i class="fa-solid fa-eye-slash fa-fw mr-2"></i>
              <span>Hide All</span>
            </div>
          </v-card>
        </div>
        <vue-tree class="vue-tree" linkStyle="straight" collapse-enabled="false"
          :dataset="binary.data" :config="treeConfig" v-if="binary.rerender">
          <template v-slot:node="{node}">
            <div class="d-flex flex-column" :style="{zoom:binary.zoom}">
              <div class="tree-node d-flex justify-center align-center elevation-2"
                @click.stop @click="getBasicTree(node)"> <!-- getUserInfo(node) -->
                <v-avatar class="pic mr-2" size="80">
                  <v-img :src="node.img"></v-img>
                </v-avatar>
                <div class="desc text-left">
                  <div class="id">{{ node.id }}</div>
                  <div class="name lr-header">{{ node.name}}</div>
                </div>
                <i class="fa-solid fa-circle-plus add-member-icon elevation-3" @click.stop @click="showModal(node.id, 'create')"></i>
              </div>

              <div class="mt-3" :ref="`binary${node.userId}`">
                <i class="fa-solid fa-sort-down fa-2x blue--text" v-if="!node.checkChildren" @click="loadBinary(node)"></i>

                <i class="fa-solid fa-caret-down fa-2x" v-if="node.children && node.children.length"></i>
                <i class="fa-solid fa-caret-up fa-2x" v-if="node._children && node._children.length"></i>

                <i class="fa-solid fa-spinner fa-spin slow-spin fa-2x" v-if="node.loading"></i>
              </div>
            </div>
          </template>
        </vue-tree>
      </v-tab-item>
      <v-tab-item class="p-rel">
        <div class="zoom-box d-flex">
          <v-card elevation="2" width="50" class="px-2 pb-1 pt-2 mr-2" title="Zoom In" @click="zoom('classic', true)">
            <i class="fa-solid fa-magnifying-glass-plus"></i>
          </v-card>
          <v-card elevation="2" width="50" class="px-2 pb-1 pt-2 mr-2"  title="Zoom Out" @click="zoom('classic', false)">
            <i class="fa-solid fa-magnifying-glass-minus"></i>
          </v-card>
          <v-card elevation="2" class="px-2 pb-1 pt-2 mr-2"  @click="showAll('classic')" :disabled="classic.allLoaded">
            <i class="fa-solid fa-eye fa-fw mr-2"></i>
            <span>Show All</span>
          </v-card>
          <v-card elevation="2" >
            <div class="px-2 pb-1 pt-2" @click="hideAll('classic')">
              <i class="fa-solid fa-eye-slash fa-fw mr-2"></i>
              <span>Hide All</span>
            </div>
          </v-card>
        </div>
        <vue-tree class="vue-tree" linkStyle="straight" collapse-enabled="false"
          :dataset="classic.data" :config="treeConfig" v-if="classic.rerender">
          <template v-slot:node="{node}">
            <div class="d-flex flex-column" :style="{zoom:classic.zoom}">
              <div class="tree-node d-flex justify-center align-center elevation-2"
                @click.stop @click="getBasicTree(node)"> <!-- getUserInfo(node) -->
                <v-avatar class="pic mr-2" size="80">
                  <v-img :src="node.img"></v-img>
                </v-avatar>
                <div class="desc text-left">
                  <div class="id">{{ node.id }}</div>
                  <div class="name lr-header">{{ node.name}}</div>
                </div>
                <i class="fa-solid fa-circle-plus add-member-icon elevation-3" @click.stop @click="showModal(node.id, 'create')"></i>
              </div>

              <div class="mt-2" :ref="`classic${node.userId}`">
                <i class="fa-solid fa-sort-down fa-2x blue--text" v-if="!node.checkChildren" @click="loadClassic(node)"></i>

                <i class="fa-solid fa-caret-down fa-2x" v-if="node.children && node.children.length"></i>

                <i class="fa-solid fa-caret-up fa-2x" v-if="node._children && node._children.length"></i>

                <i class="fa-solid fa-spinner fa-spin slow-spin fa-2x" 
                  v-if="node.loading"
                ></i>
              </div>
            </div>
          </template>
        </vue-tree>
      </v-tab-item>
    </v-tabs>

    <v-bottom-sheet v-model="userInfo.show">
      <v-sheet class="text-center user-info-box">
        <v-btn class="mt-1 mb-5" text color="red" @click="userInfo.show = false">close</v-btn>
        <div class="py-3 d-flex justify-center">
          <v-avatar class="mr-4" size="150">
            <v-img :src="userInfo.data.img"></v-img>
          </v-avatar>
          <div class="text-left">
            <div class="apl-id lr-header">#{{userInfo.data.id}}</div>
            <div class="name lr-header">{{userInfo.data.name}}</div>
            <div class="email lr-title">{{userInfo.data.email}}</div>
            <div class="mobile">{{userInfo.data.mobile}}</div>
            <a :href="userInfo.data.fb" target="_blank" class="socials" v-if="userInfo.data.fb">
              <i class="fa-brands fa-square-facebook fa-2x"></i>
            </a>
            <a :href="userInfo.data.ig" target="_blank" class="mr-5 ml-2 socials" v-if="userInfo.data.ig">
              <i class="fa-brands fa-square-instagram fa-2x"></i>
            </a>
            <!-- <v-divider class="mb-3" /> -->
            <div class="sponsor text-center mt-3 elevation-1">
              <span class="lr-title">SPONSOR</span>
              <div class="pa-3 text-left">
                <div class="apl-id lr-header">#{{userInfo.data.sponsorId}}</div>
                <div class="name lr-header">{{userInfo.data.sponsorName}}</div>
              </div>
            </div>
          </div>
        </div>
      </v-sheet>
    </v-bottom-sheet>

    <!-- CREATE POST MODAL -->
    <v-dialog v-model="memberModal.show" persistent max-width="500">
      <v-card class="pt-3">
        <v-card-title class="text-h5 mb-2">
          <span v-if="memberModal.type === 'create'">Add Member</span>
          <span v-else>Update Member</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="col-12">
              <div class="lr-header">Temporary Name</div>
              <v-text-field outlined hide-details="auto" dense
                v-model="memberModal.name"></v-text-field>
            </v-col>
            <v-col class="col-12">
              <div class="lr-header">APL Go ID</div>
              <v-text-field outlined hide-details="auto" dense
                v-model="memberModal.aplGoId"></v-text-field>
            </v-col>
            <v-col class="col-12">
              <div class="lr-header">Sponsor ID</div>
              <v-text-field outlined hide-details="auto" dense :disabled="memberModal.type == 'edit'"
                v-model="memberModal.sponsorId"></v-text-field>
            </v-col>
            <v-col class="col-12" v-if="memberModal.type == 'edit'">
              <div class="lr-header">Left Child ID</div>
              <v-text-field outlined hide-details="auto" dense
                v-model="memberModal.leftId"></v-text-field>
            </v-col>
            <v-col class="col-12" v-if="memberModal.type == 'edit'">
              <div class="lr-header">Right Child ID</div>
              <v-text-field outlined hide-details="auto" dense
                v-model="memberModal.rightId"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="mt-3" />
        <v-card-actions>
          <v-btn color="grey darken-1" text @click="memberModal.show = false">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="processMember">
            <span v-if="memberModal.type === 'create'">Add Member</span>
            <span v-else>Update</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueTree from "@ssthouse/vue-tree-chart"

export default {
  name: 'Home',
  components: { VueTree },
  data () {
    return {
      tab: null,
      tabs: ['Binary Tree', 'Classic Tree'],
      binary: {
        data: {},
        original: {},
        rerender: true,
        zoom: 1,
        allLoaded: false,
        firstLoad: false
      },
      classic: {
        data: {},
        original: {},
        rerender: true,
        loaded: false,
        zoom: 1,
        allLoaded: false,
        firstLoad: false
      },
      tree: {},
      treeConfig: { nodeWidth: 320, nodeHeight: 180, levelHeight: 220 },
      rerender: true,
      defaultPic: 'https://waysideschools.org/wp-content/uploads/2015/07/default-profile-pic.png',
      userInfo: {
        show: false,
        data: {}
      },
      memberModal: {
        show: false,
        type: 'create',
        name: '',
        aplGoId: '',
        sponsorId: '',
        leftId: '',
        rightId: ''
      }
    }
  },
  computed: {
    user () { return this.$store.state.user },
  },
  methods: {
    getAplGoId () {
      let self = this

      if (self.user.aplGoSponsorId) {
        return
      }

      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'getUserInfo',
        token: self.user.token
      }, res => {
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {loading: false})
          self.$store.commit('global/setGlobalData', {
            loading: false,
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        self.$store.commit('user/setUserData', {
          id: res.data.id,
          aplGoUser: res.data.apl_go_user
        })
        self.getTreeData(true)
      })
    },
    getTreeData (isBinary = true, node = false, callback) {
      let self = this
      if (node && (
        (node.children && node.children.length) ||
        (node._children && node._children.length)
      )) { return }

      let id = self.user.aplGoUser
      if (node) id = node.id
      let type = isBinary ? 'getBinaryTree' : 'getClassicTree'

      self.$sdk.post('', {
        requestType: type,
        token: self.user.token,
        aplgo_id: id
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            loading: false,
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        let main = res.data.main
        if (isBinary) {
          let left = res.data.left
          let right = res.data.right

          if (node) {
            callback(res)
            return
          } else {
            self.binary.data = self.formatTreeNode(main)
            self.binary.data.checkChildren = true
            self.binary.data.children = [
              self.formatTreeNode(left),
              self.formatTreeNode(right)
            ]
          }

          if (!self.binary.firstLoad) {
            self.binary.firstLoad = true
            self.binary.original = self.binary.data
          }

          self.binary.rerender = false
          self.$nextTick(() => {
            self.binary.rerender = true
          })
        } else {
          let temp = self.formatTreeNode(main)
          temp.checkChildren = true
          let children = []
          res.data.children.forEach(child => {
            children.push(self.formatTreeNode(child))
          })
          temp.children = children

          if (node) {
            node.children = children
            node.loading = false
          } else {
            self.classic.data = temp
            self.classic.loaded = true
          }

          if (!self.classic.firstLoad) {
            self.classic.firstLoad = true
            self.classic.original = self.classic.data
          }

          self.classic.rerender = false
          self.$nextTick(() => {
            self.classic.rerender = true
          })
        }
      })
    },
    formatTreeNode (data) {
      return {
        id: data.aplgo_id,
        userId: data.users_id,
        sponsorId: data.sponsor_id,
        sponsorName: data.sponsor_name,
        name: data.full_name || 'John Doe',
        img: data.profile_pic,
        checkChildren: false,
        email: data.email,
        mobile: data.contact_number,
        fb: data.facebook_url,
        ig: data.instagram_url,
        loading: false,
      }
    },
    loadBinary (node) {
      let self = this
      if (node.loading) return
      node.checkChildren = true
      node.loading = true
      self.getTreeData(true, node, res => {
        let left = res.data.left
        let right = res.data.right
        node.children = [
          self.formatTreeNode(left),
          self.formatTreeNode(right)
        ]
        node.loading = false
        self.binary.rerender = false
        self.$nextTick(() => {
          self.binary.rerender = true
        })
      })
    },
    loadClassic (node) {
      let self = this
      if (node.loading) return
      node.checkChildren = true
      node.loading = true
      self.getTreeData(false, node)
    },
    getUserInfo (node) {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})

      setTimeout(() => {
        self.$store.commit('global/setGlobalData', {loading: false})
        self.userInfo = {
          show: true,
          data: node
        }
      }, 500)
    },
    checkLoaded (type) {
      if (type != 'Classic Tree' || this.classic.loaded) return
      this.$store.commit('global/setGlobalData', {loading: true})
      this.getTreeData(false)
    },
    zoom (tree, increase) {
      let self = this
      if (increase && self[tree].zoom <= 1.5) {
        self[tree].zoom += .1
        self.treeConfig.nodeWidth += 10
        self.treeConfig.nodeHeight += 10
        self.treeConfig.levelHeight += 15
        self[tree].rerender = false
        self.$nextTick(() => {
          self[tree].rerender = true
        })
        return
      }

      if (self[tree].zoom <= .5) return
      self[tree].zoom -= .1
      self.treeConfig.nodeWidth -= tree == 'binary' ? 10 : 20
      self.treeConfig.nodeHeight -= 10
      self.treeConfig.levelHeight -= 15
      self[tree].rerender = false
      self.$nextTick(() => {
        self[tree].rerender = true
      })
    },
    showAll (tree) {
      let self = this
      if (self[tree].allLoaded) {
        return
      }

      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        token: self.user.token,
        requestType: 'getFullTree',
        type_of_tree: tree,
        aplgo_id: self.user.aplGoUser
      }, res => {
        // console.log('@@~', res)
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            loading: false,
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }
        let data = res.data.main
        if (tree == 'binary') data.tree = res.data.tree
        else data.children = res.data.children.child
        // console.log('~~', self.largeObjectFormatter(data, tree))
        self[tree].rerender = false
        self[tree].data = self.largeObjectFormatter(data, tree)
        self.$nextTick(() => {
          self[tree].rerender = true
        })
        self[tree].allLoaded = true
        self.$store.commit('global/setGlobalData', {loading: false})
      })
    },
    largeObjectFormatter (x, tree) {
      const treeFormat = data => ({
        ...data,
        id: data.aplgo_id,
        userId: data.users_id,
        sponsorId: data.sponsor_id,
        sponsorName: data.sponsor_name,
        name: data.full_name || 'John Doe',
        img: data.profile_pic,
        checkChildren: true,
        email: data.email,
        mobile: data.contact_number,
        fb: data.facebook_url,
        ig: data.instagram_url,
        loading: false,
        ...Object.entries(data)
          .filter(([k, v]) => typeof v === 'object') // eslint-disable-line
          .reduce((c, [k, v]) => ({ // eslint-disable-line
            children: v ? [v.left ? treeFormat(v.left) : '',  v.right ? treeFormat(v.right) : ''] : v
          }), {})
      })

      const removeTree = x => Object.entries(x)
        .filter(([k, v]) => k !== 'tree') // eslint-disable-line
        .reduce((c, [k, v]) => ({ ...c, [k]: typeof v === 'object' ? (v ? removeTree(v) : v) : v }), {});

      const fixChildren = x => {
        x.children = Object.fromEntries(Object.entries(x.children).filter(([key, val]) => val !== '')) // eslint-disable-line
        if (!Object.entries(x.children).filter(([k, v]) => v != '').length) { // eslint-disable-line
          delete x.children
        }
        return {
          ...x,
          ...Object.entries(x)
            .filter(([k, v]) => k === 'children' && typeof v === 'object')
            .reduce((c, [, v]) => ({
              children: [
                ...Object.values(v).filter(h => typeof h !== 'object'),
                ...Object.values(v).filter(h => typeof h === 'object').map(h => fixChildren(h))
              ]
            }), {}),
        }
      };

      const treeFormat2 = data => {
        if (data.children.child) {
          data.children = data.children.child
        }
        return {
          ...data,
          id: data.aplgo_id,
          userId: data.users_id,
          sponsorId: data.sponsor_id,
          sponsorName: data.sponsor_name,
          name: data.full_name || 'John Doe',
          img: data.profile_pic,
          checkChildren: true,
          email: data.email,
          mobile: data.contact_number,
          fb: data.facebook_url,
          ig: data.instagram_url,
          loading: false,
          ...Object.entries(data)
            .filter(([k, v]) => typeof v === 'object' && v != null) // eslint-disable-line
            .reduce((c, [k, v]) => { // eslint-disable-line
              let children = []
              v.forEach(child => {
                children.push(treeFormat2(child))
              })
              return { children: v ? children : v }
            }, {})
        }
      }

      let temp = ''
      if (tree == 'binary') {
        temp = treeFormat(x)
        temp = removeTree(temp)
        temp = fixChildren(temp)
      } else {
        temp = treeFormat2(x)
      }

      return temp
    },
    hideAll (tree) {
      this[tree].rerender = false
      this[tree].data = this[tree].original
      this.$nextTick(() => {
        this[tree].rerender = true
      })
      this[tree].allLoaded = false
      setTimeout(() => {
        if (this.$refs[`${tree}${this.user.id}`]) {
          this.$refs[`${tree}${this.user.id}`].click()
        }
      }, 200)
    },
    showModal (id, type) {
      this.memberModal = {
        show: true,
        type: type,
        name: '',
        aplGoId: '',
        sponsorId: id,
        leftId: '',
        rightId: ''
      }
    },
    processMember () {
      let self = this

      self.$store.commit('global/setGlobalData', {loading: true})
      let params = {
        requestType: 'createMLMRecord',
        token: self.user.token,
        temp_name: self.memberModal.name,
        aplgo_id: self.memberModal.aplGoId,
        sponsor_id: self.memberModal.sponsorId,
      }
      if (self.memberModal.type == 'edit') {
        params.requestType = 'updateMLMRecord'
        params.left_id = self.memberModal.leftId || ''
        params.right_id = self.memberModal.rightId || ''
      }
      self.$sdk.post('', params, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        self.memberModal.show = false
        self.$store.commit('global/setGlobalData', {
          response: {
            show: true,
            success: true,
            title: 'Successful!',
            message: self.memberModal.type == 'create' ? 'New member created.' : 'Member info updated.'
          }
        })
      })
    },
    getBasicTree (node) {
      let self = this

      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'getBinaryTree',
        token: self.user.token,
        aplgo_id: node.id
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        let main = res.data.main
        self.memberModal = {
          show: true,
          type: 'edit',
          name: main.temp_name,
          aplGoId: main.aplgo_id,
          sponsorId: main.sponsor_id,
          leftId: main.left_id,
          rightId: main.right_id
        }
      })
    }
  },
  mounted () {
    this.getAplGoId()
  }
}
</script>

<style lang="scss" scoped>
.p-rel{
  position: relative;
}
.zoom-box{
  position: absolute;
  z-index: 2;
  top: 20px;
  left: 20px;

  div{
    cursor: pointer;
  }
  i{
    font-size: 21px;
    color:#333;
    line-height: 1;
  }
  span{
    font-size: 15px;
    vertical-align: 2px;
    line-height: 1;
  }
}
.vue-tree{
  width: 100%;
  height: calc(100vh - 180px);
  border: 1px solid #ccc;
  background: #fafafa url('/grid.png');
  cursor: grab;
  position: relative;
  z-index: 1;
}

.tree-node{
  border: 1px solid #eee;
  padding: 15px;
  text-align: center;
  background: #fff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;

  .desc{
    line-height: 1;
  }
  .name{
    font-weight: 600;
    color: #1d2431;
    max-width: 190px;
    font-size: 15px;
  }
  .id{
    color: #777;
    margin-bottom: 2px;
    font-size: 14px;
  }
  .add-member-icon{
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    font-size:24px;
    color: #c14343;
    background: #fff;
    border-radius: 100%;
  }

  &:hover{
    background: #353535;
    .name, .id{
      color: #fff;
    }
  }
}
.fa-sort-down{
  position: relative;
  top: -5px;
}

.user-info-box{
  *{
    line-height: 1.4;
  }
  .name{
    font-weight: 600;
    color: #1d2431;
    font-size: 21px;
  }
  .apl-id{
    font-size: 15px;
    color: #fff;
    background: #333;
    border-radius: 3px;
    display: inline-block;
    padding: 2px 5px;
    margin-bottom: 2px;
  }
  .email{
    color: #555;
    font-size: 14px;
  }
  .mobile{
    letter-spacing: .5px;
    font-size: 14px;
    color: #666;
  }
}
.socials{
  color: #1d2431!important;
}
.sponsor{
  border: 1px solid #555;
  border-radius: 5px;
  background: #f5f5f5;

  span{
    display: block;
    color: #fff;
    background: #425365;
    padding: 3px 0;
    font-weight: 600;
    border-radius: 5px 5px 0 0;
  }
}
</style>