<template>
  <div class="px-4">
    <div class="page-title mb-12">Agent Details</div>

    <div class="mb-2">
      <a @click="back" class="lr-header back">
        <i class="fa-solid fa-arrow-left fa-fw mr-2"></i>
        <b>Back to agent list</b>
      </a>
    </div>

     <v-row>
        <v-col class="col-12 col-md-7">
          <v-card class="details-box px-0 pt-0 pb-4 pa-md-4" color="#1d2431" dark>
            <div class="d-flex flex-column flex-md-row">
              <v-avatar class="ma-4 d-none d-md-block" size="150" tile>
                <v-img :src="agent.profilePic"></v-img>
              </v-avatar>
              <v-img :src="agent.profilePic" class="d-md-none mobile-pic" max-height="280" contain></v-img>

              <div class="text-center text-md-start">
                <v-card-title class="text-h5 mb-1 warning--text text-center text-md-start d-block">{{ agent.firstName }} {{ agent.lastName}}</v-card-title>

                <v-card-subtitle>
                  <span class="email">
                    <i class="fa-solid fa-at fa-fw mr-1"></i>
                    {{ agent.email }}
                  </span>
                  <br/>
                  <span class="mobile">
                    <i class="fa-solid fa-mobile-screen fa-fw mr-1"></i>
                    {{ agent.mobile }}
                  </span>
                </v-card-subtitle>

                <div class="px-4">
                  <div class="field mb-4" v-if="desc">
                    <label class="grey--text caption">Agent Description:</label>
                    <pre class="mt-1 lr-header value"><b>{{desc}}</b></pre>
                  </div>
                  <div class="field mb-4" v-if="agent.aplGoUser">
                    <label class="grey--text caption">APL Go Member ID</label>
                    <div class="value">
                      <a :href="`https://aplgo.com/j/${agent.aplGoUser}/`" target="_blank">
                        {{agent.aplGoUser}}
                      </a>
                    </div>
                  </div>
                  <div class="field mb-2" v-if="agent.aplGoSponsorId">
                    <label class="grey--text caption">APLGO Sponsor ID</label>
                    <div class="value">{{agent.aplGoSponsorId}}</div>
                  </div>
                </div>

                <v-card-actions class="justify-center justify-md-start no-bg">
                  <a :href="agent.instagram" target="_blank" class="mr-5 ml-2 socials" v-if="agent.instagram">
                    <i class="fa-brands fa-square-instagram fa-3x"></i>
                  </a>
                  <a :href="agent.facebook" target="_blank" class="socials" v-if="agent.facebook">
                    <i class="fa-brands fa-square-facebook fa-3x"></i>
                  </a>
                </v-card-actions>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col class="col-12 col-md-5">
          <v-card class="details-box pa-4" color="#1d2431" dark>
            <div class="text-center mb-5 lr-header _title">
              AVAILABILITY
            </div>

            <v-row>
              <v-col class="py-1">
                <v-checkbox label="Monday" color="orange" checked="1" hide-details class="mt-0" readonly
                  v-model="monday"></v-checkbox>
              </v-col>
              <v-col class="py-1">
                <v-checkbox label="Tuesday" color="orange" value="1" hide-details class="mt-0" readonly
                  v-model="tuesday"></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-1">
                <v-checkbox label="Wednesday" color="orange" value="1" hide-details class="mt-0" readonly
                  v-model="wednesday"></v-checkbox>
              </v-col>
              <v-col class="py-1">
                <v-checkbox label="Thursday" color="orange" value="1" hide-details class="mt-0" readonly
                  v-model="thursday"></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-1">
                <v-checkbox label="Friday" color="orange" value="1" hide-details class="mt-0" readonly
                  v-model="friday"></v-checkbox>
              </v-col>
              <v-col class="py-1">
                <v-checkbox label="Saturday" color="orange" value="1" hide-details class="mt-0" readonly
                  v-model="saturday"></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-1">
                <v-checkbox label="Sunday" color="orange" value="1" hide-details class="mt-0" readonly
                  v-model="sunday"></v-checkbox>
              </v-col>
            </v-row>

            <div class="mt-7 mb-4">
              <b> From {{from}} - {{to}}</b>
            </div>

            <div class="mt-3 mb-4 notes-box" v-if="note">
              <label class="lr-header">Agent Notes:</label>
              <pre class="mt-1 lr-text">{{note}}</pre>
            </div>


          </v-card>
        </v-col>
     </v-row>

  </div>
</template>

<script>
export default {
  name: 'AgentDetails',
  data() {
    return {
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      from: '',
      to: '',
      note: '',
      desc: ''
    }
  },
  computed: {
    id () { return this.$route.params.id },
    user () { return this.$store.state.user },
    agent () { return this.$store.state.agents.agentInfo }
  },
  methods: {
    back () {
      this.$router.push('/agents')
    },
    getUserInfo () {
      let self = this

      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'getUserInfo',
        token: self.user.token,
        user_id: self.id
      }, res => {
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            loading: false,
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          self.$router.push('/agents')
          return
        }

        self.$store.commit('agents/setAgentData', {
          id: res.data.id,
          email: res.data.email,
          type: res.data.user_type,
          firstName: res.data.first_name,
          lastName: res.data.last_name,
          mobile: res.data.contact_number,
          aplGoUser: res.data.apl_go_user,
          aplGoSponsorId: res.data.apl_go_sponsor_id,
          instagram: res.data.instagram_url,
          facebook: res.data.facebook_url,
          profilePic: res.data.profile_pic,
        })

        self.getAgentInfo()
      })
    },
    getAgentInfo () {
      let self = this

      self.$sdk.post('', {
        requestType: 'getAgentInfo',
        token: self.user.token,
        user_id: self.id
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        self.monday = res.data.is_available_monday
        self.tuesday = res.data.is_available_tuesday
        self.wednesday = res.data.is_available_wednesday
        self.thursday = res.data.is_available_thursday
        self.friday = res.data.is_available_friday
        self.saturday = res.data.is_available_saturday
        self.sunday = res.data.is_available_sunday
        self.from = res.data.availability_from
        self.to = res.data.availability_to
        self.note = res.data.availability_note
        self.desc = res.data.agent_description
      })
    }
  },
  watch: {
    id () {
      this.getUserInfo()
    }
  },
  mounted () {
    this.getUserInfo()

    if (!this.user.isVip) {
      this.$store.commit('global/setGlobalData', {
        response: {
          show: true,
          success: false,
          title: 'Premium Required',
          message: 'Be a Premium VIP to unlock this Premium Feature.'
        }
      })
      this.$router.push('/premium')
      return
    }
  }
}
</script>

<style lang="scss" scoped>
.details-box{
  color: #fff;
}

.mobile-pic{
  background: #393f49;
  border-radius: 5px 5px 0 0;
  // blue-grey darken-4
}

.field{
  .value{
    line-height: 1;
    display: block;
  }
  div{
    font-weight: 800;
    // color: #1d2431;

    a{
      font-weight: 600;
    }
  }
}

.text-h5{
  font-weight: 600;
}
.socials{
  color: #fff!important;
}

._title{
  font-weight: 600;
  font-size: 28px;
}

.notes-box{
  label{
    font-size: 21px;
  }
  div{
    color: #eee;
  }
}

pre{
  white-space: break-spaces;
  line-height: 1.3!important;
}
</style>