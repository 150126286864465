<template>
  <div class="main d-flex justify-center align-center flex-column px-4 px-md-0">
    <div class="text-left box mb-2" v-if="!user.token && !user.invitationCode">
      <a @click="back" class="lr-text back">
        <i class="fa-solid fa-arrow-left fa-fw"></i> BACK
      </a>
    </div>
    <v-card elevation="4" outlined color="#1d2431" class="px-7 py-5 text-center box mb-5" dark>
      <div class="text-center mb-10 lr-header _title">
        LOGIN
      </div>
      <div class="lr-title">EMAIL</div>
      <v-text-field filled outlined class="mb-4" hide-details="auto" dense
        v-model="email" v-on:keyup.enter="login"></v-text-field>
      <div class="lr-title">PASSWORD</div>
      <v-text-field filled outlined hide-details="auto" dense type="password"
        v-model="password" v-on:keyup.enter="login"></v-text-field>
      <v-btn color="#2d8c40" block class="mt-6" large @click="login">LOGIN</v-btn>
    </v-card>

    <div class="reset-box lr-header">
      Forgot your password?
      <a href="#/auth/reset" class="lr-text">CLICK HERE</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      email: '',
      password: ''
    }
  },
  computed: {
    user () { return this.$store.state.user }
  },
  methods: {
    back () {
      this.$router.push('/')
    },
    login () {
      let self = this

      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'authenticate',
        email: self.email,
        password: self.password
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        localStorage.setItem('apl_session_token', res.data.token)
        self.$store.commit('user/setSessionData', {token: res.data.token})
        self.$store.commit('user/setUserData', {
          id: res.data.id,
          email: res.data.email,
          type: res.data.user_type,
        })
        if (res.data.user_type == 1) self.$router.push('/admin/users')
        else self.getUserInfo()
      })
    },
    getUserInfo () {
      let self = this

      self.$sdk.post('', {
        requestType: 'getUserInfo',
        token: self.user.token
      }, res => {
        if (res.status != 'failed') {
          let data = res.data
          self.$store.commit('user/setUserData', {
            firstName: data.first_name,
            lastName: data.last_name,
            mobile: data.contact_number,
            aplGoUser: data.apl_go_user,
            aplGoSponsorId: data.apl_go_sponsor_id,
            instagram: data.instagram_url,
            facebook: data.facebook_url,
            profilePic: data.profile_pic,
            isVip: data.is_vip == 1,
            vipExpiry: data.vip_expiry,
            mbid: data.mbid,
            sponsorMbid: data.sponsor_mbid,
            sponsorId: data.sponsor_id,
            busdWallet: data.busd_address,
          })
        }
        self.$router.push('/profile')
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.main{
  width: 100%;
  height: 100%;
}
.box{
  max-width: 400px;
  width: 100%;
}
.back{
  font-weight: 600;
}
._title{
  font-weight: 600;
  font-size: 36px;
}
.reset-box{
  color: #777;

  a{
    font-weight: 600;
    text-decoration: underline;
  }
}
</style>