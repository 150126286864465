<template>
  <div>
    <v-dialog v-model="data.show" persistent max-width="500">
      <v-card class="pt-3">
        <v-card-title class="title">Confirm Delete Post</v-card-title>
        <v-card-text class="text mb-4 mt-1">
          Are you sure you want to delete this post 
          <b class="lr-header">{{data.title}}</b>?
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn color="grey darken-1" text @click="$emit('hide')">
            No, I change my mind
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="deletePost">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'DeletePostModal',
  props: ['data'],
  data () {
    return {}
  },
  computed: {
    user () { return this.$store.state.user },
  },
  methods: {
    deletePost () {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'deleteTraining',
        token: self.user.token,
        training_id: self.data.id,
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        self.$emit('hide')
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }
        
        self.$store.commit('global/setGlobalData', {
          response: {
            show: true,
            success: true,
            title: 'Success!',
            message: 'Post deleted.'
          }
        })

        if (self.$route.name !== 'AdminPosts') {
          self.$router.push('/admin/posts')
        } else {
          self.$emit('success')
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.title{
  color: #1d2431;
  font-size: 28px!important;
}
.text{
  font-size: 18px;
}
</style>
