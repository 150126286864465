<template>
  <div class="main d-flex justify-center align-center px-4 px-md-0">
    <v-card elevation="4" outlined color="#1d2431" class="form-box px-7 py-5 mb-5" dark>
      <div class="text-center mb-10 lr-header _title">
        <!-- <img src="@/assets/img/logo.png" class="mb-4" width="200" /> -->
        SIGNUP
      </div>
      <v-row>
        <v-col>
          <div class="lr-title">Sponsor ID</div>
          <v-text-field filled outlined hide-details="auto" dense type="email"
            v-model="sponsorId" :error-messages="error.sponsorId" :disabled="sponsor.loading"
            @change="validateSponsor" :loading="sponsor.loading"
            :success-messages="sponsor.success"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="lr-title">Email</div>
          <v-text-field filled outlined hide-details="auto" dense type="email"
            v-model="email" :error-messages="error.email"
            @change="fieldChange('email')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-12 col-md-6">
          <div class="lr-title">First Name</div>
          <v-text-field filled outlined hide-details="auto" dense
            v-model="firstName" :error-messages="error.firstName"
            @change="fieldChange('firstName')"
          ></v-text-field>
        </v-col>
        <v-col class="col-12 col-md-6">
          <div class="lr-title">Last Name</div>
          <v-text-field filled outlined hide-details="auto" dense
            v-model="lastName" :error-messages="error.lastName"
            @change="fieldChange('lastName')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-12 col-md-6">
          <div class="lr-title">Password</div>
          <v-text-field filled outlined hide-details="auto" dense type="password"
            v-model="password" :error-messages="error.password"
            hint="At least 8 characters" @change="fieldChange('password')"
          ></v-text-field>
        </v-col>
        <v-col class="col-12 col-md-6">
          <div class="lr-title">Repeat Password</div>
          <v-text-field filled outlined hide-details="auto" dense type="password"
            v-model="rpassword" :error-messages="error.rpassword"
            hint="At least 8 characters" @change="fieldChange('rpassword')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-btn color="#2d8c40" block class="mt-8" large @click="submit" :disabled="sponsor.loading">Sumbit</v-btn>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      sponsorId: '',
      sponsor: {
        loading: false,
        success: ''
      },
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      rpassword: '',
      error: {
        sponsorId: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        rpassword: ''
      }
    }
  },
  computed: {
    user () { return this.$store.state.user }
  },
  methods: {
    validateSponsor () {
      let self = this
      self.fieldChange('sponsorId')
      self.sponsor.success = ''
      if (!self.sponsorId && self.sponsor.loading) return

      self.sponsor.loading = true
      self.$sdk.post('', {
        requestType: 'validateSponsorID',
        sponsor_id: self.sponsorId
      }, res => {
        self.sponsor.loading = false
        if (res.status === 'failed') {
          self.error.sponsorId = res.message[0]
          return
        }
        self.sponsor.success = 'Valid Sponsor ID.'
      })
    },
    fieldChange (field) {
      if (this.error[field] && this[field]) {
        this.error[field] = ''
      }
    },
    submit () {
      let self = this
      let fields = ['firstName', 'lastName', 'email', 'password', 'rpassword', 'sponsorId']
      let hasError = false

      fields.forEach(field => {
        self.error[field] = ''
        if (!self[field]) {
          hasError = true
          self.error[field] = 'This field is required.'
        }
      })
      let pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      if (!pattern.test(self.email)) {
        hasError = true
        self.error.email = 'Invalid email.'
      }

      if (self.password.length < 8) {
        hasError = true
        self.error.password = 'Password must be atleast 8 characters.'
      }
      if (self.rpassword.length < 8) {
        hasError = true
        self.error.rpassword = 'Repeat password must be atleast 8 characters.'
      }

      if (self.password && self.rpassword && self.password !== self.rpassword) {
        hasError = true
        self.error.password = 'Password and repeat password doesn\'t match.'
        self.error.rpassword = ' '
      }

      if (self.sponsorId && !self.sponsor.success) {
        hasError = true
      }

      if (hasError) return

      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'signup',
        first_name: self.firstName,
        last_name: self.lastName,
        email: self.email,
        password: self.password,
        password_repeat: self.rpassword,
        invitation_code: self.user.invitationCode,
        sponsor_id: self.sponsorId,
      }, res => {
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            loading: false,
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        self.login()
      })
    },
    login () {
      let self = this
      self.$sdk.post('', {
        requestType: 'authenticate',
        email: self.email,
        password: self.password
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        localStorage.setItem('apl_session_token', res.data.token)
        self.$store.commit('user/setSessionData', {token: res.data.token})
        self.$store.commit('user/setUserData', {
          id: res.data.id,
          email: res.data.email,
          type: res.data.user_type,
        })
        self.$router.push('/profile')
      })
    }
  },
  mounted () {
    // Kick to landing page if no invitation code
    // if (!this.user.invitationCode) {
    //   this.$router.push('/')
    // } else {
    //   // this.sponsorId = this.user.invitationCode
    // }
    this.$router.push('/auth/signup-millionaire-build')
  }
}
</script>

<style lang="scss" scoped>
.main{
  width: 100%;
  height: 100%;
}
.form-box{
  width: 100%;
  max-width: 600px;
}
._title{
  font-weight: 600;
  font-size: 36px;
}
.login-box{
  color: #777;

  a{
    font-weight: 600;
    text-decoration: underline;
  }
}
</style>