<template>
  <div class="main d-flex justify-center align-center flex-column px-4 px-md-0">
    <div class="text-left box mb-2" v-if="!user.token && !user.invitationCode">
      <a @click="back" class="lr-text back">
        <i class="fa-solid fa-arrow-left fa-fw"></i> BACK
      </a>
    </div>
    <v-card elevation="4" outlined color="#1d2431" class="px-7 py-5 text-center box mb-5" dark>
      <div class="lr-header _title">
        FORGOT PASSWORD?
      </div>
      <div class="lr-header desc mb-8">
        Enter the email associated with your account and we'll send an email with 
        instruction to reset password.
      </div>
      <div class="lr-title">EMAIL</div>
      <v-text-field filled outlined class="mb-4" hide-details="auto" dense
        v-model="email"></v-text-field>
      <v-btn color="#2d8c40" block class="mt-6" large @click="sendReset">SEND RESET PASSWORD</v-btn>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      email: '',
    }
  },
  computed: {
    user () { return this.$store.state.user }
  },
  methods: {
    back () {
      this.$router.push('/auth')
    },
    sendReset () {
      let self = this

      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'sendResetPassword',
        email: self.email
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        self.$store.commit('global/setGlobalData', {
          response: {
            show: true,
            success: true,
            title: 'Email has been sent!',
            message: 'Please check your inbox and click in the received link to reset the password.'
          }
        })
        self.$router.push('/auth')
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.main{
  width: 100%;
  height: 100%;
}
.box{
  max-width: 500px;
  width: 100%;
}
.back{
  font-weight: 600;
}
._title{
  font-weight: 600;
  font-size: 36px;
}
.desc{
  color: #d9dde5;
}
.login-box{
  color: #777;

  a{
    font-weight: 600;
    text-decoration: underline;
  }
}
</style>