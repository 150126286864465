<template>
  <div>
    <div class="page-title">USER PROFILE</div>
    <div class="sub-title mb-12">Update user information.</div>

    <div class="d-flex justify-space-between mb-5 align-center">
      <a href="#/admin/users" class="lr-header back">
        <i class="fa-solid fa-arrow-left fa-fw mr-2"></i>
        <b>Back to Users List</b>
      </a>

      <div>
        <!-- <v-btn color="primary" class="mr-2" dark @click="resetPassModal = true">
          <i class="fa-solid fa-lock fa-fw mr-2"></i> Send Reset Password
        </v-btn> -->
        <v-btn color="error" dark @click="deleteModal = true">
          <i class="fa-solid fa-trash fa-fw mr-2"></i> Delete User
        </v-btn>
      </div>
    </div>

    <v-card elevation="2" class="mb-10">
      <v-container class="px-5 py-8">
        <v-alert color="#2A3B4D" dark dense class="text-h6">User Information</v-alert>
        <v-row>
          <v-col class="col-12 col-md-12">
            <div class="lr-header">Email</div>
            <v-text-field outlined hide-details="auto" dense type="email"
              v-model="userInfo.email"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-12 col-md-6">
            <div class="lr-header">First Name</div>
            <v-text-field outlined hide-details="auto" dense
              v-model="userInfo.firstName" :error-messages="error.firstName"
              @change="fieldChange('firstName')"
            ></v-text-field>
          </v-col>
          <v-col class="col-12 col-md-6">
            <div class="lr-header">Last Name</div>
            <v-text-field outlined hide-details="auto" dense
              v-model="userInfo.lastName" :error-messages="error.lastName"
              @change="fieldChange('lastName')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-12 col-md-12">
            <div class="lr-header">Contact Number</div>
            <v-text-field outlined hide-details="auto" dense
              v-model="userInfo.mobile" :error-messages="error.mobile"
              @change="fieldChange('mobile')"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col class="col-12 col-md-12">
            <div class="lr-header">APL GO User</div>
            <v-text-field outlined hide-details="auto" dense
              v-model="userInfo.aplGoUser" :error-messages="error.aplGoUser"
              @change="fieldChange('aplGoUser')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-12 col-md-12">
            <div class="lr-header">APL GO Sponsor ID</div>
            <v-text-field outlined hide-details="auto" dense
              v-model="userInfo.aplGoSponsorId" :error-messages="error.aplGoSponsorId"
              @change="fieldChange('aplGoSponsorId')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-12 col-md-6">
            <div class="lr-header">Left ID</div>
            <v-text-field outlined hide-details="auto" dense
              v-model="userInfo.leftId" :error-messages="error.leftId"
              @change="fieldChange('leftId')"
            ></v-text-field>
          </v-col>
          <v-col class="col-12 col-md-6">
            <div class="lr-header">Right ID</div>
            <v-text-field outlined hide-details="auto" dense
              v-model="userInfo.rightId" :error-messages="error.rightId"
              @change="fieldChange('rightId')"
            ></v-text-field>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col class="col-12 col-md-12">
            <div class="lr-header">Instagram URL</div>
            <v-text-field outlined hide-details="auto" dense
              v-model="userInfo.instagram" :error-messages="error.instagram"
              @change="fieldChange('instagram')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-12 col-md-12">
            <div class="lr-header">Facebook URL</div>
            <v-text-field outlined hide-details="auto" dense
              v-model="userInfo.facebook" :error-messages="error.facebook"
              @change="fieldChange('facebook')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-12 col-md-12">
            <div class="lr-header">Millionaire Build ID (MBID)</div>
            <v-text-field outlined hide-details="auto" dense
              v-model="userInfo.mbid" :error-messages="error.mbid"
              @change="fieldChange('mbid')"
            ></v-text-field>
          </v-col>
          <v-col class="col-12 col-md-12">
            <div class="lr-header">Sponsor MBID</div>
            <v-text-field outlined hide-details="auto" dense
              v-model="userInfo.sponsorMbid" :error-messages="error.sponsorMbid"
              @change="fieldChange('sponsorMbid')"
            ></v-text-field>
          </v-col>
          <v-col class="col-12 col-md-12">
            <div class="lr-header">BUSD Wallet Address</div>
            <small>
              Make sure the address is correct. Incorrect address may result in asset/token lost and is irreversible. Leaving this blank will also result in commission loss.
            </small>
            <v-text-field outlined hide-details="auto" dense
              v-model="userInfo.busdWallet" :error-messages="error.busdWallet"
              @change="fieldChange('busdWallet')"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <v-divider/>

      <v-container class="px-5 py-8">
        <v-alert color="#2A3B4D" dark dense class="text-h6">
          User Type
        </v-alert>
        <v-row>
          <v-col class="col-12 col-md-12">
            <v-radio-group v-model="userInfo.type" row class="mt-1">
              <!--<v-radio label="Admin" color="red" value="1"></v-radio>-->
              <v-radio label="" color="green" value="2">
                <template v-slot:label>
                  <strong class="success--text">Agent</strong>
                </template>
              </v-radio>
              <v-radio label="" color="warning" value="3">
                <template v-slot:label>
                  <strong class="warning--text">Member</strong>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-container>

      <v-divider/>

      <div class="pa-5">
        <v-btn color="#2d8c40" block dark large @click="updateUser()">UPDATE PROFILE</v-btn>
      </div>
    </v-card>

    <v-dialog v-model="deleteModal" persistent max-width="400">
      <v-card>
        <v-card-title class="text-h5">Confirm Delete User</v-card-title>
        <v-card-text>
          Are you sure you want to delete this user 
          <b class="lr-header">{{this.userInfo.email}}</b>?
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn color="grey darken-1" text @click="deleteModal = false">
            No, I change my mind
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="deleteUser">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="resetPassModal" persistent max-width="400">
      <v-card>
        <v-card-title class="text-h5">Reset Password</v-card-title>
        <v-card-text>
          Are you sure you want to reset the password of this user 
          <b class="lr-header">{{this.userInfo.email}}</b>?
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn color="grey darken-1" text @click="resetPassModal = false">
            No, I change my mind
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="resetPass">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'AdminUsers',
  data() {
    return {
      error: {
        firstName: '',
        lastName: '',
        mobile: '',
        aplGoUser: '',
        aplGoSponsorId: '',
        leftId: '',
        rightId: '',
        instagram: '',
        facebook: '',
        mbid: '',
        sponsorMbid: '',
        busdWallet: '',
      },
      deleteModal: false,
      resetPassModal: false
    }
  },
  computed: {
    userId () { return this.$route.params.id },
    user () { return this.$store.state.user },
    admin () { return this.$store.state.admin },
    userInfo () { return this.admin.userInfo }
  },
  methods: {
    getUserInfo () {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'getUserInfo',
        token: self.user.token,
        user_id: self.userId,
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          self.$router.push('/admin/users')
          return
        }
        self.$store.commit('admin/setUserData', {
          id: res.data.id,
          email: res.data.email,
          type: res.data.user_type,
          firstName: res.data.first_name,
          lastName: res.data.last_name,
          mobile: res.data.contact_number,
          aplGoUser: res.data.apl_go_user,
          aplGoSponsorId: res.data.apl_go_sponsor_id,
          leftId: res.data.left_id || '',
          rightId: res.data.right_id || '',
          instagram: res.data.instagram_url,
          facebook: res.data.facebook_url,
          mbid: res.data.mbid,
          sponsorMbid: res.data.sponsor_mbid,
          busdWallet: res.data.busd_address,
        })
      })
    },
    fieldChange (field) {
      if (this.error[field] && this.userInfo[field]) {
        this.error[field] = ''
      }
    },
    updateUser () {
      let self = this
      let hasError = false
      let fields = [
        'firstName', 'lastName', 'mobile',
        //'aplGoUser', 'aplGoSponsorId',
        'instagram', 'facebook'
      ]

      fields.forEach(field => {
        self.error[field] = ''
        if (!self.userInfo[field]) {
          hasError = true
          self.error[field] = 'This field is required.'
        }
      })
      
      if (hasError) return
      
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'updateUserInfo',
        token: self.user.token,
        user_id: self.userId,
        first_name: self.userInfo.firstName,
        last_name: self.userInfo.lastName,
        contact_number: self.userInfo.mobile,
        apl_go_user: self.userInfo.aplGoUser,
        apl_go_sponsor_id: self.userInfo.aplGoSponsorId,
        left_id: self.userInfo.leftId,
        right_id: self.userInfo.rightId,
        instagram_url: self.userInfo.instagram,
        facebook_url: self.userInfo.facebook,
        mbid: self.userInfo.mbid,
        sponsor_mbid: self.userInfo.sponsorMbid,
        busd_address: self.userInfo.busdWallet,
        email: self.userInfo.email,
      }, res => {
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            loading: false,
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        self.updateUserType()
      })
    },
    updateUserType() {
      let self = this

      self.$sdk.post('', {
        requestType: 'updateUserType',
        token: self.user.token,
        user_id: self.userId,
        user_type: self.userInfo.type
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        self.$store.commit('global/setGlobalData', {
          response: {
            show: true,
            success: true,
            title: 'Success!',
            message: 'User profile was updated.'
          }
        })
      })
    },
    deleteUser () {
      let self = this

      self.deleteModal = false
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'deleteUser',
        token: self.user.token,
        user_id: self.userId
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        self.$store.commit('global/setGlobalData', {
          response: {
            show: true,
            success: true,
            title: 'Success!',
            message: 'User was deleted.'
          }
        })
        self.$router.push('/admin/users')
      })
    },
    resetPass () {
      let self = this

      self.resetPassModal = false
      self.$store.commit('global/setGlobalData', {loading: true})

      setTimeout(() => {
        self.$store.commit('global/setGlobalData', {loading: false})
      }, 2000)

      // self.$sdk.post('', {
      //   requestType: 'deleteUser',
      //   token: self.user.token,
      //   email: self.userInfo.email
      // }, res => {
      //   self.$store.commit('global/setGlobalData', {loading: false})
      //   if (res.status == 'failed') {
      //     self.$store.commit('global/setGlobalData', {
      //       response: {
      //         show: true,
      //         success: false,
      //         title: 'Oops! Something went wrong.',
      //         message: res.message[0]
      //       }
      //     })
      //     return
      //   }

      //   self.$store.commit('global/setGlobalData', {
      //     response: {
      //       show: true,
      //       success: true,
      //       title: 'Success!',
      //       message: 'Password has been reset.'
      //     }
      //   })
      //   self.$router.push('/admin/users')
      // })
    }
  },
  watch: {
    userId () {
      this.getUserInfo()
    }
  },
  mounted () {
    this.getUserInfo()
  }
}
</script>

<style lang="scss" scoped>

</style>