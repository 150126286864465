<template>
  <div class="px-4">
    <div class="page-title mb-12 text-center" v-if="false">Welcome to our Millionaire Build Training Page!</div>

    <v-card elevation="5" outlined class="px-7 py-10 mb-7" v-if="false">
      <div class="lr-header d-flex">
        <div>
          <a href="#/training/2">
            <v-img src="https://play-lh.googleusercontent.com/QNP0Aj2hyumAmYiWVAsJtY2LLTQnzHxdW7-DpwFUFNkPJjgRxi-BXg7A4yI6tgYKMeU"
              class="mx-4" width="150"></v-img>
          </a>
        </div>
        <div>
          <a href="#/training/2" class="vlog-title">
            <div>
              Training: Associate Enrollment / PV Maintenance via GCASH
            </div>
          </a>
          <div class="date mb-3">JANUARY 25, 2023</div>
          <div class="vlog-desc">
            APL GO payment system currently accepts payment via Credit Cards and Accredited Banks. The training talks about the procedure on how to pay your Monthly PV Maintenance via GCASH.
          </div>
        </div>
      </div>

      <v-divider class="my-6" />

      <div class="lr-header d-flex">
        <div>
          <a href="#/training/1">
            <v-img src="https://img.freepik.com/free-vector/organic-flat-people-business-training-illustration_23-2148901755.jpg?w=2000"
              class="mx-4" width="150"></v-img>
          </a>
        </div>
        <div>
          <a href="#/training/1" class="vlog-title">
            <div>
              Training: Enrollment of a new Associate of APL Company 
            </div>
          </a>
          <div class="date mb-3">JANUARY 25, 2023</div>
          <div class="vlog-desc">
            This training talks about the steps on how to enroll an associate on APL GO via Invitation Link and Manual Registration.
          </div>
        </div>
      </div>
    </v-card>

    <v-card elevation="5" outlined class="mb-7">
      <div class="wistia_channel wistia_async_4pu1bsmeug mode=inline" style="min-height:100vh;position:relative;width:100%;"></div>
    </v-card>

  </div>
</template>

<script>
export default {
  name: 'Training',
  data() {
    return {
      title: '',
      desc: '',
      items: []
    }
  },
  computed: {
    id () { return this.$route.params.id },
    user () { return this.$store.state.user },
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.vlog-title{
  font-size: 21px;
  word-spacing: 2px;
  color: #1d2431!important;
  text-decoration: none;

  &:hover{
    text-decoration: underline;
  }
}
.date{
  letter-spacing: 2px;
  color: #868f9d;
  font-size: 14px;
}
.vlog-desc{
  color: #666;
  word-spacing: 2px;
}
</style>