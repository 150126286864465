<template>
  <div class="main px-4 d-flex justify-center align-center">
    <v-card class="pa-5 mb-8 form-box" color="#222" dark width="100%" max-width="700px">
      <v-img src="@/assets/img/gcash/logo.png" contain height="130px" class="my-5 mx-auto"></v-img>
      <div class="head lr-header mb-8 text-center">Sign Up - GCash!</div>

      <transition name="slide-down" mode="out-in">
        <v-card class="pa-5 sponsor-box mb-5" light v-if="gcash.mbid">
          <b class="lr-title">Your Sponsor:</b>
          <div class="name lr-header mt-1">{{gcash.name}}</div>
          <div class="mbid lr-header">{{gcash.mbid}}</div>
        </v-card>
      </transition>
      <v-row class="mb-5">
        <v-col class="col-md-6 col-12 mb-2">
          <div class="lr-title">First Name:</div>
          <v-text-field filled outlined hide-details="auto" v-model="firstName" :error-messages="error.firstName"></v-text-field>
        </v-col>
        <v-col class="col-md-6 col-12 mb-2">
          <div class="lr-title">Last Name:</div>
          <v-text-field filled outlined hide-details="auto" v-model="lastName" :error-messages="error.lastName"></v-text-field>
        </v-col>
        <v-col class="col-md-12 col-12 mb-2">
          <div class="lr-title">Email:</div>
          <v-text-field filled outlined hide-details="auto" v-model="email" :error-messages="error.email"></v-text-field>
        </v-col>
        <v-col class="col-md-6 col-12 mb-2">
          <div class="lr-title">Password:</div>
          <v-text-field type="password" filled outlined hide-details="auto" v-model="password" :error-messages="error.password"></v-text-field>
        </v-col>
        <v-col class="col-md-6 col-12 mb-2">
          <div class="lr-title">Repeat Password:</div>
          <v-text-field type="password" filled outlined hide-details="auto" v-model="rpassword" :error-messages="error.rpassword"></v-text-field>
        </v-col>
        <v-col class="col-md-12 col-12 mb-2">
          <div class="lr-title">Your Sponsor’s Gcash Number</div>
          <v-text-field filled outlined hide-details="auto" v-model="gcashSponsor" disabled></v-text-field>
        </v-col>
        <!-- <v-col class="col-md-12 col-12 mb-2">
          <div class="lr-title">BUSD Address</div>
          <small class="note lr-header">
            Make sure the address is correct. Incorrect address may result in asset/token lost and is irreversible.
          </small>
          <v-text-field filled outlined hide-details v-model="busdAddress"></v-text-field>
        </v-col> -->
      </v-row>
      <v-btn color="success" block x-large class="white--text pulse-btn" @click="submit">
        Submit Entry
      </v-btn>
    </v-card>

    <v-dialog v-model="gcash.show" persistent max-width="500">
      <v-card class="pt-3">
        <v-card-title class="title">GCash Validation</v-card-title>
        <v-card-text class="text mb-4 mt-1">
          Please input your GCash number
          <v-text-field filled outlined hide-details="auto" v-model="gcash.val" :error-messages="error.gcashSponsor"></v-text-field>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-5">
          <v-btn color="green darken-1" block dark @click="validate">
            Validate
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: 'gcash',
  data () {
    return {
      gcash: {
        show: false,
        val: '',
        name: '',
        mbid: ''
      },
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      rpassword: '',
      gcashSponsor: '',
      busdAddress: '',
      error: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        rpassword: '',
        gcashSponsor: '',
      }
    }
  },
  computed: {
    user () { return this.$store.state.user },
    userId () { return this.$route.params.id },
  },
  methods: {
    validate () {
      let self = this
      self.error.gcashSponsor = ''
      if (!self.gcash.val) {
        self.error.gcashSponsor ='This field is required.'
        return
      }
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'validateGcashNumber',
        gcash: self.gcash.val,
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status === 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: 'Invalid GCash Number. Does not exist in our system.'
            }
          })
          return
        }

        self.gcash.mbid = res.data.sponsor_mbid
        self.gcash.name = `${res.data.sponsor_first_name} ${res.data.sponsor_last_name}`
        self.gcashSponsor = self.gcash.val
        self.gcash.show = false
      })
    },
    submit () {
      let self = this
      let fields = [
        'firstName', 'lastName', 'email',
        'password', 'rpassword', 'gcashSponsor'
      ]
      let hasError = false

      fields.forEach(field => {
        self.error[field] = ''
        if (!self[field]) {
          hasError = true
          self.error[field] = 'This field is required.'
        }
      })
      if (self.password && self.rpassword && self.password != self.rpassword) {
        hasError = true
        self.error.password = 'Password and Repeat Password does not match.'
        self.error.rpassword = ' '
      }

      if (hasError) return
      
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'signupViaGCash',
        first_name: self.firstName,
        last_name: self.lastName,
        email: self.email,
        password: self.password,
        password_repeat: self.rpassword,
        gcash_sponsor: self.gcashSponsor,
        busd_address : self.busdAddress,
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        // self.$router.push('/profile')
        self.login()
      })
    },
    login () {
      let self = this
      self.$sdk.post('', {
        requestType: 'authenticate',
        email: self.email,
        password: self.password
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        localStorage.setItem('apl_session_token', res.data.token)
        self.$store.commit('user/setSessionData', {token: res.data.token})
        self.$store.commit('user/setUserData', {
          id: res.data.id,
          email: res.data.email,
          type: res.data.user_type,
        })
        self.$router.push('/profile')
      })
    }
  },
  mounted () {
    if (this.userId) this.gcash.val = this.userId
  }
}
</script>

<style lang="scss" scoped>
.main{
  min-height: 100%;
}
.form-box{
  .head{
    font-size: 28px!important;
    font-weight: 600;
  }
}
.sponsor-box{
  b{
    font-size: 18px;
    color: #777;
  }
  .name{
    line-height: 1;
    font-weight: 600;
  }
  .mbid{
    font-size: 15px;
  }
}

.note{
  line-height: 1;
}
</style>