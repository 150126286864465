<template>
  <div class="px-4">
    <v-row>
      <v-col class="col-md-6 text-center mb-8">
        <v-card class="pa-5" color="#222">
          <img class="fast_start_img" src="fast_start/Fast Start Promo 1.jpeg" />
          <div class="mt-2">
            <v-btn color="success" large rounded @click="downloadImg('/fast_start/Fast Start Promo 1.jpeg')">
              Download Image
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col class="col-md-6 text-center mb-8">
        <v-card class="pa-5" color="#222">
          <img class="fast_start_img elevation-4" src="fast_start/Fast Start Promo 2.jpeg" />
          <div class="mt-2">
            <v-btn color="success" large rounded @click="downloadImg('/fast_start/Fast Start Promo 2.jpeg')">
              Download Image
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col class="col-md-6 text-center mb-8">
        <v-card class="pa-5" color="#222">
          <img class="fast_start_img elevation-4" src="fast_start/Fast Start Promo 3.jpeg" />
          <div class="mt-2">
            <v-btn color="success" large rounded @click="downloadImg('/fast_start/Fast Start Promo 3.jpeg')">
              Download Image
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col class="col-md-6 text-center mb-8">
        <v-card class="pa-5" color="#222">
          <img class="fast_start_img elevation-4" src="fast_start/Fast Start Promo 5.jpeg" />
          <div class="mt-2">
            <v-btn color="success" large rounded @click="downloadImg('/fast_start/Fast Start Promo 5.jpeg')">
              Download Image
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col class="col-md-12 text-center mb-8">
        <v-card class="pa-5" color="#222">
          <img class="fast_start_img elevation-4" src="fast_start/Fast Start Promo 4.jpeg" />
          <div class="mt-2">
            <v-btn color="success" large rounded @click="downloadImg('/fast_start/Fast Start Promo 4.jpeg')">
              Download Image
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col class="col-md-6 text-center mb-8">
        <v-card class="pa-5" color="#222">
          <img class="fast_start_img elevation-4" src="fast_start/Fast Start Promo 6.jpg" />
          <div class="mt-2">
            <v-btn color="success" large rounded @click="downloadImg('/fast_start/Fast Start Promo 6.jpg')">
              Download Image
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col class="col-md-6 text-center mb-8">
        <v-card class="pa-5" color="#222">
          <img class="fast_start_img elevation-4" src="fast_start/Fast Start Promo 7.jpg" />
          <div class="mt-2">
            <v-btn color="success" large rounded @click="downloadImg('/fast_start/Fast Start Promo 7.jpg')">
              Download Image
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col class="col-md-6 text-center mb-8">
        <v-card class="pa-5" color="#222">
          <img class="fast_start_img elevation-4" src="fast_start/Fast Start Promo 9.jpg" />
          <div class="mt-2">
            <v-btn color="success" large rounded @click="downloadImg('/fast_start/Fast Start Promo 9.jpg')">
              Download Image
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col class="col-md-6 text-center mb-8">
        <v-card class="pa-5" color="#222">
          <img class="fast_start_img elevation-4" src="fast_start/Fast Start Promo 10.jpg" />
          <div class="mt-2">
            <v-btn color="success" large rounded @click="downloadImg('/fast_start/Fast Start Promo 10.jpg')">
              Download Image
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col class="col-md-6 text-center mb-8">
        <v-card class="pa-5" color="#222">
          <img class="fast_start_img elevation-4" src="fast_start/Fast Start Promo 11.jpg" />
          <div class="mt-2">
            <v-btn color="success" large rounded @click="downloadImg('/fast_start/Fast Start Promo 11.jpg')">
              Download Image
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col class="col-md-6 text-center mb-8">
        <v-card class="pa-5" color="#222">
          <img class="fast_start_img elevation-4" src="fast_start/Fast Start Promo 12.jpg" />
          <div class="mt-2">
            <v-btn color="success" large rounded @click="downloadImg('/fast_start/Fast Start Promo 12.jpg')">
              Download Image
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col class="col-md-12 text-center mb-8">
        <v-card class="pa-5" color="#222">
          <img class="fast_start_img elevation-4" src="fast_start/Fast Start Promo 8.jpg" />
          <div class="mt-2">
            <v-btn color="success" large rounded @click="downloadImg('/fast_start/Fast Start Promo 8.jpg')">
              Download Image
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col class="col-md-6 text-center mb-8">
        <v-card class="pa-5" color="#222">
          <img class="fast_start_img elevation-4" src="fast_start/Fast Start Promo 13.jpg" />
          <div class="mt-2">
            <v-btn color="success" large rounded @click="downloadImg('/fast_start/Fast Start Promo 13.jpg')">
              Download Image
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col class="col-md-6 text-center mb-8">
        <v-card class="pa-5" color="#222">
          <img class="fast_start_img elevation-4" src="fast_start/Fast Start Promo 14.jpg" />
          <div class="mt-2">
            <v-btn color="success" large rounded @click="downloadImg('/fast_start/Fast Start Promo 14.jpg')">
              Download Image
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col class="col-md-6 text-center mb-8">
        <v-card class="pa-5" color="#222">
          <img class="fast_start_img elevation-4" src="fast_start/Fast Start Promo 15.jpg" />
          <div class="mt-2">
            <v-btn color="success" large rounded @click="downloadImg('/fast_start/Fast Start Promo 15.jpg')">
              Download Image
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col class="col-md-6 text-center mb-8">
        <v-card class="pa-5" color="#222">
          <img class="fast_start_img elevation-4" src="fast_start/Fast Start Promo 16.jpg" />
          <div class="mt-2">
            <v-btn color="success" large rounded @click="downloadImg('/fast_start/Fast Start Promo 16.jpg')">
              Download Image
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col class="col-md-6 text-center mb-8">
        <v-card class="pa-5" color="#222">
          <img class="fast_start_img elevation-4" src="fast_start/Fast Start Promo 17.jpg" />
          <div class="mt-2">
            <v-btn color="success" large rounded @click="downloadImg('/fast_start/Fast Start Promo 17.jpg')">
              Download Image
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col class="col-md-6 text-center mb-8">
        <v-card class="pa-5" color="#222">
          <img class="fast_start_img elevation-4" src="fast_start/Fast Start Promo 18.jpg" />
          <div class="mt-2">
            <v-btn color="success" large rounded @click="downloadImg('/fast_start/Fast Start Promo 18.jpg')">
              Download Image
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col class="col-md-6 text-center mb-8">
        <v-card class="pa-5" color="#222">
          <img class="fast_start_img elevation-4" src="fast_start/Fast Start Promo 19.jpg" />
          <div class="mt-2">
            <v-btn color="success" large rounded @click="downloadImg('/fast_start/Fast Start Promo 19.jpg')">
              Download Image
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col class="col-md-6 text-center mb-8">
        <v-card class="pa-5" color="#222">
          <img class="fast_start_img elevation-4" src="fast_start/Fast Start Promo 20.jpg" />
          <div class="mt-2">
            <v-btn color="success" large rounded @click="downloadImg('/fast_start/Fast Start Promo 20.jpg')">
              Download Image
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col class="col-md-6 text-center mb-8">
        <v-card class="pa-5" color="#222">
          <img class="fast_start_img elevation-4" src="fast_start/Fast Start Promo 21.jpg" />
          <div class="mt-2">
            <v-btn color="success" large rounded @click="downloadImg('/fast_start/Fast Start Promo 21.jpg')">
              Download Image
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col class="col-md-6 text-center mb-8">
        <v-card class="pa-5" color="#222">
          <img class="fast_start_img elevation-4" src="fast_start/Fast Start Promo 22.jpg" />
          <div class="mt-2">
            <v-btn color="success" large rounded @click="downloadImg('/fast_start/Fast Start Promo 22.jpg')">
              Download Image
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col class="col-md-6 text-center mb-8">
        <v-card class="pa-5" color="#222">
          <img class="fast_start_img elevation-4" src="fast_start/Fast Start Promo 23.jpg" />
          <div class="mt-2">
            <v-btn color="success" large rounded @click="downloadImg('/fast_start/Fast Start Promo 23.jpg')">
              Download Image
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col class="col-md-6 text-center mb-8">
        <v-card class="pa-5" color="#222">
          <img class="fast_start_img elevation-4" src="fast_start/Fast Start Promo 24.jpg" />
          <div class="mt-2">
            <v-btn color="success" large rounded @click="downloadImg('/fast_start/Fast Start Promo 24.jpg')">
              Download Image
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import download from 'downloadjs'

export default {
  name: 'Training',
  data() {
    return {
      title: '',
      desc: '',
      items: []
    }
  },
  computed: {
    id () { return this.$route.params.id },
    user () { return this.$store.state.user },
  },
  methods: {
    downloadImg (url) {
      download(encodeURI(url))
    }
  },
  mounted () {
    console.log()
  }
}
</script>

<style lang="scss" scoped>
.vlog-title{
  font-size: 21px;
  word-spacing: 2px;
  color: #1d2431!important;
  text-decoration: none;

  &:hover{
    text-decoration: underline;
  }
}
.date{
  letter-spacing: 2px;
  color: #868f9d;
  font-size: 14px;
}
.vlog-desc{
  color: #666;
  word-spacing: 2px;
}
.fast_start_img{
  max-height:600px;
  max-width: 100%;
}
</style>