<template>
  <v-app>
    <transition name="slide-down" mode="out-in">
      <navigation v-show="showNav" />
    </transition>

    <v-main :class="['lr-app', {premium:routeMeta.premiumPage}]">
      <div :class="['d-flex justify-center wrap-box mx-auto', {fullpage: routeMeta && routeMeta.fullpage}]">
        <div :class="['main-box', {'pt-10':showNav}]">
          <transition name="page" mode="out-in">
            <router-view />
          </transition>
        </div>
      </div>
    </v-main>

    <v-dialog v-model="global.loading" persistent width="62">
      <v-card class="pa-4">
        <v-progress-circular indeterminate color="primary" class="mb-0"></v-progress-circular>
      </v-card>
    </v-dialog>

    <v-dialog v-model="global.response.show" persistent width="450">
      <v-card class="pa-4 text-center">
        <div class="response-box pa-5">
          <i class="fas fa-check-circle fa-fw fa-5x green--text mb-2" v-if="global.response.success"></i>
          <i class="fas fa-exclamation-triangle fa-fw fa-5x red--text mb-2" v-else></i>
          <label class="mb-3 lr-header">{{ global.response.title }}</label>
          <div v-if="typeof(global.response.message) === 'object'" class="lr-title">
            <span class="d-block mb-1" v-for="(message, index) in global.response.message" :key="index">{{message}}</span>
          </div>
          <span v-html="global.response.message" v-else class="lr-text"></span>
        </div>
        <v-btn class="mt-2" color="blue-grey darken-3" dark block @click="closeGlobalResponse">Close</v-btn>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import navigation from '@/components/navigation'

export default {
  name: 'App',
  components: { navigation },
  data() {
    return {
      initLoading: false
    }
  },
  computed: {
    user () { return this.$store.state.user },
    showNav () { return this.user.token || this.user.invitationCode },
    global () { return this.$store.state.global },
    routeMeta () { return this.$route.meta }
  },
  methods: {
    closeGlobalResponse () {
      this.$store.commit('global/hideResponse')
    },
    getUserInfo () {
      let self = this

      self.$sdk.post('', {
        requestType: 'getUserInfo',
        token: self.user.token
      }, res => {
        if (res.status != 'failed') {
          let data = res.data
          self.$store.commit('user/setUserData', {
            firstName: data.first_name,
            lastName: data.last_name,
            mobile: data.contact_number,
            aplGoUser: data.apl_go_user,
            aplGoSponsorId: data.apl_go_sponsor_id,
            instagram: data.instagram_url,
            facebook: data.facebook_url,
            profilePic: data.profile_pic,
            isVip: data.is_vip == 1,
            vipExpiry: data.vip_expiry,
            mbid: data.mbid,
            sponsorMbid: data.sponsor_mbid,
            sponsorId: data.sponsor_id,
            busdWallet: data.busd_address,
          })
        }
        self.$router.push('/agents')
      })
    }
  },
  mounted () {
  },
  beforeCreate () {
    let self = this
    let token = localStorage.getItem('apl_session_token')
    if (token) {
      self.$store.commit('user/setSessionData', {token: token})
      if (self.initLoading) return

      self.initLoading = true
      self.$sdk.post('', {
        requestType: 'getLoginInfo',
        token: token
      }, res => {
        let data = res.data
        if (res.status === 'failed') {
          
          self.$store.commit('user/setSessionData', {token: ''})
          if (self.$route.path !== '/' && self.$route.name !== 'gcash' && self.$route.name !== 'SignupGcash') {
            self.$router.push('/')
          }
          return
        }
        self.initLoading = false
        self.$store.commit('user/setUserData', {
          id: data.id,
          email: data.email,
          type: data.user_type
        })
        if (self.$route.name === 'Landing') {
          if (data.user_type == 1) {
            self.$router.push('/admin/users')
          } else {
            self.getUserInfo()
            // self.$router.push('/agents')
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import "@/assets/scss/global.scss";

.lr-app{
  background: #f2f6fc;

  &.premium{
    background: #282828;
  }
}
.wrap-box{
  width: 100%;
  max-width: 1264px;
  height: 100%;

  &.fullpage{
    max-width: 100%;
    overflow: auto;
  }
}
.main-box{
  width: 100%;
}
</style>