<template>
  <div class="px-4">
    <v-row>
      <v-col class="col-md-12 text-center mb-8">
        <v-card class="pa-5 mb-10" color="#222">
          <img class="fast_start_img" src="products/Product1.jpg" />
        </v-card>

        <v-card class="pa-5 mb-8" color="#222">
          <div class="wistia_responsive_padding" style="padding:62.5% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/dxkaw5xd2x?videoFoam=true" title="Prosperna - eCommerce Website Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%"></iframe></div></div>
        </v-card>

        <v-card class="pa-5 mb-8 form-box mx-auto" color="#222" dark width="100%" max-width="500px">
          <div class="head lr-header mb-8">Request an access for your eCommerce Website</div>
          <div class="mb-5">
            <div class="lr-title">Name:</div>
            <v-text-field filled outlined hide-details="auto" v-model="name" :error-messages="error.name"></v-text-field>
          </div>
          <div class="mb-10">
            <div class="lr-title">Phone Number:</div>
            <v-text-field filled outlined hide-details="auto" v-model="phone" :error-messages="error.phone"></v-text-field>
          </div>
          <v-btn color="success" block x-large class="white--text pulse-btn" @click="submit">
            Request an Access
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'Products',
  data() {
    return {
      name: '',
      phone: '',
      error: {
        name: '',
        phone: ''
      }
    }
  },
  computed: {
    user () { return this.$store.state.user },
  },
  methods: {
    submit () {
      let self = this
      let fields = ['name', 'phone']
      let hasError = false

      fields.forEach(field => {
        self.error[field] = ''
        if (!self[field]) {
          hasError = true
          self.error[field] = 'This field is required.'
        }
      })

      if (hasError) return
      
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'products_requestAccess',
        token: self.user.token,
        name: self.name,
        phone_number: self.phone,
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        self.name = ''
        self.phone = ''

        self.$store.commit('global/setGlobalData', {
          response: {
            show: true,
            success: true,
            title: 'Success!',
            message: 'Access request sent.'
          }
        })
      })
    },
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.vlog-title{
  font-size: 21px;
  word-spacing: 2px;
  color: #1d2431!important;
  text-decoration: none;

  &:hover{
    text-decoration: underline;
  }
}
.date{
  letter-spacing: 2px;
  color: #868f9d;
  font-size: 14px;
}
.vlog-desc{
  color: #666;
  word-spacing: 2px;
}
.fast_start_img{
  max-height:600px;
  max-width: 100%;
}

.form-box{
  .head{
    font-size: 28px!important;
    font-weight: 600;
  }
}
</style>