export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    loading: false,
    response: {
      show: false,
      success: false,
      title: '',
      message: ''
    },
  },
  mutations: {
    setGlobalData (state, data) {
      for (let field in data) {
        state[field] = data[field]
      }
    },
    hideResponse (state) {
      state.response.show = false
    }
  }
}