<template>
  <div>
    <div class="d-flex justify-center align-end text-center mb-8 lr-text">
      <div class="rank-box mx-1" v-if="userData[1]">
        <div class="place lr-title mb-1">2nd</div>
        <v-card class="pa-4 blue-grey darken-1" dark width="200px" height="300px">
          <v-avatar size="100" class="mb-5">
            <img :src="userData[1].img">
          </v-avatar>
          <div class="pts mb-1">{{userData[1].value}}</div>
          <div class="name">{{userData[1].name}}</div>
        </v-card>
      </div>
      <div class="rank-box mx-1 first" v-if="userData[0]">
        <div class="place lr-title mb-1">1st</div>
        <v-card class="pa-4 amber lighten-1" width="200px" height="350px">
          <v-avatar size="100" class="mb-5">
            <img :src="userData[0].img">
          </v-avatar>
          <div class="pts mb-1">{{userData[0].value}}</div>
          <div class="name">{{userData[0].name}}</div>
        </v-card>
      </div>
      <div class="rank-box mx-1" v-if="userData[2]">
        <div class="place lr-title mb-1">3rd</div>
        <v-card class="pa-4 brown darken-1" dark width="200px" height="250px">
          <v-avatar size="100" class="mb-5">
            <img :src="userData[2].img">
          </v-avatar>
          <div class="pts mb-1">{{userData[2].value}}</div>
          <div class="name">{{userData[2].name}}</div>
        </v-card>
      </div>
    </div>

    <div class="d-flex flex-column rank-list lr-text">
      <v-card class="pa-4 mx-auto mb-2" width="600px" v-if="userData[3]">
        <div class="d-flex align-center">
          <v-avatar size="50">
            <img :src="userData[3].img">
          </v-avatar>
          <div class="name px-4 flex-grow-1">{{userData[3].name}}</div>
          <div class="pts mb-1">{{userData[3].value}}</div>
        </div>
      </v-card>
      <v-card class="pa-4 mx-auto mb-2" width="600px" v-if="userData[4]">
        <div class="d-flex align-center">
          <v-avatar size="50">
            <img :src="userData[4].img">
          </v-avatar>
          <div class="name px-4 flex-grow-1">{{userData[4].name}}</div>
          <div class="pts mb-1">{{userData[4].value}}</div>
        </div>
      </v-card>
    </div>
     
  </div>
</template>

<script>

export default {
  name: 'AdminLeaderboard',
  props: ['userData'],
  data () {
    return {
    }
  },
  computed: {
    user () { return this.$store.state.user },
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.rank-box{
  font-weight: 600;
  width: 200px;

  .place{
    font-size: 18px;
  }
  .pts{
    font-size: 35px;
    line-height: 1;
  }
  .name{
    font-size: 18px;
  }
  &.first{
    .place{
      font-size: 31px;
    }
  }
}

.rank-list{
  font-weight: 600;

  .pts{
    font-size: 26px;
  }
}
</style>