<template>
  <div class="main px-4 pb-10">
    <transition name="page">
      <div class="desc-box pa-10">
        <div class="head mb-3">
          <span v-if="user.isVip">Congratulations!</span>
          <span v-else>Become a Premium Member</span>
        </div>
        <div class="title mb-2">
          <span v-if="user.isVip">You are now a PREMIUM VIP member! You’ve just unlocked these exclusive benefits:</span>
          <span v-else>You are not a Premium member. Availing Premium will grant you benefits below:</span>
        </div>
        <div>1. 100% commission from your Premium VIP referrals on their 1st month, 3rd month and to infinity.</div>
        <div class="pl-5">
          <i class="fa-solid fa-right-long mr-1"></i>
          Make sure your referrals are using your MBID.
        </div>
        <div class="pl-5">
          <i class="fa-solid fa-right-long mr-1"></i>
          Make sure your BUSD address is set and correct 
          ( <a href="#/profile" class="font-weight-bold">update here</a> ).
        </div>
        <div class="mt-2 mb-2">2. You can maximize the Millionaire Build Agents support and services. Connect to the available agents and ask for their assistance in growing your team.</div>
        <div class="mb-2">3. Exclusive access on the Millionaire Build Live Trainings.</div>
        <div class="mb-2">4. Manage your  Binary Tree and Core Team.</div>
        <div>5. Enables you to upgrade your skills.</div>
      </div>
    </transition>

    <v-card class="details-box pa-5 mt-5" color="#111" dark elevation="0">
      <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/d3hdqw77o4?videoFoam=true" title="Millionaire Build Introduction Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%"></iframe></div></div>
    </v-card>

    <v-card class="details-box pa-5 mt-5" color="#111" dark elevation="0">
      <div class="d-flex flex-column flex-md-row">
        <v-avatar class="ma-4 d-none d-md-block" size="150" tile>
          <v-img :src="user.profilePic"></v-img>
        </v-avatar>
        <v-img :src="user.profilePic" class="d-md-none mobile-pic" max-height="280" contain></v-img>

        <div class="text-center text-md-start flex-grow-1">
          <div class="px-4">
            <div class="field mb-4">
              <label class="grey--text caption">Name</label>
              <div class="value">
                {{user.firstName}} {{user.lastName}}
              </div>
            </div>
            <div class="field mb-4">
              <label class="grey--text caption">VIP Expiration Date</label>
              <div class="value">{{user.vipExpiry}}</div>
            </div>
            <div class="field mb-4">
              <label class="grey--text caption">MBID</label>
              <div class="value">{{user.mbid}}</div>
            </div>
            <div class="field mb-4">
              <label class="grey--text caption">Skill</label>
              <div class="value">{{userData.level_description}}</div>
            </div>
            <div class="field mb-4">
              <label class="grey--text caption">Sponsor MBID</label>
              <div class="value">{{userData.sponsor_mbid}} ({{userData.sponsor_details.first_name}} {{userData.sponsor_details.last_name}})</div>
            </div>
            <div class="field mb-4">
              <label class="grey--text caption">BUSD Wallet</label>
              <div v-if="user.busdWallet" class="value wallet">{{user.busdWallet}}</div>
              <div v-else class="value red--text">NOT SET</div>
            </div>
            <div class="field mb-4">
              <label class="grey--text caption">Your Referral Link</label>
              <v-text-field v-if="user.busdWallet" filled outlined hide-details="auto" dense disabled
                v-model="user.referralLink"
              ></v-text-field>
              <v-text-field v-else filled outlined hide-details="auto" dense disabled value="XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
              ></v-text-field>
              <small class="red--text" v-if="user.busdWallet.length==0">Setup your BUSD wallet address to activate your referral link.</small>
            </div>
          </div>
        </div>
      </div>      
    </v-card>

    <v-card class="pa-10 mt-5" color="#111" dark elevation="0">
      <div class="d-flex justify-space-between align-center mb-5">
        <div class="trx-title">Earnings History</div>
        <div class="trx-earnings">Total Earnings: <b>{{totalEarnings}}</b></div>
      </div>
      <v-data-table :headers="earningsHeaders" :items="earnings" :items-per-page="10" class="elevation-1 mb-5">
        <template #item.trxId="{ value }">
          <a :href="'https://bscscan.com/tx/'+value" target="_blank" style="text-decoration:none;color:#e6c069 !important;">
            {{ value }}
          </a>
      </template>
      </v-data-table>
    </v-card>

    <v-card class="pa-10 mt-5" color="#111" dark elevation="0">
      <div class="d-flex justify-space-between align-center mb-5">
        <div class="trx-title">Purchase History</div>
      </div>
      <v-data-table :headers="purchasesHeaders" :items="purchases" :items-per-page="10" class="elevation-1 mb-5">
        <template #item.trxId="{ value }">
          <a :href="'https://bscscan.com/tx/'+value" target="_blank" style="text-decoration:none;color:#e6c069 !important;">
            {{ value }}
          </a>
      </template>
      </v-data-table>
    </v-card>

    <v-row v-if="currentTransactionId == 0" class="mt-5">
      <v-col md="6" :offset-md="user.isVip ? 0 : 3" class="py-4">
        <v-btn color="#e6c069" block x-large class="black--text" @click="monthlyDialog.show=true">
          <span v-if="!user.isVip">Upgrade to Premium VIP</span>
          <span v-else>Purchase another Premium VIP</span>
        </v-btn>
      </v-col>
      <v-col md="6" class="py-4" v-if="user.isVip">
        <v-btn color="#00695C" block x-large class="white--text pulse-btn" @click="upgradeDialog.show=true">
          <span>Upgrade Your Skill</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="currentTransactionId != 0">
      <v-col md="6" offset-md="3" class="py-4 mt-5">
        <v-btn color="#e6c069" block x-large class="black--text" :to="`/premium/transaction/${currentTransactionId}`">
          <span>Complete Your Transaction</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="monthlyDialog.show" persistent max-width="600px">
      <v-card>
        <v-card-title class="modal-head mb-5">
          <span>Purchase Premium</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <b class="lr-header field-label">Months</b>
                <v-select required :items="monthlyDialog.items" v-model="monthlyDialog.months"
                  class="pt-0 mt-0 mb-5" hide-details></v-select>
              </v-col>
              <v-col cols="12" class="lr-header">
                Note: Total amount of premium is affected by your skill level.
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="monthlyDialog.show = false">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="createMonthlyPremiumTransaction()">
            Proceed Purchase
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="upgradeDialog.show" persistent max-width="600px">
      <v-card>
        <v-card-title class="mb-5 modal-head">
          <span>Upgrade Skill</span>
        </v-card-title>
        <v-card-text>
          <v-card elevation="0" outlined color="#efefef" class="pa-5 mb-5">
            <div class="skill-box d-flex justify-space-between align-center lr-header">
              <div class="text-center">
                <div class="your-current mb-1">Your current skill:</div>
                <img :src="`premium/${userData.level_description.toLowerCase()}.png`" />
                <div class="skill-name">{{userData.level_description}}</div>
              </div>
              <div class="text-center">
                <i class="fa-solid fa-chevron-right fa-fw fa-3x"></i>
              </div>
              <div class="text-center">
                <div class="your-current  mb-1">UPGRADE into:</div>
                <img :src="`premium/${targetSkill.toLowerCase()}.png`" />
                <div class="skill-name">{{targetSkill}}</div>
              </div>
            </div>
          </v-card>
          <v-container>
            <v-row>
              <v-col cols="12">
                <div class="lr-header field-label">Skills</div>
                <v-select item-value="value" required :items="upgradeDialog.items"
                  v-model="upgradeDialog.level" class="pt-0 mt-0 mb-5" hide-details>
                  <template slot="selection" slot-scope="data">
                    <!-- HTML that describe how select should render selected items -->
                    ({{ data.item.amount }}) {{ data.item.text }}
                  </template>
                  <template slot="item" slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    ({{ data.item.amount }}) {{ data.item.text }}
                  </template>
  </v-select>
              </v-col>
              <v-col cols="12" class="lr-header text-gold">
                Note: You can only upgrade your skills and cannot ever downgrade. Advance months will also be calculated in the total amount payable.
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="upgradeDialog.show = false">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="createUpgradePremiumTransaction()">
            Proceed Upgrade
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'premium',
  data() {
    return {
      showAlert: false,
      earnings: [],
      earningsHeaders: [
        { text: 'Date', value: 'date', sortable: false },
        { text: 'MBID', value: 'mbid', sortable: false },
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Value', value: 'value', sortable: false },
        { text: 'Transaction ID', value: 'trxId', sortable: false },
      ],
      totalEarnings: 0,
      purchases: [],
      purchasesHeaders: [
        { text: 'Date', value: 'date', sortable: false },
        { text: 'Type of Purchase', value: 'type', sortable: false },
        { text: 'Amount', value: 'amount', sortable: false },
      ],
      userData: {}, // Holds user data
      upgradeDialog: {
        show: false,
        items: [],
        level: 1,
      },
      monthlyDialog: {
        show: false,
        items: [1],
        months: 1,
      },
      currentTransactionId: 0,
      targetSkill: ''
    }
  },
  computed: {
    user () { return this.$store.state.user },
  },
  methods: {
    getVipEarnings () {
      let self = this
      self.$sdk.post('', {
        requestType: 'getVIPEarnings',
        token: self.user.token
      }, res => {
        if (res.data.total) {
          self.totalEarnings = res.data.earnings
          res.data.data.forEach(data => {
            self.earnings.push({
              id: data.id,
              date: moment(data.created_at, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY hh:mmA'),
              mbid: data.mbid,
              name: `${data.first_name} ${data.last_name}`,
              value: parseFloat(data.value).toFixed(2),
              trxId: data.txid
            })
          })
        }
      })
    },
    getVipPurhcases () {
      let self = this
      self.$sdk.post('', {
        requestType: 'getVIPTransactions',
        token: self.user.token
      }, res => {
        if (res.data.total) {
          res.data.data.forEach(data => {
            self.purchases.push({
              id: data.id,
              date: moment(data.created_at, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY hh:mmA'),
              comment: data.comment,
              trxId: data.txid
            })
          })
        }
      })
    },
    getUserInfo () {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'getUserInfo',
        token: self.user.token
      }, res => {
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            loading: false,
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          self.$router.push('/auth')
          return
        }
        self.$store.commit('user/setUserData', {
          id: res.data.id,
          email: res.data.email,
          type: res.data.user_type,
          firstName: res.data.first_name,
          lastName: res.data.last_name,
          mobile: res.data.contact_number,
          aplGoUser: res.data.apl_go_user,
          aplGoSponsorId: res.data.apl_go_sponsor_id,
          instagram: res.data.instagram_url,
          facebook: res.data.facebook_url,
          profilePic: res.data.profile_pic,
          isVip: res.data.is_vip == 1,
          vipExpiry: res.data.vip_expiry,
          mbid: res.data.mbid,
          sponsorMbid: res.data.sponsor_mbid,
          sponsorId: res.data.sponsor_id,
          busdWallet: res.data.busd_address,
          referralLink: res.data.referral_link
        })
        self.$store.commit('global/setGlobalData', {loading: false})
      })
    },
    getUserInfo2 () {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'getUserInfo2',
        token: self.user.token
      }, res => {
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            loading: false,
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          self.$router.push('/auth')
          return
        }
        self.userData = res.data
        self.upgradeDialog.level = parseInt(self.userData.level) + 1
        
        self.$store.commit('global/setGlobalData', {loading: false})
      })
    },
    getAvailableSkills () {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'getAvailableSkills',
        token: self.user.token
      }, res => {
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            loading: false,
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }
        self.upgradeDialog.items = res.data.skills
        let hasDefault = false
        res.data.skills.forEach(skill => {
          if (!skill.disabled && !hasDefault) {
            hasDefault = true
            self.targetSkill = skill.text
          }
        })
        
        self.$store.commit('global/setGlobalData', {loading: false})
      })
    },
    createUpgradePremiumTransaction () {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'createUpgradePremiumTransaction',
        token: self.user.token,
        level: self.upgradeDialog.level
      }, res => {
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            loading: false,
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }
        self.$router.push('/premium/transaction/'+res.data.id)
        self.$store.commit('global/setGlobalData', {loading: false})
      })
    },
    createMonthlyPremiumTransaction () {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'createMonthlyPremiumTransaction',
        token: self.user.token,
        level: self.monthlyDialog.months
      }, res => {
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            loading: false,
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }
        self.$router.push('/premium/transaction/'+res.data.id)
        self.$store.commit('global/setGlobalData', {loading: false})
      })
    },
    getCurrentTransaction () {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'getCurrentTransaction',
        token: self.user.token
      }, res => {
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            loading: false,
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }
        self.currentTransactionId = res.data.id
        
        self.$store.commit('global/setGlobalData', {loading: false})
      })
    },
    getPremiumPurchases () {
      let self = this
      self.$sdk.post('', {
        requestType: 'getPremiumPurchases',
        token: self.user.token
      }, res => {
        if (res.data.total) {
          res.data.records.forEach(data => {
            self.purchases.push({
              id: data.id,
              date: moment(data.created_at, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY hh:mmA'),
              type: data.type_description,
              amount: data.amount + ' BUSD',
            })
          })
        }
      })
    },
  },
  watch: {
    'upgradeDialog.level': function (val) {
      let index = this.upgradeDialog.items.findIndex(x => x.value == val)
      if (index > -1) {
        this.targetSkill = this.upgradeDialog.items[index].text
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.showAlert = true
    }, 700)

    this.getVipEarnings()
    this.getPremiumPurchases()
    this.getUserInfo()
    this.getUserInfo2()
    this.getAvailableSkills()
    this.getCurrentTransaction()
  },
}
</script>

<style lang="scss" scoped>
.desc-box{
  color: #8c8c8c;
  font-size:15px;
  background: #111;
  border-radius: 5px;

  .head{
    color: #e6c069;
    font-size: 48px;
    font-weight: 600;
    line-height: 1;
  }
  .title{
    font-size: 21px;
  }
  a{
    color: #e6c069!important;
  }
}

.field{
  .value{
    line-height: 1;
    display: block;
    font-size: 18px;
  }
  div{
    font-weight: 800;

    a{
      font-weight: 600;
    }
  }
}
.socials{
  color: #fff!important;
}
.wallet{
  font-family: 'Courier New', Courier, monospace;
  font-weight: 100!important;
  letter-spacing: 1px;
  line-height: 1.2!important;
  font-size: 18px!important;
}

.trx-title{
  color: #e6c069;
  font-size: 36px;
  font-weight: 600;
  line-height: 1;
}
.trx-earnings{
  color: #fff;
  font-size: 21px;
  font-weight: 600;
  line-height: 1;
  b{
    color: #e6c069;
  }
}

.pulse-btn{
  transform: scale(1);
  animation: pulse-gold 1.5s infinite;
}
@keyframes pulse-gold {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 177, 66, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 177, 66, 0);
  }
  
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
  }
}

.modal-head{
  span{
    color: #ddb047;
    font-weight: 600;
    font-size: 32px;
  }
}
.skill-name{
  font-weight: 600;
}
.field-label{
  font-size: 18px;
  font-weight: 600;
  color: #333;
}
.text-gold {
  color: #ddb047;
}
</style>