<template>
  <div class="main px-4 d-flex justify-center align-center flex-column">
    
    <v-img src="@/assets/img/gcash/spin.jpg" contain width="100%" max-width="600px" class="my-5 mx-auto"></v-img>
    <v-card class="pa-5 mb-8 form-box mx-auto" color="#222" dark>
      <div class="head lr-header mb-8">GCash Give Away!</div>
      <div class="mb-10">
        <div class="lr-title">GCash number of the person inviting you for spin:</div>
        <v-text-field filled outlined hide-details="auto" v-model="gcashInv" :error-messages="error.gcashInv"></v-text-field>
      </div>
      <div class="mb-5">
        <div class="lr-title">Your Name:</div>
        <v-text-field filled outlined hide-details="auto" v-model="name" :error-messages="error.name"></v-text-field>
      </div>
      <div class="mb-10">
        <div class="lr-title">Your GCash Number:</div>
        <v-text-field filled outlined hide-details="auto" v-model="gcash" :error-messages="error.gcash"></v-text-field>
      </div>
      <v-btn color="success" block x-large class="white--text pulse-btn" @click="submit">
        Submit Entry
      </v-btn>
    </v-card>

  </div>
</template>

<script>

export default {
  name: 'gcash',
  data() {
    return {
      name: '',
      gcash: '',
      gcashInv: '',
      error: {
        name: '',
        gcash: '',
        gcashInv: '',
      }
    }
  },
  computed: {
    user () { return this.$store.state.user },
  },
  methods: {
    submit () {
      let self = this
      let fields = ['name', 'gcash', 'gcashInv']
      let hasError = false

      fields.forEach(field => {
        self.error[field] = ''
        if (!self[field]) {
          hasError = true
          self.error[field] = 'This field is required.'
        }
      })

      if (hasError) return
      
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'gcash_register',
        token: self.user.token,
        name: self.name,
        gcash: self.gcash,
        gcash_inviter: self.gcashInv,
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        self.name = ''
        self.gcash = ''
        self.gcashInv = ''

        self.$store.commit('global/setGlobalData', {
          response: {
            show: true,
            success: true,
            title: 'Success!',
            message: 'GCash Give Away entry submitted.'
          }
        })
      })
    },
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.main{
  min-height: 100%;
}
.form-box{
  width: 100%;
  max-width: 600px;
  .head{
    font-size: 28px!important;
    font-weight: 600;
  }
}
</style>