<template>
  <div>
    <div class="page-title">Transactions</div>
    <div class="sub-title mb-12">Website stats.</div>

    <div class="lr-header _title mb-1">User Transactions</div>
    <v-row class="mb-2">
      <v-col>
        <v-card flat class="pa-4 inv-stats-box light-blue darken-4 white--text">
          <div class="lr-header val">{{transactions.total}}</div>
          <div class="lr-text label">Total Transactions</div>
        </v-card>
      </v-col>
      <v-col>
        <v-card flat class="pa-4 inv-stats-box green darken-3 white--text">
          <div class="lr-header val">{{transactions.total_success}}</div>
          <div class="lr-text label">Successful Transactions</div>
        </v-card>
      </v-col>
      <v-col>
        <v-card flat class="pa-4 inv-stats-box warning darken-1 white--text">
          <div class="lr-header val">{{transactions.total_pending}}</div>
          <div class="lr-text label">Pending Transactions</div>
        </v-card>
      </v-col>
      <v-col>
        <v-card flat class="pa-4 inv-stats-box deep-orange darken-4 white--text">
          <div class="lr-header val">{{transactions.total_cancelled_or_error}}</div>
          <div class="lr-text label">Cancelled/Error Transasctions</div>
        </v-card>
      </v-col>
    </v-row>
    <v-text-field solo v-model="transactions.search" append-icon="mdi-magnify" class="pt-0" label="Search Transaction" hide-details></v-text-field>
    <v-data-table :headers="transactions.headers" class="elevation-1 mb-10 mt-3"
      :loading="transactions.loading" :search="transactions.search"
      :items="transactions.list" :items-per-page="10">
      <template v-slot:item.status_description="{ item }" >
        <v-chip label :color="transactionStatus(item.status).color" dark small>
          {{ item.status_description }}
        </v-chip>
      </template>
      <template v-slot:item.txid="{ item }">
          <a :href="'https://bscscan.com/tx/'+item.txid" target="_blank" style="text-decoration:none;">
            {{ item.txid }}
          </a>
      </template>
      <template v-slot:item.user_details="{ item }">
          <b>{{item.mbid}}</b><br/>
          {{item.name}}<br/>
          {{item.email}}
      </template>
      <template v-slot:item.expected_value="{ item }">
          {{item.expected_value}} | {{item.value}}
      </template>
    </v-data-table>

  </div>
</template>

<script>

export default {
  name: 'AdminTransactions',
  data() {
    return {
      loading: false,
      transactions: {
        loading: false,
        search: '',
        headers: [
          { text: 'ID', value: 'id', sortable: false },
          //{ text: 'MBID', value: 'mbid', sortable: false },
          //{ text: 'Name', value: 'name', sortable: false },
          //{ text: 'Email', value: 'email', sortable: false },
          { text: 'User', value: 'user_details', sortable: false },
          { text: 'Status', value: 'status_description', sortable: false },
          { text: 'TXID', value: 'txid', sortable: false, align: 'center' },
          { text: 'Amount | Value', value: 'expected_value', sortable: false, align: 'center' },
          //{ text: 'Value', value: 'value', sortable: false, align: 'center' },
          { text: 'Receiver', value: 'receiver', sortable: false, align: 'center' },
        ],
        list: [],
        total: 0,
        total_success: 0,
        total_pending: 0,
        total_cancelled_or_error: 0,
      },
    }
  },
  computed: {
    user () { return this.$store.state.user },
    analytics () { return this.$store.state.analytics }
  },
  methods: {
    getTransactions () {
      let self = this
      self.transactions.loading = true
      self.$sdk.post('', {
        requestType: 'admin_getListOfTransaction',
        token: self.user.token
      }, res => {
        self.transactions.loading = false
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        let data = res.data
        self.transactions.total = data.total
        self.transactions.total_success = data.total_successful_transaction
        self.transactions.total_pending = data.total_pending_transaction
        self.transactions.total_cancelled_or_error = data.total_cancelled_or_error_transaction
        data.transactions.forEach(x => {
          let tempValue = (x.value=='' || x.value==null) ? 0 : x.value
          let tempExpectedValue = (x.expected_value=='' || x.expected_value==null) ? 0 : x.expected_value
          let tempMbid = x.mbid;
          let tempName = `${x.first_name || ''} ${x.last_name || ''}`;
          let tempEmail = x.email || '';
          self.transactions.list.push({
            id: x.users_id,
            mbid: x.mbid,
            name: `${x.first_name || ''} ${x.last_name || ''}`,
            email: x.email || '',
            user_details: tempMbid+': '+tempName+' ('+tempEmail+')',
            status_description: x.status_description,
            txid: x.txid,
            value: parseFloat(tempValue).toFixed(2),
            expected_value: parseFloat(tempExpectedValue).toFixed(2),
            receiver: x.receiver,
            status: x.status,
          })
        })
      })
    },
    transactionStatus (status) {
      switch (status) {
        case '2': return { label: 'Error', color: 'error' }
        case '3': return { label: 'Cancelled', color: 'error' }
        case '0': return { label: 'Pending', color: 'warning' }
        case '1': return { label: 'Success', color: 'green' }
        default: return { label: '', color: '' }
      }
    },
  },
  mounted () {
    this.getTransactions()
  }
}
</script>

<style lang="scss" scoped>
._title{
  font-size: 21px;
  font-weight: 600;
}

.inv-stats-box{
  .-orange label{
    color: #854e05;
  }
  .-yellow label{
    color: #4f4612;
  }
  .-green label{
    color: #47791d;
  }

  .val{
    font-size: 35px;
    font-weight: 600;
  }
}

.user-level-box{
  .head{
    font-size: 21px;
    font-weight: 600;
  }
}
.stats-val{
  font-weight: 600;
  font-size: 35px;
  line-height: 1;
}
</style>