<template>
  <div class="main px-4">
    <div class="desc-box mb-5">
      <div class="px-10 py-10 pb-5">
        <div class="head mb-5 text-center"
          v-if="transactionData.details.status==0 || transactionData.details.status==1">
          You are one way closer to 
          <span v-if="transactionData.details.type==1">Purchasing Monthly Premium</span>
          <span v-if="transactionData.details.type==2">Upgrading Your Skill</span>
        </div>
        <div v-if="transactionData.details.status==2" class="head mb-5 text-center">Transaction Completed</div>
        <div v-if="transactionData.details.status==3" class="head mb-5 text-center">Transaction Cancelled</div>
      </div>
      <div class="text-center orange darken-2 white--text py-1">
        <i>Please complete your transaction.</i>
      </div>
    </div>

    <v-card class="details-box pa-5 mb-5" color="#111" dark elevation="0">
      <div class="pa-5 white--text lr-header card-head d-flex justify-space-between align-end mb-3">
        <v-btn color="primary" link outlined class="lr-header" to="/premium">
          Back
        </v-btn>
        <v-btn color="error" large class="lr-header" 
        v-if="transactionData.details.status==0" @click="cancelModal.show=true">
          Cancel Transaction
        </v-btn>
      </div>
      <div class="pa-5 white--text lr-header card-head d-flex justify-space-between align-end">
        <div>Transaction Details</div>
      </div>
      <div class="px-5 details-box lr-header mb-10">
        <v-simple-table>
          <tbody>
            <tr>
              <td><div class="d-label">Transaction #</div></td>
              <td><b class="d-value">{{ transactionData.details.id }}</b></td>
            </tr>
            <tr>
              <td><div class="d-label">Status</div></td>
              <td><b class="d-value">{{ transactionData.details.status_description }}</b></td>
            </tr>
            <tr>
              <td><div class="d-label">Created</div></td>
              <td><b class="d-value">{{ transactionData.details.created_at }}</b></td>
            </tr>
            <tr>
              <td><div class="d-label">Type</div></td>
              <td><b class="d-value">{{ transactionData.details.type_description }}</b></td>
            </tr>
            <tr v-if="transactionData.details.type==1">
              <td><div class="d-label">Months</div></td>
              <td><b class="d-value">{{ transactionData.details.months }}</b></td>
            </tr>
            <tr v-if="transactionData.details.type==1">
              <td><div class="d-label">Skill</div></td>
              <td><b class="d-value">{{transactionData.details.price * transactionData.details.level}} BUSD </b><i>({{ transactionData.details.level_description }})</i></td>
            </tr>
            <tr v-if="transactionData.details.type==2">
              <td><div class="d-label">Upgrade to</div></td>
              <td><b class="d-value">{{ transactionData.details.level_description }}</b></td>
            </tr>
            <tr v-if="transactionData.details.type==2">
              <td><div class="d-label">Active Months</div></td>
              <td><b class="d-value">{{ transactionData.details.active_months }}</b> <i>(current month + advance purchases)</i></td>
            </tr>
            <tr v-if="transactionData.details.type==2">
              <td><div class="d-label">Upgrade Amount</div></td>
              <td><b class="d-value">{{ (transactionData.details.level-userData.level)*transactionData.details.price }} BUSD </b><i>= ({{ userData.level*transactionData.details.price }}) {{ userData.level_description }} to ({{ transactionData.details.level*transactionData.details.price }}) {{ transactionData.details.level_description }}</i></td>
            </tr>
            <tr>
              <td><div class="d-label">Computation</div></td>
              <td v-if="transactionData.details.type==1"><b class="d-value">{{ transactionData.details.amount }} = {{transactionData.details.price * transactionData.details.level}} * {{ transactionData.details.months }}</b></td>    
              <td v-if="transactionData.details.type==2"><b class="d-value">{{ transactionData.details.amount }} = {{(transactionData.details.level-userData.level)*transactionData.details.price}} * {{ transactionData.details.active_months }}</b></td>
            </tr>
            <tr>
              <td><div class="d-label">Please Pay</div></td>
              <td><b class="d-value">{{ transactionData.details.amount }} BUSD</b></td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>

      <v-divider></v-divider>

      <div class="pa-5 pt-10 details-box lighten-5">
        <div class="card-head lr-header mb-5">This <span v-if="transactionData.details.type==1">Premium Purchase</span><span v-if="transactionData.details.type==2">Skill Upgrade</span> needs {{transactionData.breakdown.length}} transaction(s) to be completed.<br/>Please process the transactions below:</div>
        <v-card class="pa-5 mb-5" v-for="(breakdown, i) in transactionData.breakdown" :key="i" >
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="lr-font1">To MBID</td>
                  <td class="lr-header">{{ breakdown.mbid }}</td>
                </tr>
                <tr>
                  <td class="lr-font1">To Address</td>
                  <td class="lr-header">{{ breakdown.receiver }}</td>
                </tr>
                <tr>
                  <td class="lr-font1">Amount</td>
                  <td class="lr-header">{{ breakdown.expected_value }} BUSD</td>
                </tr>
                <tr>
                  <td class="lr-font1">Status</td>
                  <td class="lr-header">{{ breakdown.status_description }}</td>
                </tr>
                <tr>
                  <td class="lr-font1">TXID</td>
                  <td class="lr-header">
                    <div v-if="breakdown.status==0" @click="updateTransaction(breakdown)" class="update-text">
                      Update Transaction Here <i class="fa-solid fa-arrow-up-right-from-square"></i>
                    </div>
                    <!-- <v-btn color="primary" class="white--text" dark small  >
                      Update
                    </v-btn> -->
                    <a :href="`https://bscscan.com/tx/${breakdown.txid}`" target="_blank" v-else>
                      {{ breakdown.txid }}
                    </a>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </div>
    </v-card>

    <v-card class="pa-5 mb-10" color="#e6c069" elevation="0" light>
      <h3>IMPORTANT! PLEASE READ BEFORE SENDING/TRANSFFERING BUSD:</h3><br/>
      <p>
        1. Make sure you copy the correct address above.<br/>
        2. Make sure you are in Binace Smart Chain (BEP20) Network.<br/>
        3. Make sure you are sending BUSD token. (Contract: <a href="https://bscscan.com/address/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56" target="_blank" class="black--text">0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56</a>).<br/>
        4. Make sure to send complete BUSD amount.<br/><br/>

        <b>Note: Incorrect details may result in asset loss and is irreversible. Admin will not be held responsible for incorrect transactions.</b>

        <br/><br/>
        <a href="https://www.youtube.com/watch?v=o1Ht8QSivYo" target="_blank" class="black--text">How to Create a Metamask Wallet</a>
        <br/>
        <a href="https://support.ledger.com/hc/en-us/articles/4418163288081-How-to-access-your-Ledger-Binance-Smart-Chain-BSC-account-via-Metamask?docs=true" target="_blank" class="black--text">How to change to Binace Smart Chain (BSC) Network in Metamask.</a>
        <br/>
        <a href="https://www.youtube.com/watch?v=LcH4ESjlVog" target="_blank" class="black--text">How to Import BUSD and How to buy BUSD</a>
        <br/>
        <a href="https://support.ledger.com/hc/en-us/articles/6375103346077-Add-custom-tokens-to-MetaMask?docs=true" target="_blank" class="black--text">How to add tokens in Metamask?</a><br/>
      </p>
    </v-card>

    <v-dialog v-model="transactionModal.show" max-width="600">
      <v-card class="lr-header">
        <v-card-title class="text-h6 text-center d-block"><b class="lr-header">Complete Transaction</b></v-card-title>
        <v-card-text>
          <v-divider class="mt-7"></v-divider>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>To MBID</td>
                  <td>{{ transactionModal.mbid }}</td>
                </tr>
                <tr>
                  <td>To Address</td>
                  <td>{{ transactionModal.address }}</td>
                </tr>
                <tr>
                  <td>Amount</td>
                  <td>{{ transactionModal.value }} BUSD</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider class="mb-7"></v-divider>
          <v-row>
            <v-col class="col-12">
              <b class="lr-header">Transaction ID</b>
              <v-text-field outlined hide-details="auto" v-model="transactionModal.txid"
                class="txt-field"
              ></v-text-field>
            </v-col>
        </v-row>
        </v-card-text>
        <v-card-actions class="flex-column pb-5 px-6">
          <v-btn color="primary" @click="processPremiumTransaction()" block class="mb-5 lr-header">
            Update Transaction
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="cancelModal.show" max-width="400">
      <v-card class="lr-header text-center">
        <v-card-title class="text-h6 d-block mb-3"><span class="lr-header">Confirm Cancel Transaction</span></v-card-title>
        <v-card-text class="mb-3">
          Are you sure you want to cancel this transaction?
        </v-card-text>
        <v-card-actions class="flex-column px-6">
          <v-btn color="error" @click="cancelPremiumTransaction()" block large class="mb-5 lr-header">
            Cancel Transaction
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
export default {
  name: 'premium',
  data() {
    return {
      txid: '',
      sponsor_busd_wallet: 'x',
      showReturn: false,
      userData: [],
      transactionData: [],
      transactionId: this.$route.params.id,
      transactionModal: {
        show: false,
        id: '',
        txid: '',
        mbid: '',
        value: '',
        address: '',
      },
      cancelModal: {
        show: false,
      }
    }
  },
  methods: {
    getUserInfo2 () {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'getUserInfo2',
        token: self.user.token
      }, res => {
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            loading: false,
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          self.$router.push('/auth')
          return
        }
        self.userData = res.data
        
        self.$store.commit('global/setGlobalData', {loading: false})
      })
    },
    getPremiumTransaction () {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'getPremiumTransaction',
        token: self.user.token,
        id: self.transactionId,
      }, res => {
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            loading: false,
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })

          self.$router.push('/premium')
          return
        }

        self.transactionData = res.data.transaction
      })
    },
    updateTransaction(breakdown) {
      let self = this
      self.transactionModal.txid = ''
      self.transactionModal.id = breakdown.id
      self.transactionModal.mbid = breakdown.mbid
      self.transactionModal.address = breakdown.receiver
      self.transactionModal.value = breakdown.expected_value
      self.transactionModal.show = true
    },
    processPremiumTransaction() {
      let self = this

      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'processPremiumTransaction',
        token: self.user.token,
        id: self.transactionModal.id,
        txid: self.transactionModal.txid,
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          
          return
        }
        self.$store.commit('global/setGlobalData', {
          response: {
            show: true,
            success: true,
            title: 'Success!',
            message: res.message[0]
          }
        })

        self.transactionModal.show = false
        self.getUserInfo2()
        self.getPremiumTransaction()
      })
    },
    cancelPremiumTransaction() {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'cancelPremiumTransaction',
        token: self.user.token,
        id: self.transactionId,
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }
        self.$store.commit('global/setGlobalData', {
          response: {
            show: true,
            success: true,
            title: 'Success!',
            message: res.message[0]
          }
        })
      
        self.cancelModal.show = false
        self.getUserInfo2()
        self.getPremiumTransaction()
      })
    },
  },
  computed: {
    user () { return this.$store.state.user },
  },
  mounted () {
    this.getUserInfo2()
    this.getPremiumTransaction()
  },
}
</script>

<style lang="scss" scoped>
.page-title{
  color: #fff;
}

.desc-box{
  color: #8c8c8c;
  font-size:15px;
  background: #111;
  border-radius: 5px;

  .head{
    color: #e6c069;
    font-size: 30px;
    font-weight: 600;
    line-height: 1;
  }
  .title{
    font-size: 21px;
  }
  a{
    color: #e6c069!important;
  }
}

.card-head{
  font-size: 18px;
}
.update-text{
  cursor: pointer;
  color: #389ed2;
}
</style>