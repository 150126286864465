<template>
  <div>
    <div class="page-title">Analytics</div>
    <div class="sub-title mb-12">Website stats.</div>

    <div class="lr-header _title mb-1">User Stats</div>
    <v-row class="mb-2">
      <v-col>
        <v-card flat class="pa-4 inv-stats-box light-blue darken-4 white--text">
          <div class="lr-header val">{{userStats.total}}</div>
          <div class="lr-text label">Total Users</div>
        </v-card>
      </v-col>
      <v-col>
        <v-card flat class="pa-4 inv-stats-box green darken-3 white--text">
          <div class="lr-header val">{{userStats.active}}</div>
          <div class="lr-text label">Active Users</div>
        </v-card>
      </v-col>
      <v-col>
        <v-card flat class="pa-4 inv-stats-box blue-grey darken-1 white--text">
          <div class="lr-header val">{{userStats.inactive}}</div>
          <div class="lr-text label">Inactive Users</div>
        </v-card>
      </v-col>
      <v-col>
        <v-card flat class="pa-4 inv-stats-box deep-orange darken-4 white--text">
          <div class="lr-header val">{{userStats.exp}}</div>
          <div class="lr-text label">Users Expiring this month</div>
        </v-card>
      </v-col>
    </v-row>

    <v-card flat color="#333" class="px-4 py-7 user-level-box mb-15 white--text" :loading="level.loading">
      <v-row class="lr-header">
        <v-col class="col-md-6 px-4">
          <div class="mb-7" v-for="n in 6" :key="n">
            <div class="head">Level {{n}}</div>
            <v-progress-linear height="25" color="yellow darken-2"
              :value="(level[`level${n}`] / level[`level${n}Total`]) * 100" />
            <div class="d-flex justify-space-between">
              <div>Active: <b class="ml-1">{{level[`level${n}`]}}</b></div>
              <div>Total: <b class="ml-1">{{level[`level${n}Total`]}}</b></div>
            </div>
          </div>
        </v-col>
        <v-col class="col-md-6 px-4">
          <div class="mb-7" v-for="n in 6" :key="n">
            <div class="head">Level {{n + 6}}</div>
            <v-progress-linear height="25" color="yellow darken-2"
              :value="level[`level${n + 6}Total`] != 0 ? (level[`level${n + 6}`] / level[`level${n + 6}Total`]) * 100 : 0" />
            <div class="d-flex justify-space-between">
              <div>Active: <b class="ml-1">{{level[`level${n + 6}`]}}</b></div>
              <div>Total: <b class="ml-1">{{level[`level${n + 6}Total`]}}</b></div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <div class="lr-header _title mb-1">Invitation Stats</div>
    <v-row class="mb-10">
      <v-col>
        <v-card flat class="pa-4 inv-stats-box green darken-2 white--text">
          <div class="lr-header val">{{analytics.invitation.day1}}</div>
          <div class="lr-text label">
            In the last <b>1 Day</b>
          </div>
        </v-card>
      </v-col>
      <v-col>
        <v-card flat class="pa-4 inv-stats-box light-green darken-2 white--text">
          <div class="lr-header val">{{analytics.invitation.day7}}</div>
          <div class="lr-text label">
            In the last <b>7 Day</b>
          </div>
        </v-card>
      </v-col>
      <v-col>
        <v-card flat class="pa-4 inv-stats-box teal darken-2 white--text">
          <div class="lr-header val">{{analytics.invitation.day30}}</div>
          <div class="lr-text label">
            In the last <b>30 Day</b>
          </div>
        </v-card>
      </v-col>
    </v-row>

   <!-- <div class="lr-header _title mb-1">Invitation</div>
    <v-text-field solo v-model="search_invitation" append-icon="mdi-magnify" label="Search Invitation" hide-details class="pt-0"></v-text-field>
    <v-data-table :headers="invHeaders" class="elevation-1 mb-10 mt-3" :loading="loading" :search="search_invitation"
      :items="analytics.invitation.list" :items-per-page="10">
    </v-data-table>

    <div class="lr-header _title mb-1">Signup</div>
    <v-text-field solo v-model="search_signup" append-icon="mdi-magnify" class="pt-0" label="Search Invitation" hide-details></v-text-field>
    <v-data-table :headers="sponsorHeaders" class="elevation-1 mb-10 mt-3" :loading="loading" :search="search_signup"
      :items="analytics.signup.list" :items-per-page="10">
    </v-data-table>-->

    <div class="lr-header _title mb-1">Purchase Records Per User</div>
    <v-card class="blue darken-4 white--text pa-5 mb-5 lr-header" max-width="400px">
      <div class="stats-val mb-1">{{purchase.total.toFixed(2)}}</div>
      <span>Total</span>
    </v-card>
    <v-text-field solo v-model="purchase.search" append-icon="mdi-magnify" class="pt-0" label="Search Purchase" hide-details></v-text-field>
    <v-data-table :headers="purchase.headers" class="elevation-1 mb-10 mt-3"
      :loading="purchase.loading" :search="purchase.search"
      :items="purchase.list" :items-per-page="10">
    </v-data-table>

    <div class="lr-header _title mb-1">Commission Records Per User</div>
    <v-card class="blue darken-4 white--text pa-5 mb-5 lr-header" max-width="400px">
      <div class="stats-val mb-1">{{commission.total.toFixed(2)}}</div>
      <span>Total</span>
    </v-card>
    <v-text-field solo v-model="commission.search" append-icon="mdi-magnify" class="pt-0" label="Search Commission" hide-details></v-text-field>
    <v-data-table :headers="commission.headers" class="elevation-1 mb-10 mt-3"
      :loading="purchase.loading" :search="commission.search"
      :items="commission.list" :items-per-page="10">
    </v-data-table>
  </div>
</template>

<script>

export default {
  name: 'AdminAnalytics',
  data() {
    return {
      search_invitation: '',
      search_signup: '',
      invHeaders: [
        { text: 'Invitation Code', value: 'code', sortable: true },
        { text: 'Total Invites', value: 'total', sortable: true },
        { text: 'Name of Inviter', value: 'name', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
      ],
      sponsorHeaders: [
        { text: 'Invitation Code', value: 'code', sortable: true },
        { text: 'Sponsor ID', value: 'sponsorId', sortable: true },
        { text: 'Name of Signee', value: 'name', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
      ],
      loading: false,
      userStats: {
        total: 0,
        active: 0,
        inactive: 0,
        exp: 0
      },
      level: {
        loading: false,
        level1: 0,
        level1Total: 0,
        level2: 0,
        level2Total: 0,
        level3: 0,
        level3Total: 0,
        level4: 0,
        level4Total: 0,
        level5: 0,
        level5Total: 0,
        level6: 0,
        level6Total: 0,
        level7: 0,
        level7Total: 0,
        level8: 0,
        level8Total: 0,
        level9: 0,
        level9Total: 0,
        level10: 0,
        level10Total: 0,
        level11: 0,
        level11Total: 0,
        level12: 0,
        level12Total: 0,
      },
      purchase: {
        loading: false,
        search: '',
        headers: [
          { text: 'ID', value: 'id', sortable: false },
          { text: 'MBID', value: 'mbid', sortable: false },
          { text: 'Name', value: 'name', sortable: false },
          { text: 'Email', value: 'email', sortable: false },
          { text: 'Total Purchased', value: 'total', sortable: false, align: 'center' },
        ],
        list: [],
        total: 0
      },
      commission: {
        search: '',
        headers: [
          { text: 'Sponsor ID', value: 'id', sortable: false },
          { text: 'MBID', value: 'mbid', sortable: false },
          { text: 'Name', value: 'name', sortable: false },
          { text: 'Email', value: 'email', sortable: false },
          { text: 'Total Earned', value: 'total', sortable: false, align: 'center' },
        ],
        list: [],
        total: 0
      }
    }
  },
  computed: {
    user () { return this.$store.state.user },
    analytics () { return this.$store.state.analytics }
  },
  methods: {
    getuserStats () {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'admin_getStatsOfUsers',
        token: self.user.token
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        let data = res.data
        self.userStats.total = data.total_users
        self.userStats.active = data.total_active
        self.userStats.inactive = data.total_inactive
        self.userStats.exp = data.expiring_this_month

        self.getStats()
        self.getStatsLevel()
      })
    },
    getStats () {
      let self = this
      self.loading = true
      self.$sdk.post('', {
        requestType: 'getAnalytics',
        token: self.user.token
      }, res => {
        self.loading = false
        if (res.data.status == 'failed') {
          self.$store.commit('analytics/setInvationData', {list: []})
          return
        }
        let invitation = []
        res.data.analytics_invitation.data.forEach(stat => {
          invitation.push({
            code: stat.invitation_code,
            total: stat.total_invites,
            name: `${stat.first_name || ''} ${stat.last_name || ''}`,
            email: stat.email || ''
          })
        })
        self.$store.commit('analytics/setInvationData', {
          list: invitation,
          day1: res.data.analytics_invitation_days['1day'],
          day7: res.data.analytics_invitation_days['7day'],
          day30: res.data.analytics_invitation_days['30day'],
        })

        let signup = []
        res.data.analytics_signup.data.forEach(stat => {
          signup.push({
            code: stat.invitation_code,
            sponsorId: stat.sponsor_id,
            name: `${stat.signup_first_name || ''} ${stat.signup_last_name || ''}`,
            email: stat.signup_email || ''
          })
        })
        self.$store.commit('analytics/setSignupData', {list: signup})
      })
    },
    getStatsLevel () {
      let self = this
      self.level.loading = true
      self.$sdk.post('', {
        requestType: 'admin_getStatsOfLevels',
        token: self.user.token
      }, res => {
        self.level.loading = false
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        let data = res.data
        self.level.level1Total = data.total_level_1
        self.level.level1 = data.total_level_1_active
        self.level.level2Total = data.total_level_2
        self.level.level2 = data.total_level_2_active
        self.level.level3Total = data.total_level_3
        self.level.level3 = data.total_level_3_active
        self.level.level4Total = data.total_level_4
        self.level.level4 = data.total_level_4_active
        self.level.level5Total = data.total_level_5
        self.level.level5 = data.total_level_5_active
        self.level.level6Total = data.total_level_6
        self.level.level6 = data.total_level_6_active
        self.level.level7Total = data.total_level_7
        self.level.level7 = data.total_level_7_active
        self.level.level8Total = data.total_level_8
        self.level.level8 = data.total_level_8_active
        self.level.level9Total = data.total_level_9
        self.level.level9 = data.total_level_9_active
        self.level.level10Total = data.total_level_10
        self.level.level10 = data.total_level_10_active
        self.level.level11Total = data.total_level_11
        self.level.level11 = data.total_level_11_active
        self.level.level12Total = data.total_level_12
        self.level.level12 = data.total_level_12_active

        self.getStatsPurchase()
      })
    },
    getStatsPurchase () {
      let self = this
      self.purchase.loading = true
      self.$sdk.post('', {
        requestType: 'admin_getStatsOfPurchase',
        token: self.user.token
      }, res => {
        self.purchase.loading = false
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        let data = res.data
        self.purchase.total = data.purchased.total
        data.purchased.records.forEach(x => {
          self.purchase.list.push({
            id: x.users_id,
            mbid: x.mbid,
            name: `${x.first_name || ''} ${x.last_name || ''}`,
            email: x.email || '',
            total: x.total_purchased || 0
          })
        })

        self.commission.total = data.commission.total
        data.commission.records.forEach(x => {
          self.commission.list.push({
            id: x.sponsors_id,
            mbid: x.mbid,
            name: `${x.first_name || ''} ${x.last_name || ''}`,
            email: x.email || '',
            total: x.total_earn || 0
          })
        })
      })
    }
  },
  mounted () {
    this.getuserStats()
  }
}
</script>

<style lang="scss" scoped>
._title{
  font-size: 21px;
  font-weight: 600;
}

.inv-stats-box{
  .-orange label{
    color: #854e05;
  }
  .-yellow label{
    color: #4f4612;
  }
  .-green label{
    color: #47791d;
  }

  .val{
    font-size: 35px;
    font-weight: 600;
  }
}

.user-level-box{
  .head{
    font-size: 21px;
    font-weight: 600;
  }
}
.stats-val{
  font-weight: 600;
  font-size: 35px;
  line-height: 1;
}
</style>