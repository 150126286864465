export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    availability: {
      from: '',
      to: '',
      timezone: '',
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      description: '',
      note: '',
    }
  },
  mutations: {
    setAvailabilityData (state, data) {
      for (let field in data) {
        state.availability[field] = data[field]
      }
    },
  }
}