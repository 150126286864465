<template>
  <div class="px-4">
    <div class="page-title">Announcements</div>
    <div class="sub-title mb-7">News, Updates and other relevant information from Ace Fletcher and Admins.</div>

    <v-card elevation="5" outlined class="px-7 py-10 mb-7">
      <div v-for="(post, i) in posts" :key="i">
        <div class="lr-header d-flex post-box">
          <div>
            <a :href="`#/post/${post.slug}`">
              <v-img src="https://ionicframework.com/docs/img/demos/thumbnail.svg"
              class="mx-4" width="150"></v-img>
            </a>
          </div>
          <div class="desc">
            <div>
              <div class="featured-box py-1 px-3" v-if="post.featured">FEATURED</div>
            </div>
            <a :href="`#/post/${post.slug}`" class="vlog-title">{{post.title}}</a>
            <div class="date">Created: {{post.created}}</div>
            <div class="date mb-3">Last Update: {{post.updated}}</div>
            <div class="vlog-desc">{{post.description}}</div>
          </div>
        </div>
        <v-divider class="my-6" v-if="i != posts.length - 1" />
      </div>

      <div v-if="loading && !posts.length" class="text-center py-4 loader lr-text">
        <v-progress-circular indeterminate color="primary" class="mb-0 mr-3"></v-progress-circular>
        <b>Getting Announcement . . .</b>
      </div>

      <div v-if="!loading && !posts.length" class="text-center py-4 empty-state lr-text">
        <i class="fa-regular fa-face-grin-wide fa-5x mb-4"></i>
        <div>No Announcement as of yet.</div>
        <div>Please come back later.</div>
      </div>
    </v-card>

  </div>
</template>

<script>

export default {
  name: 'Post List',
  data () {
    return {
      posts: [],
      loading: false,
      createPostModal: false,
      createPostModalField: {
        title: '',
        description: '',
      },
      deleteModal: {
        show: false,
        id: 0,
        title: ''
      }
    }
  },
  computed: {
    id () { return this.$route.params.id },
    user () { return this.$store.state.user },
  },
  methods: {
    getPosts () {
      let self = this
      self.loading = true
      self.$sdk.post('', {
        requestType: 'getListOfTrainings',
        token: self.user.token
      }, res => {
        self.loading = false
        if (res.data.status == 'failed') {
          return
        }
        self.posts = []
        res.data.forEach(post => {
          if (post.status == '1') {
            self.posts.push({
              id: post.id,
              name: `${post.first_name} ${post.last_name}`,
              title: post.title,
              created: post.created_at,
              updated: post.updated_at,
              description: post.description,
              status: post.status == '1',
              featured: post.is_featured == '1',
              slug: post.slug,
              loading: false
            })
          }
        })
        self.posts.sort((a, b) => b.featured - a.featured)
      })
    },
  },
  mounted () {
    this.getPosts()
  }
}
</script>

<style lang="scss" scoped>
.vlog-title{
  font-size: 21px;
  word-spacing: 2px;
  color: #1d2431!important;
  text-decoration: none;

  &:hover{
    text-decoration: underline;
  }
}
.desc{
  width: 100%;
}
.status{
  color: #555;
}
.featured-box{
  font-size: 13px;
  letter-spacing: 1px;
  background: #E65100;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  line-height: 1;
}
.date{
  letter-spacing: 2px;
  color: #868f9d;
  font-size: 13px;
  line-height: 1.3;
}
.vlog-desc{
  color: #666;
  word-spacing: 2px;
}
.action-box{
  height: 30px;
  * {
    display: inline-block;
    line-height: 1;
  }
  div{
    z-index: -1;
    opacity: 0;
    transform: translateY(15px);
    transition: .3s all ease;
  }
  a{
    font-weight: 600;
    text-decoration: none;
  }
}
.post-box:hover{
  .action-box div{
    z-index: 1;
    opacity: 1;
    transform: translateY(0);
  }
}

.loader{
  font-size: 21px;
  color: #868f9d;
}
.empty-state{
  i{
    color: #1d2431;
  }
  div{
    color: #868f9d;
    font-size: 21px;
    font-weight: 600;
  }
}
</style>



























