import Vue from 'vue'
import Vuex from 'vuex'

import global from './global.js'
import agents from './agents'
import user from './user'
import admin from './admin'
import analytics from './analytics'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    global: global,
    agents: agents,
    user: user,
    admin: admin,
    analytics: analytics,
  }
})
