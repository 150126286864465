<template>
  <div class="live-box d-flex justify-center align-center flex-column px-6">
    <div class="head mb-1 text-center">Live Training Link</div>
    <v-card elevation="2" class="pa-10 -box" color="#1d2431" dark>
      <div class="lr-title">Title</div>
      <v-text-field filled outlined hide-details="auto" dense
        v-model="training.title" :error-messages="error.title"
      ></v-text-field>
      <div class="lr-title mt-5">Description</div>
      <v-text-field filled outlined hide-details="auto" dense v-model="training.desc"></v-text-field>
      <div class="lr-title mt-5">Link</div>
      <v-text-field filled outlined hide-details="auto" dense
        v-model="training.link" :error-messages="error.link"
      ></v-text-field>

      <v-btn color="#2d8c40" block class="mt-10" large @click="update">Update Live Training Link</v-btn>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'TrainingLive',
  data() {
    return {
      training: {
        title: '',
        desc: '',
        link: ''
      },
      error: {
        title: '',
        link: '' 
      }
    }
  },
  computed: {
    user () { return this.$store.state.user },
  },
  methods: {
    getTrainingLink () {
      let self = this

      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'getLiveTraining',
        token: self.user.token
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            loading: false,
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }
        let data = res.data.data
        self.training = {
          title: data.title,
          desc: data.description,
          link: data.link,
        }
      })
    },
    update () {
      let self = this

      self.error = {
        title: '',
        link: ''
      }
      // let hasError = false
      // if (!self.training.title) {
      //   self.error.title = 'This field is required.'
      //   hasError = true
      // }
      // if (!self.training.link) {
      //   self.error.link = 'This field is required.'
      //   hasError = true
      // }
      // if (hasError) return

      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'updateLiveTraining',
        token: self.user.token,
        title: self.training.title,
        description: self.training.desc,
        link: self.training.link
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        self.$store.commit('global/setGlobalData', {
          response: {
            show: true,
            success: true,
            title: res.message[0],
            message: ''
          }
        })
      })
    }
  },
  mounted () {
    this.getTrainingLink()
  }
}
</script>

<style lang="scss" scoped>
.live-box{
  height: calc(100vh - 104px);
  width: 100%;
}
.-box{
  width: 100%;
  max-width: 600px;
}
.head{
  font-size: 45px!important;
  font-weight: 600;
}

</style>