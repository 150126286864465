<template>
  <div>
    <div class="page-title">Products</div>
    <div class="sub-title mb-12">List of access request.</div>

    <v-text-field solo v-model="search" append-icon="mdi-magnify" class="pt-0" label="Search" hide-details></v-text-field>
    <v-data-table :headers="headers" class="elevation-1 mb-10 mt-3"
      :search="search" :items="list" :items-per-page="10">
      <template v-slot:item.status="{ item }">
          <v-chip v-if="item.status==1" label color="success" dark small>
            PROCESSED
          </v-chip>
          <v-btn v-if="item.status==0" color="primary" class="white--text" dark small text @click="showConfirmModal(item)">
            Set to Processed
          </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="confirmModal.show" persistent max-width="400">
      <v-card>
        <v-card-title class="text-h5">
          Process Request
        </v-card-title>
        <v-card-text class="lr-font2 py-7">
          Are you sure you want to mark this request as processed?<br/><br/>
          Request #: <b>{{confirmModal.id}}</b><br/>
          Name: <b>{{confirmModal.name}}</b><br/>
          Phone: <b>{{confirmModal.phone_number}}</b><br/>
          <br/>
          Comment:<br/>
          <v-textarea filled outlined hide-details="auto"
            style="font-family:Roboto, sans-serif;font-size:14px;"
            v-model="confirmModal.comment"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey darken-1" text @click="confirmModal.show = false">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="updateProductsRequests()">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'AdminTransactions',
  data() {
    return {
      search: '',
      headers: [
        { text: 'Request #', value: 'id', sortable: false, align: 'center' },
        { text: 'Date Requested', value: 'date', sortable: false, align: 'center' },
        { text: 'Name', value: 'name', sortable: false, align: 'center' },
        { text: 'Phone Number', value: 'phone', sortable: false, align: 'center' },
        { text: 'Comment', value: 'comment', sortable: false, align: 'center' },
        { text: 'Status', value: 'status', sortable: false, align: 'center' },
      ],
      list: [],
      confirmModal: {
        show: false,
        name: '',
        email: '',
        token: '',
        id: '',
        type: '',
        comment: '',
      },
    }
  },
  computed: {
    user () { return this.$store.state.user },
  },
  methods: {
    getProductRequests () {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'admin_listProductsRequests',
        token: self.user.token
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        let data = res.data
        console.log('@@', data)
        self.list=[];
        data.record.forEach(x => {
          self.list.push({
            id: x.id,
            date: moment(x.created_at, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY hh:mmA'),
            name: x.name,
            phone: x.phone_number,
            status: x.status,
            comment: x.comment,
          })
        })
      })
    },
    showConfirmModal (item) {
      let self = this
      self.confirmModal.id = item.id
      self.confirmModal.name = item.name
      self.confirmModal.phone_number = item.phone
      self.confirmModal.show = true
      self.confirmModal.comment = ''
    },
    updateProductsRequests() {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'admin_updateProductsRequests',
        token: self.user.token,
        id: self.confirmModal.id,
        comment: self.confirmModal.comment,
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }
        self.confirmModal.show = false;
        this.getProductRequests();
      })
    }
    
  },
  mounted () {
    this.getProductRequests()
  }
}
</script>

<style lang="scss" scoped>
._title{
  font-size: 21px;
  font-weight: 600;
}

.inv-stats-box{
  .-orange label{
    color: #854e05;
  }
  .-yellow label{
    color: #4f4612;
  }
  .-green label{
    color: #47791d;
  }

  .val{
    font-size: 35px;
    font-weight: 600;
  }
}

.user-level-box{
  .head{
    font-size: 21px;
    font-weight: 600;
  }
}
.stats-val{
  font-weight: 600;
  font-size: 35px;
  line-height: 1;
}
</style>