<template>
  <div class="px-4 pb-10 text-center">
    <div class="page-title mb-2">Welcome to Millionaire Build</div>
    <div class="page-title sub mb-12">VIP Presentation - We are creating 2023 Millionaires!</div>

    <v-card elevation="2" class="pa-5" color="#1d2431">
      <!-- <video controls class="mb-6 vid">
        <source src="https://team-builders.online/video/VIPIntro.mp4" type="video/mp4">
      </video> -->

      <div class="mb-6 vid">
        <div class="wistia_responsive_padding" style="padding:62.5% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/umxlnlanb3?videoFoam=true" title="Introduction Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%"></iframe></div></div>
      </div>

      <ssr-carousel :slides-per-page="1" loop show-arrows :autoplay-delay="4" no-drag>
        <div class="slide" v-for="(item,i) in items" :key="i">
          <v-img :src="item.src" max-height="800" contain></v-img>
        </div>
      </ssr-carousel>

      <div class="lr-header mt-10 mb-5 _title">Millionaire Build - Videos</div>

      <div class="mb-10 vid">
        <div class="wistia_responsive_padding" style="padding:62.5% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/lzvlqzrscy?videoFoam=true" title="Pinoy Power Millionaire Build Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%"></iframe></div></div>
      </div>

      <div class="mb-10 vid">
        <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/diunx9y4b7?videoFoam=true" title="IMG_0304 Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%"></iframe></div></div>
      </div>

      <div class="mb-10 vid">
        <div class="wistia_responsive_padding" style="padding:62.5% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/v8c90ewybl?videoFoam=true" title="Contest Winners Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%"></iframe></div></div>
      </div>

      <div class="mb-10 vid">
        <iframe width="100%" height="500" src="https://www.youtube.com/embed/538VXul12aY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>

      <div class="mb-10 vid">
        <div class="wistia_responsive_padding" style="padding:62.5% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/h3pwuu1908?videoFoam=true" title="APLGO Biz Op Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%"></iframe></div></div>
      </div>
    </v-card>

    <v-row>
      <v-col class="col-md-12">
        <v-img src="@/assets/img/vip/balance.jpeg" contain height="600" class="my-5"></v-img>  
      </v-col>
    </v-row>

  </div>
</template>

<script>
export default {
  name: 'VipPresentation',
  data() {
    return {
      items: [
        {src: 'vip/a1.jpg'},
        {src: 'vip/a2.jpg'},
        {src: 'vip/a3.jpg'},
        {src: 'vip/a4.jpg'},
        {src: 'vip/a5.jpg'},
        {src: 'vip/a6.jpg'},
        {src: 'vip/a7.jpg'},
        {src: 'vip/a8.jpg'},
        {src: 'vip/a9.jpg'},
        {src: 'vip/a10.jpg'},
        {src: 'vip/a11.jpg'},
        {src: 'vip/a12.jpg'},
        {src: 'vip/a13.jpg'},
        {src: 'vip/1.jpg'},
        {src: 'vip/2.jpg'},
        {src: 'vip/3.jpg'},
        {src: 'vip/4.jpg'},
        {src: 'vip/5.jpg'},
        {src: 'vip/6.jpg'},
        {src: 'vip/7.jpg'},
        {src: 'vip/8.jpg'},
        {src: 'vip/9.jpg'},
        {src: 'vip/10.jpg'},
        {src: 'vip/11.jpg'},
        {src: 'vip/12.jpg'},
        {src: 'vip/13.jpg'},
        {src: 'vip/14.jpg'},
        {src: 'vip/15.jpg'},
        {src: 'vip/16.jpg'},
        {src: 'vip/17.jpg'},
        {src: 'vip/18.jpg'},
        {src: 'vip/19.jpg'},
        {src: 'vip/20.jpg'},
        {src: 'vip/21.jpg'},
        {src: 'vip/22.jpg'},
        {src: 'vip/23.jpg'},
        {src: 'vip/24.jpg'},
        {src: 'vip/25.jpg'},
        {src: 'vip/26.jpg'},
        {src: 'vip/27.jpg'},
        {src: 'vip/28.jpg'},
        {src: 'vip/29.jpg'},
        {src: 'vip/30.jpg'},
        {src: 'vip/31.jpg'},
        {src: 'vip/32.jpg'},
        {src: 'vip/33.jpg'},
        {src: 'vip/34.jpg'},
        {src: 'vip/35.jpg'},
        {src: 'vip/36.jpg'},
        {src: 'vip/37.jpg'},
        {src: 'vip/38.jpg'},
      ],
    }
  },
  computed: {
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.sub{
  font-size: 32px;
}
.vid{
  width: 100%;
  background: #000;
  border-radius: 6px;
}
._title{
  font-size: 28px;
  color: #fff;
}
</style>