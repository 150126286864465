<template>
  <div class="px-4">
    <div class="page-title">{{title}}</div>
    <div class="sub-title mb-12">{{desc}}</div>

    <v-card elevation="2" class="mb-5 pa-4">
      <div v-for="(item, i) in items" :key="i">
        <div v-if="item.type_description === 'text'" v-html="item.content" class="mb-5 ql-editor content"></div>

        <v-img v-if="item.type_description === 'image'" :src="item.content" max-height="500" contain class="mb-5"></v-img>
        
        <video v-if="item.type_description === 'video'" controls class="mb-5 vid">
          <source :src="item.content" type="video/mp4">
        </video>

        <div v-if="item.type_description === 'file'">
          <i class="fa-solid fa-download fa-fw fa-3x file" @click="download(item.content)"></i>
        </div>
      </div>
    </v-card>

  </div>
</template>

<script>
var download = require('file-download')

export default {
  name: 'Training',
  data() {
    return {
      title: '',
      desc: '',
      items: []
    }
  },
  computed: {
    slug () { return this.$route.params.slug },
    user () { return this.$store.state.user },
  },
  methods: {
    getInfo () {
      let self = this

      self.items = []
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'getListOfTrainingContents2',
        token: self.user.token,
        //training_id: self.id,
        slug: self.slug,
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          self.$router.push('/agents')
          return
        }

        self.title = res.data.training_title
        self.desc = res.data.training_description
        self.items = res.data.training_contents
      })
    },
    download (url) {
      download(url, {}, err => {
        if (err) {
          this.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: 'There seems to be an issue with the file. Please contact an admin.'
            }
          })
        }
      })
    }
  },
  watch: {
    id () {
      this.getInfo()
    }
  },
  mounted () {
    this.getInfo()
  }
}
</script>

<style lang="scss" scoped>
.vid{
  width: 100%;
  max-height: 500px;
  background: #000;
  border-radius: 6px;
}

.file{
  cursor: pointer;
}
</style>