<template>
  <div class="main d-flex flex-column justify-center align-center">
    <div class="d-relative">
      <img src="@/assets/img/landing/crown.svg" class="crown" />
      <img src="@/assets/img/landing/coins.svg" class="coins" />
      <v-card elevation="5" outlined color="#1d2431" class="px-7 py-10 text-center box mb-7" dark>
        <v-img src="@/assets/img/logo.png" class="mb-4 mx-auto" max-width="280"></v-img>
        <div class="title mb-1">Invitation Code</div>
        <v-text-field filled outlined class="lr-input-rounded" hide-details="auto"
          v-model="invitationCode" :error-messages="errorMsg"
          v-on:keyup.enter="submit"></v-text-field>
        <v-btn color="#2d8c40" block class="mt-3" large @click="submit">ENTER</v-btn>
      </v-card>
    </div>

    <div class="login-box lr-header">
      Already a member?
      <a @click="login" class="lr-text">LOGIN</a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data () {
    return {
      invitationCode: '',
      errorMsg: ''
    }
  },
  computed: {
  },
  methods: {
    submit () {
      let self = this

      self.errorMsg = ''
      if (!self.invitationCode) {
        self.errorMsg = 'Please input Invitation Code.'
        return
      }

      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'validateInvitationCode',
        invitation_code: self.invitationCode
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        self.$store.commit('user/setSessionData', {invitationCode: self.invitationCode})
        self.$router.push('/vip')
      })
    },
    login () {
      this.$router.push('/auth')
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.main{
  width: 100%;
  min-height: 100vh;
}
.crown{
  width: 300px;
  height: 300px;
  position: absolute;
  z-index: 2;
  bottom: calc(100% - 105px);
  left: calc(100% - 200px);
  transform: rotate(17deg);
}
.coins{
  width: 120px;
  height: 120px;
  position: absolute;
  z-index: 2;
  top: calc(100% - 96px);
  right: calc(100% - 94px);
}
.box{
  min-width: 400px;
  position: relative;
  z-index: 1;
}
.title{
  font-family: 'Poppins', sans-serif!important;
  font-weight: 600;
}
.login-box{
  color: #777;

  a{
    font-weight: 600;
    text-decoration: underline;
  }
}

@media (max-width: 960px) {
  .box{
    min-width: auto;
    width: 100%;
  }
  .login-box{
    margin-top: 20px;
  }
}
</style>