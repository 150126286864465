import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'

import Landing from '@/views/landing'
import Auth from '@/views/auth'
import Signup from '@/views/auth/signup'
import SignupMillionaireBuild from '@/views/auth/signup-millionaire-build'
import SendReset from '@/views/auth/send-reset'
import PasswordReset from '@/views/auth/password-reset'
import SignupGcash from '@/views/auth/gcash'
//import AgentList from '@/views/agent/list'
import AgentDetails from '@/views/agent/details'
import VipPresentation from '@/views/vip'
import AdminList from '@/views/admin/list'
import Profile from '@/views/profile'
import PostList from '@/views/posts/list'
import PostView from '@/views/posts/view'
import Training from '@/views/training/english'
import TrainingTemp2 from '@/views/training/taglish'
import TrainingLive from '@/views/training/live'
import TrainingLink from '@/views/training/link'
import TrainingFastStart from '@/views/training/fast_start'
//import Premium from '@/views/premium'
import PremiumTree from '@/views/premium/tree'
//import PremiumPurchase from '@/views/premium/purchase'
import Premium2 from '@/views/premium/index2'
import PremiumTransaction from '@/views/premium/transaction'

// import CoreTeam from '@/views/core-team'
import CoreTeam2 from '@/views/core-team/test'
import Products from '@/views/products'

// Global
import gcash from '@/views/gcash'

// Admin
import AdminUsers from '@/views/admin/users/list'
import AdminUsersEdit from '@/views/admin/users/edit'
import AdminAnalytics from '@/views/admin/analytics'
import AdminPosts from '@/views/admin/posts/list'
import AdminPostsEdit from '@/views/admin/posts/edit'
import AdminTransactions from '@/views/admin/transactions'
import AdminLeaderboard from '@/views/admin/leaderboard'
import AdminProducts from '@/views/admin/products'
import AdminGCashGiveAways from '@/views/admin/gcash_giveaways'

Vue.use(VueRouter)

let router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'Landing',
      component: Landing
    },
    {
      path: '/binary-tree',
      name: 'CoreTeam',
      component: CoreTeam2,
      meta: {
        needLogin: true,
        fullpage: true
      }
    },
    /*{
      path: '/agents',
      name: 'AgentList',
      component: AgentList,
      meta: {
        needInvCode: true,
        needLogin: true
      }
    },*/
    {
      path: '/vip',
      name: 'VipPresentation',
      component: VipPresentation,
      meta: {
        needInvCode: true,
        needLogin: true
      }
    },
    {
      path: '/auth',
      name: 'Auth',
      component: Auth,
    },
    {
      path: '/auth/reset',
      name: 'SendReset',
      component: SendReset,
    },
    {
      path: '/password-reset/:id',
      name: 'PasswordReset',
      component: PasswordReset,
    },
    {
      path: '/auth/signup',
      name: 'Signup',
      component: Signup,
      meta: {
        needInvCode: true,
      }
    },
    {
      path: '/auth/signup-millionaire-build',
      name: 'SignupMillionaireBuild',
      component: SignupMillionaireBuild,
    },
    {
      path: '/auth/signup-millionaire-build/:id',
      name: 'SignupMillionaireBuild',
      component: SignupMillionaireBuild,
    },
    {
      path: '/auth/signup-millionaire-build-gcash',
      name: 'SignupGcash',
      component: SignupGcash,
    },
    {
      path: '/auth/signup-millionaire-build-gcash/:id',
      name: 'SignupGcash',
      component: SignupGcash,
    },
    {
      path: '/profile',
      name: 'Profile',
      component: Profile,
      meta: {
        needLogin: true
      }
    },
    {
      path: '/agent/:id',
      name: 'AgentDetails',
      component: AgentDetails,
      meta: {
        needInvCode: true,
        needLogin: true
      }
    },
    {
      path: '/training/english',
      name: 'TrainingEnglish',
      component: Training,
      meta: {
        needInvCode: true,
        needLogin: true
      }
    },
    {
      path: '/training/taglish',
      name: 'TrainingTaglish',
      component: TrainingTemp2,
      meta: {
        needInvCode: true,
        needLogin: true
      }
    },
    {
      path: '/training/live',
      name: 'TrainingLive',
      component: TrainingLive,
      meta: {
        needLogin: true
      }
    },
    {
      path: '/training/Ehps9Mkm7Bram3aOt',
      name: 'TrainingLink',
      component: TrainingLink,
      meta: {
      }
    },
    {
      path: '/training/fast_start',
      name: 'TrainingFastStart',
      component: TrainingFastStart,
      meta: {
        needLogin: true
      }
    },
    {
      path: '/products',
      name: 'Products',
      component: Products,
      meta: {
        needLogin: true
      }
    },
    {
      path: '/GCashGiveAwayFeature',
      name: 'gcash',
      component: gcash
    },
    {
      path: '/posts',
      name: 'PostList',
      component: PostList,
      meta: {
        needInvCode: true,
        needLogin: true
      }
    },
    {
      path: '/post/:slug',
      name: 'PostView',
      component: PostView,
      meta: {
        needInvCode: true,
        needLogin: true
      }
    },
    /*{
      path: '/premium',
      name: 'Premium',
      component: Premium,
      meta: {
        needLogin: true,
        premiumPage: true
      }
    },*/
    {
      path: '/premium',
      name: 'Premium2',
      component: Premium2,
      meta: {
        needLogin: true,
        premiumPage: true
      }
    },
    {
      path: '/premium/transaction/:id',
      name: 'PremiumTransaction',
      component: PremiumTransaction,
      meta: {
        needLogin: true,
        premiumPage: true
      }
    },
    {
      path: '/premium/tree',
      name: 'PremiumTree',
      component: PremiumTree,
      meta: {
        needLogin: true,
        fullpage: true,
        premiumPage: true
      }
    },
    /*{
      path: '/premium/purchase',
      name: 'PremiumPurchase',
      component: PremiumPurchase,
      meta: {
        needLogin: true,
        premiumPage: true
      }
    },*/

    {
      path: '/admin',
      name: 'AdminList',
      component: AdminList,
      meta: {
        needLogin: true
      }
    },
    {
      path: '/admin/users',
      name: 'AdminUsers',
      component: AdminUsers,
      meta: {
        needLogin: true
      }
    },
    {
      path: '/admin/user/:id',
      name: 'AdminUsersEdit',
      component: AdminUsersEdit,
      meta: {
        needLogin: true
      }
    },
    {
      path: '/admin/analytics',
      name: 'AdminAnalytics',
      component: AdminAnalytics,
      meta: {
        needLogin: true
      }
    },
    {
      path: '/admin/transactions',
      name: 'AdminTransactions',
      component: AdminTransactions,
      meta: {
        needLogin: true
      }
    },
    {
      path: '/admin/leaderboard',
      name: 'AdminLeaderboard',
      component: AdminLeaderboard,
      meta: {
        needLogin: true
      }
    },
    {
      path: '/admin/products',
      name: 'AdminProducts',
      component: AdminProducts,
      meta: {
        needLogin: true
      }
    },
    {
      path: '/admin/gcash',
      name: 'AdminGCashGiveAways',
      component: AdminGCashGiveAways,
      meta: {
        //needLogin: true
      }
    },
    {
      path: '/admin/posts',
      name: 'AdminPosts',
      component: AdminPosts,
      meta: {
        needLogin: true
      }
    },
    {
      path: '/admin/post/:id',
      name: 'AdminPostsEdit',
      component: AdminPostsEdit,
      meta: {
        needLogin: true
      }
    },
  ]
})

router.beforeEach((to, from, next) => {
  let user = store.state.user
  if (
    (to.meta.needInvCode &&  to.meta.needLogin && !user.invitationCode && !user.token) ||
    (to.meta.needInvCode && !to.meta.needLogin && !user.invitationCode) ||
    (!to.meta.needInvCode && to.meta.needLogin && !user.token)
  ) {
    next('/')
    return
  }
  next()
})

export default router