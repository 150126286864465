<template>
  <div class="px-4">
    <v-card elevation="5" outlined class="mb-7">
      <div class="wistia_channel wistia_async_agxip338xb mode=inline" style="min-height:100vh;position:relative;width:100%;"></div>
    </v-card>

  </div>
</template>

<script>
export default {
  name: 'Training',
  data() {
    return {
      title: '',
      desc: '',
      items: []
    }
  },
  computed: {
    id () { return this.$route.params.id },
    user () { return this.$store.state.user },
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.vlog-title{
  font-size: 21px;
  word-spacing: 2px;
  color: #1d2431!important;
  text-decoration: none;

  &:hover{
    text-decoration: underline;
  }
}
.date{
  letter-spacing: 2px;
  color: #868f9d;
  font-size: 14px;
}
.vlog-desc{
  color: #666;
  word-spacing: 2px;
}
</style>