<template>
  <div>
    <div class="page-title my-8 text-center">GCash Give Aways</div>
    <div class="head lr-header mb-8 text-center">Total Spins: {{gcashStats.spins}}<br/>Total Amount Given: {{gcashStats.amount}}</div>
    <div class="head lr-header mb-8 text-center">
      <v-btn color="primary" class="white--text" dark @click="refreshRecords()">
        Refresh Records
      </v-btn>
    </div>
    
    
    <div class="sub-title mb-5">New Registered Member.</div>
    <v-text-field solo v-model="search" append-icon="mdi-magnify" class="pt-0 uni-font" label="Search via Date (e.g. 12/31/2023) | GCash Number (e.g. 09XXXXXXXX) | Name (e.g. John Doe)" hide-details></v-text-field>
    <v-data-table :headers="headers" class="elevation-1 mb-10 mt-3"
      :search="search" :items="list" :items-per-page="10">
      <template v-slot:item.registrantPrize="{ item }">
          <v-chip v-if="item.registrantPrize!=0 && item.registrantPrize!=9999" label color="success" dark small>
            {{item.registrantPrize}}
          </v-chip>
          <v-chip v-if="item.registrantPrize==9999" label color="gray" dark small>
            WAIVED
          </v-chip>
          <v-btn v-if="item.registrantPrize==0" color="primary" class="white--text" dark x-small @click="showUpdatePrizeModal(item, 1)">
            Update Prize
          </v-btn>
      </template>
      <template v-slot:item.inviterPrize="{ item }">
          <v-chip v-if="item.inviterPrize!=0 && item.inviterPrize!=9999" label color="success" dark small>
            {{item.inviterPrize}}
          </v-chip>
          <v-chip v-if="item.inviterPrize==9999" label color="gray" dark small>
            WAIVED
          </v-chip>
          <v-btn v-if="item.inviterPrize==0" color="primary" class="white--text" dark x-small @click="showUpdatePrizeModal(item, 2)">
            Update Prize
          </v-btn>
      </template>
      <template v-slot:item.action="{ item }">
          <v-btn color="error" class="white--text" dark x-small @click="showDeleteModal(item)">
            Delete
          </v-btn>
      </template>
    </v-data-table>

    <div class="sub-title mb-5">GCash record with total earnings.</div>
    <v-text-field solo v-model="earningsSearch" append-icon="mdi-magnify" class="pt-0 uni-font" label="Search via Date (e.g. 12/31/2023) | GCash Number (e.g. 09XXXXXXXX) | Name (e.g. John Doe)" hide-details></v-text-field>
    <v-data-table :headers="earningsHeaders" class="elevation-1 mb-10 mt-3"
      :search="earningsSearch" :items="earningsList" :items-per-page="10">
      <template v-slot:item.totalEarnings="{ item }">
          <b>{{item.totalEarnings}}</b>
      </template>
      <template v-slot:item.totalBUSD="{ item }">
          <b>{{item.totalBUSD}}</b>
      </template>
    </v-data-table>

    <v-dialog v-model="updatePrizeModal.show" persistent max-width="400">
      <v-card>
        <v-card-title class="text-h5">
          Update Prize
        </v-card-title>
        <v-card-text class="lr-font2 py-7">
          Updating prize for 
          <span v-if="updatePrizeModal.type==1"><b>Registrant</b></span>
          <span v-if="updatePrizeModal.type==2"><b>Inviter</b></span>
          <br/>
          Id #: <b>{{updatePrizeModal.id}}</b><br/>
          Name: <b>{{updatePrizeModal.name}}</b><br/>
          <br/>
          <v-select
            v-model="updatePrizeModal.amount"
            :items="prizes"
            :value="updatePrizeModal.amount"
            label="Prize"
            dense
            outlined
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey darken-1" text @click="updatePrizeModal.show = false">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="updatePrize()">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog transition="dialog-top-transition" max-width="460" :value="showPassword" v-model="showPassword" persistent>
      <v-card>
        <v-toolbar color="#444" dark>Enter Password</v-toolbar>
        <v-card-text>
          <div class="my-10">
            <v-text-field filled outlined hide-details="auto" v-model="password" type="password"
              :error-messages="error.password"
              v-on:keyup.enter="checkPassowrd"></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn text @click="checkPassowrd">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteModal.show" persistent max-width="400">
      <v-card>
        <v-card-title class="text-h5">
          Delete Record
        </v-card-title>
        <v-card-text class="lr-font2 py-7">
          Deleting GCash record:
          <br/>
          Id #: <b>{{deleteModal.id}}</b><br/>
          Name: <b>{{deleteModal.name}}</b><br/>
          Inviter: <b>{{deleteModal.inviter}}</b><br/>
          <br/>
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey darken-1" text @click="deleteModal.show = false">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="deleteGCash()">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'AdminGCashGiveAways',
  data() {
    return {
      search: '',
      headers: [
        { text: 'ID', value: 'id', sortable: false, align: 'center' },
        { text: 'Date Registered', value: 'date', sortable: false, align: 'center' },
        { text: 'Registrant', value: 'registrant', sortable: false, align: 'left' },
        { text: 'Registrant Spin Prize', value: 'registrantPrize', sortable: false, align: 'center' },
        { text: 'Inviter', value: 'inviter', sortable: false, align: 'left' },
        { text: 'Inviter Spin Prize', value: 'inviterPrize', sortable: false, align: 'center' },
        { text: '', value: 'action', sortable: false, align: 'center' },
      ],
      list: [],
      updatePrizeModal: {
        show: false,
        name: '',
        type: '',
        amount: 50,
      },
      prizes: [
        { text: '50', value: 50 },
        { text: '100', value: 100 },
        { text: '150', value: 150 },
        { text: '200', value: 200 },
        { text: '500', value: 500 },
        { text: 'WAIVED', value: 9999 },
      ],
      showPassword: true,
      password: '',
      error: {
        password: ''
      },
      earningsSearch: '',
      earningsHeaders: [
        { text: 'ID', value: 'id', sortable: false, align: 'center' },
        { text: 'Date Registered', value: 'date', sortable: false, align: 'center' },
        { text: 'Name', value: 'name', sortable: false, align: 'left' },
        { text: 'Spin Prize', value: 'prize', sortable: false, align: 'center' },
        { text: 'Earnings on Invites', value: 'prizeEarnings', sortable: false, align: 'center' },
        { text: 'Total Earnings', value: 'totalEarnings', sortable: false, align: 'center' },
        { text: 'Total Invites', value: 'totalInvites', sortable: false, align: 'center' },
        { text: 'Total Earnings on BUSD', value: 'totalBUSD', sortable: false, align: 'center' },
      ],
      earningsList: [],
      gcashStats: {
        spins: 0,
        amount: 0,
      },
      deleteModal: {
        show: false,
        name: '',
        inviter: '',
        id: '',
      },
    }
  },
  computed: {
    user () { return this.$store.state.user },
  },
  methods: {
    getGCashRegistrant () {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'admin_listAllGCashRegistrant',
        //token: self.user.token
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        let data = res.data
        //console.log('@@', data)
        self.list=[];
        data.record.forEach(x => {
          self.list.push({
            id: x.id,
            date: moment(x.created_at, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY hh:mmA'),
            registrant: x.gcash+' ('+x.name+')',
            registrantPrize: x.prize,
            inviter: x.gcash_inviter+' ('+x.name_inviter+')',
            inviterPrize: x.prize_inviter,
          })
        })
      })
    },
    getGCashRegistrantEarnings () {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'admin_listAllGCashEarnings',
        //token: self.user.token
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        let data = res.data
        //console.log('@@2', data)
        self.earningsList=[];
        data.record.forEach(x => {
          self.earningsList.push({
            id: x.id,
            date: moment(x.created_at, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY hh:mmA'),
            name: x.gcash+' ('+x.name+')',
            prize: (x.prize == 'WAIVED') ? x.prize : x.prize+' PHP',
            prizeEarnings: x.total_invite_earnings+' PHP',
            totalEarnings: (x.prize == 'WAIVED') ? x.total_invite_earnings+' PHP' : (parseInt(x.prize)+parseInt(x.total_invite_earnings))+' PHP',
            totalInvites: x.total_invites,
            totalBUSD: x.total_busd+' USD',
            action: '',
          })
        })
      })
    },
    getGCashStats () {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'admin_getGCashStats',
        //token: self.user.token
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }

        let data = res.data
        console.log('@@3', data)
        self.gcashStats.spins = data.total_winner;
        self.gcashStats.amount = data.total_amount;
      })
    },
    showUpdatePrizeModal (item, type) {
      let self = this
      self.updatePrizeModal.id = item.id
      self.updatePrizeModal.name = (type==1) ? item.registrant : item.inviter
      self.updatePrizeModal.type = type
      self.updatePrizeModal.amount = 50
      self.updatePrizeModal.show = true
    },
    updatePrize() {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'admin_updateGCashRegistrant',
        id: self.updatePrizeModal.id,
        amount: self.updatePrizeModal.amount,
        type: self.updatePrizeModal.type,
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }
        self.updatePrizeModal.show = false;
        this.getGCashRegistrant();
        this.getGCashRegistrantEarnings();
      this.getGCashStats();
      })
    },
    checkPassowrd () {
      this.error.password = ''
      if (!this.password) {
        this.error.password = 'This field is required.'
        return
      }
      if (this.password != 'Gcash1234') {
        this.error.password = 'Invalid password.'
        return
      }
      this.showPassword = false
      this.getGCashRegistrant()
      this.getGCashRegistrantEarnings();
      this.getGCashStats();
    },
    refreshRecords() {
      if (this.password != 'Gcash1234') {
        this.showPassword = true
        return
      }
      this.getGCashRegistrant()
      this.getGCashRegistrantEarnings();
      this.getGCashStats();
    },
    showDeleteModal (item) {
      let self = this
      self.deleteModal.id = item.id
      self.deleteModal.name = item.registrant
      self.deleteModal.inviter = item.inviter
      self.deleteModal.show = true
    },
    deleteGCash() {
      let self = this
      self.$store.commit('global/setGlobalData', {loading: true})
      self.$sdk.post('', {
        requestType: 'admin_deleteGCashRegistrant',
        id: self.deleteModal.id,
      }, res => {
        self.$store.commit('global/setGlobalData', {loading: false})
        if (res.status == 'failed') {
          self.$store.commit('global/setGlobalData', {
            response: {
              show: true,
              success: false,
              title: 'Oops! Something went wrong.',
              message: res.message[0]
            }
          })
          return
        }
        self.deleteModal.show = false;
        this.getGCashRegistrant();
        this.getGCashRegistrantEarnings();
      this.getGCashStats();
      })
    },
  },
  mounted () {
    // this.getGCashRegistrant()
  }
}
</script>

<style lang="scss" scoped>
._title{
  font-size: 21px;
  font-weight: 600;
}

.inv-stats-box{
  .-orange label{
    color: #854e05;
  }
  .-yellow label{
    color: #4f4612;
  }
  .-green label{
    color: #47791d;
  }

  .val{
    font-size: 35px;
    font-weight: 600;
  }
}

.user-level-box{
  .head{
    font-size: 21px;
    font-weight: 600;
  }
}
.stats-val{
  font-weight: 600;
  font-size: 35px;
  line-height: 1;
}
.uni-font {
  font-family: "Poppins", sans-serif !important;
}
.head{
  font-size: 28px!important;
  font-weight: 600;
}
</style>