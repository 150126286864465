export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    token: '',
    invitationCode: ''
  },
  mutations: {
    setSessionData (state, data) {
      for (let field in data) {
        state[field] = data[field]
      }
    },
  }
}